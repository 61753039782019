import React, { useEffect, useState } from "react";

// import InputRange from "react-input-range";
// import "react-input-range/lib/css/index.css";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import SelectInput from "../../../Shared/components/FormInput/components/SelectInput";

import { onChangeFilters } from "../../../Shared/utils/EventFunctions";
import { AnimatePresence, motion } from "framer-motion";

const FiltersComponentMobile = ({
  productsData,
  featuresArray,
  setFeaturesArray,
  filters,
  setFilters,
  arrayToDisplay,
  setArrayToDisplay,
  displayFilters,
  setDisplayFilters,
  innerWidthState,
}) => {
  const [displaySideBar, setDisplaySideBar] = useState("none");

  const variantsSideBar = {
    hidden: {
      height: 0,
      opacity: 0,
      display: displaySideBar,
    },
    visible: {
      height: "auto",
      opacity: 1,
      display: displaySideBar,
    },
  };

  const filtersFormat = () => {
    const filtersIDs = Object.keys(filters);
    if (filtersIDs.length > 0) {
      const filteredProducts = productsData["products"].filter((product) => {
        return Object.entries(filters).every(([filterID, filterObject]) => {
          let target = product.formatedFeatures.find(
            (elmt) => elmt.id === filterID
          );

          if (target) {
            if (
              target &&
              filterObject.type === "dropdown" &&
              filterObject.values.find(
                (filter) => filter.value === target.value
              )
            ) {
              return product;
            } else if (target && filterObject.type === "numerical") {
              if (
                target.value >= filterObject["values"][0] &&
                target.value <= filterObject["values"][1]
              ) {
                return product;
              } else {
                return false;
              }
            } else {
              return false;
            }
          } else {
            return false;
          }
        });
      });
      setArrayToDisplay(filteredProducts);
    } else {
      setArrayToDisplay(productsData["products"]);
    }
  };

  const switchDisplaySideBar = () => {
    if (!displayFilters || innerWidthState > 768) {
      setTimeout(() => {
        setDisplaySideBar("none");
      }, [500]);
    } else {
      setDisplaySideBar("flex");
    }
  };

  useEffect(() => {
    filtersFormat();
  }, [filters]);

  useEffect(() => {
    switchDisplaySideBar();
  }, [displayFilters, innerWidthState]);

  return (
    <>
      <AnimatePresence initial={false}>
        <div className="p-10 shadow-md">
          <motion.div
            animate={
              displayFilters && innerWidthState < 768 ? "visible" : "hidden"
            }
            variants={variantsSideBar}
            transition={{ duration: 0.5 }}
            className="flex flex-col">
            <div className="flex justify-around items-center">
              <div
                onClick={() => {
                  setDisplayFilters(false);
                }}
                className="h-10 w-10 cursor-pointer"
                title="cacher les filtres">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
                  <path d="M38.8 5.1C28.4-3.1 13.3-1.2 5.1 9.2S-1.2 34.7 9.2 42.9l592 464c10.4 8.2 25.5 6.3 33.7-4.1s6.3-25.5-4.1-33.7L525.6 386.7c39.6-40.6 66.4-86.1 79.9-118.4c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C465.5 68.8 400.8 32 320 32c-68.2 0-125 26.3-169.3 60.8L38.8 5.1zM223.1 149.5C248.6 126.2 282.7 112 320 112c79.5 0 144 64.5 144 144c0 24.9-6.3 48.3-17.4 68.7L408 294.5c8.4-19.3 10.6-41.4 4.8-63.3c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3c0 10.2-2.4 19.8-6.6 28.3l-90.3-70.8zM373 389.9c-16.4 6.5-34.3 10.1-53 10.1c-79.5 0-144-64.5-144-144c0-6.9 .5-13.6 1.4-20.2L83.1 161.5C60.3 191.2 44 220.8 34.5 243.7c-3.3 7.9-3.3 16.7 0 24.6c14.9 35.7 46.2 87.7 93 131.1C174.5 443.2 239.2 480 320 480c47.8 0 89.9-12.9 126.2-32.5L373 389.9z" />
                </svg>
              </div>
              <button
                onClick={() => setFilters({})}
                className="w-[70%] px-4 py-1 block hover:bg-gray-800 hover:text-gray-100 font-semibold border rounded border-gray-800 text-gray-800">
                Réinitialiser les filtres
              </button>
            </div>
            <ul className="px-3 text-sm mx-auto">
              {featuresArray &&
                featuresArray.length > 0 &&
                featuresArray
                  .sort((a, b) => a.type - b.type)
                  .map((feature, index) => {
                    if (
                      feature.listOfValues &&
                      feature.listOfValues.length > 1
                    ) {
                      if (
                        feature.type === "y_n" ||
                        feature.type === "dropdown"
                      ) {
                        return (
                          <li key={index} className="py-5">
                            <label>{feature.featureName}</label>
                            <SelectInput
                              feature={feature}
                              index={index}
                              page={"product-list"}
                              state={filters}
                              setState={setFilters}
                              objectKey={feature.id}
                              validateFunction={() => {}}
                              arrayOptions={
                                feature.type === "y_n"
                                  ? [
                                      { value: true, label: "Oui" },
                                      { value: false, label: "Non" },
                                    ]
                                  : feature.listOfValues.reduce(
                                      (accumulateur, current) => {
                                        if (
                                          !accumulateur.find(
                                            (elmt) =>
                                              elmt.value === current.value
                                          )
                                        ) {
                                          accumulateur.push(current);
                                        }
                                        return accumulateur;
                                      },
                                      []
                                    )
                              }
                              multipleAttribute={
                                feature.type === "y_n" ? false : true
                              }
                              clientProductList={true}
                            />
                          </li>
                        );
                      } else if (feature.type === "numerical") {
                        return (
                          <li className="py-5" key={index}>
                            <label>{feature.featureName}</label>

                            <Slider
                              range
                              value={
                                filters[feature.id] &&
                                filters[feature.id]["values"]
                                  ? filters[feature.id]["values"]
                                  : feature["listOfValues"]
                                  ? [
                                      0,
                                      Number(
                                        feature["listOfValues"].sort(
                                          (a, b) => a.value - b.value
                                        )[feature["listOfValues"].length - 1][
                                          "value"
                                        ]
                                      ),
                                    ]
                                  : [0, 0]
                              }
                              marks={feature.marks}
                              step={1}
                              min={0}
                              max={
                                feature.listOfValues.sort(
                                  (a, b) => a.value - b.value
                                )[feature.listOfValues.length - 1]["value"]
                              }
                              onChange={(e) => {
                                let filterObject = {
                                  values: e,
                                  type: feature.type,
                                };

                                onChangeFilters(
                                  filterObject,
                                  feature.id,
                                  filters,
                                  setFilters
                                );
                              }}
                            />
                          </li>
                        );
                      }
                    }
                  })}
            </ul>
          </motion.div>

          <motion.div
            title="Afficher les filtres"
            animate={
              displayFilters || innerWidthState > 768
                ? { display: "none" }
                : { display: "flex" }
            }
            transition={{ duration: 0.3 }}
            className="mt-10 justify-center">
            <motion.div
              animate={
                displayFilters || innerWidthState > 768
                  ? { opacity: 0 }
                  : { opacity: 1 }
              }
              transition={{ duration: 0.3 }}
              className="cursor-pointer"
              onClick={() => setDisplayFilters(true)}>
              <p className="underline font-bold hover:text-red-800">
                Filtrer les résultats
              </p>
            </motion.div>
          </motion.div>
        </div>
      </AnimatePresence>
    </>
  );
};

export default FiltersComponentMobile;
