import React, { useState, useEffect } from "react";

// IMPORT LIB
import { sanitize } from "dompurify";
import { Link } from "react-router-dom";

// IMPORT CONTEXT
import { useLoadingErrorContext } from "../../Shared/Context/LoadingErrorContext";

// IMPORT UTILS
import {
  requestHandle,
  updateSingleField,
} from "../../Shared/utils/RequestFunctions";
import { nameValueConcatHandle } from "../../Shared/utils/ProductFeatureToKeepArrays";

// IMPORT COMPONENT
import BackofficeGoBack from "../../Shared/components/BackofficeGoBack/BackofficeGoBack";
import AlertMessage from "../../Shared/components/AlertMessage/AlertMessage";
import ModalDelete from "../../Shared/components/Modals/ModalDelete";
import TableComponent from "../../Shared/components/TableComponent/TableComponent";
import ListeFilters from "./components/ListeFilters";

const ProductsList = ({ userSession }) => {
  const { isLoading, setIsLoading } = useLoadingErrorContext();

  const [errorMessage, setErrorMessage] = useState();

  const [modaleDelete, setModaleDelete] = useState(false);
  const [productList, setProductList] = useState([]);
  const [listToDisplay, setListToDisplay] = useState();

  const fetchProductList = async () => {
    const response = await requestHandle(
      setIsLoading,
      setErrorMessage,
      userSession.token,
      "GET",
      "product"
    );
    if (response && response.statut === "OK") {
      let formatedData = [];
      if (
        response.message &&
        Array.isArray(response.message) &&
        response.message.length > 0
      )
        response.message.map(async (product) => {
          product.features = await nameValueConcatHandle(
            product.features,
            product.sub_category && product.sub_category["_id"]
              ? product.sub_category["_id"]
              : ""
          );
        });

      setProductList(response.message);
      setListToDisplay(response.message);
    }
  };

  useEffect(() => {
    fetchProductList();
  }, []);

  return (
    <main id="presentation-liste-container" className="backoffice-container">
      {errorMessage ? (
        <AlertMessage
          errorMessage={errorMessage}
          setErrorMessage={setErrorMessage}
        />
      ) : null}

      {modaleDelete ? (
        <ModalDelete
          setIsLoading={setIsLoading}
          setErrorMessage={setErrorMessage}
          modale={modaleDelete}
          setModale={setModaleDelete}
          token={userSession.token}
        />
      ) : null}

      <TableComponent
        filterList={[
          { key: "name", display: "Nom" },
          { key: "reference", display: "Réference" },
          { key: "EAN", display: "Ean" },
          { key: "created_at", display: "Date" },
        ]}
        token={userSession.token}
        modaleDelete={modaleDelete}
        setModaleDelete={setModaleDelete}
        collection={"product"}
        addButton={{ text: "un produit", link: "/backoffice/product/create" }}
        listTitle={"Les produits"}
        setListArray={setProductList}
        listArray={productList.sort(
          (a, b) => new Date(b.created_at) - new Date(a.created_at)
        )}
        listToDisplay={listToDisplay}
        setListToDisplay={setListToDisplay}
        fieldsArray={[
          { colTitle: "Nom", type: "text", objectKey: "name" },

          {
            objectKey: "brand",
            colTitle: "Marque",
            nestedField: "name",
            type: "text",
          },

          { objectKey: "EAN", colTitle: "EAN", type: "text" },
          { objectKey: "reference", colTitle: "Référence", type: "text" },

          {
            objectKey: "home_select",
            colTitle: "Séléctionné pour la page d'acceuil",
            type: "boolean",
          },
          {
            objectKey: "category",
            nestedField: "name",
            colTitle: "Catégorie de navigation",
            type: "text",
          },
          {
            objectKey: "sub_category",
            nestedField: "name",
            colTitle: "Sous catégorie de navigation",
            type: "text",
          },
          {
            objectKey: "created_at",
            colTitle: "Date de création",
            type: "date",
          },

          // {
          //   objectKey: "repairability_index",
          //   colTitle: "Indice de réparabilité",
          //   type: "text",
          // },
          // {
          //   objectKey: "repairability_picture",
          //   colTitle: "Étiquette d'indice de réparabilité",
          //   type: "picture",
          // },

          // { objectKey: "energy_label", colTitle: "Label énergie", type: "pdf" },

          // { objectKey: "manual", colTitle: "Manuel", type: "pdf" },
          // { objectKey: "leaflet", colTitle: "Brochure", type: "pdf" },
          // {
          //   objectKey: "eu_product_fiche",
          //   colTitle: "Fiche produit européenne",
          //   type: "pdf",
          // },
          // {
          //   objectKey: "description",
          //   colTitle: "Description",
          //   type: "innerHtml",
          // },
          // {
          //   objectKey: "picture_presentation",
          //   colTitle: "Image de présentation",
          //   type: "picture",
          // },
          // {
          //   objectKey: "picture_gallery",
          //   colTitle: "Gallerie d'image",
          //   type: "picture_gallery",
          // },
          // {
          //   objectKey: "features",
          //   colTitle: "Caractéristiques",
          //   type: "features",
          // },
        ]}
      />
    </main>
  );
};

export default ProductsList;
