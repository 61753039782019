import React, { useState } from "react";

import { useFormik } from "formik";

// IMPORT CONTEXT
import { useLoadingErrorContext } from "../../Shared/Context/LoadingErrorContext";

// IMPORT COMPONENTS
import LoadingWrapper from "../../Shared/components/LoadingWrapper/LoadingWrapper";
import ModalClient from "../../Shared/components/Modals/ModalClient";
import AlertMessage from "../../Shared/components/AlertMessage/AlertMessage";
import SEOHelmet from "../../Shared/components/SeoHelmet/SEOHelmet";
// IMPORT UTILS

const Contact = ({ userSession }) => {
  const { isLoading, setIsLoading, setErrorMessage, errorMessage } =
    useLoadingErrorContext();

  const [modalSucces, setModalSuccess] = useState(false);

  const validateContact = (value) => {
    const errors = {};
    if (!value.last_name) {
      errors.last_name = "Merci de renseigner de champ";
    }
    if (!value.last_name.toLowerCase().match(/^[a-zA-Z\-]+$/)) {
      errors.last_name =
        "Le nom de famille ne doit contenir que des lettres ou des tirets (-)";
    }

    if (!value.first_name) {
      errors.first_name = "Merci de renseigner de champ";
    }

    if (!value.first_name.toLowerCase().match(/^[a-zA-Z\-]+$/)) {
      errors.first_name =
        "Le prénom ne doit contenir que des lettres ou des tirets (-)";
    }

    if (!value.phone_number) {
      errors.phone_number = "Merci de renseigner de champ";
    }

    if (
      !value.phone_number
        .toLowerCase()
        .match(/^([+]?\d{1,2}[-\s]?|)\d{3}[-\s]?\d{3}[-\s]?\d{4}$/)
    ) {
      errors.phone_number = "Merci de renseigner un numéro de téléphone valide";
    }

    if (!value.message) {
      errors.message = "Merci de renseigner de champ";
    }

    if (!value.email) {
      errors.email = "Merci de renseigner de champ";
    }

    if (
      !value.email
        .toLowerCase()
        .match(
          /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/
        )
    ) {
      errors.email = "Merci de renseigner un email valide";
    }

    return errors;
  };

  const formikContact = useFormik({
    initialValues: {
      last_name: "",
      first_name: "",
      phone_number: "",
      email: "",
      adress: "",
      post_code: "",
      city: "",
      product_category: "",
      brand: "",
      reference: "",
      serial_number: "",
      message: "",
    },
    validate: validateContact,
    onSubmit: (v) => {
      setErrorMessage("");
      setModalSuccess({ checked: false, values: v });
    },
  });

  if (isLoading) return <LoadingWrapper isLoading={isLoading} />;
  return (
    <main id="contact-container" className="px-5 md:px-0">
      {errorMessage && (
        <AlertMessage
          errorMessage={errorMessage}
          setErrorMessage={setErrorMessage}
        />
      )}
      <SEOHelmet title={"Nous contacter"} />

      {modalSucces && (
        <ModalClient
          setIsLoading={setIsLoading}
          errorMessage={errorMessage}
          setErrorMessage={setErrorMessage}
          modale={modalSucces}
          setModale={setModalSuccess}
        />
      )}

      <div className={`${modalSucces ? "opacity-50" : ""}`}>
        <section className="p-6 bg-gray-100 text-gray-900">
          <form
            onSubmit={formikContact.handleSubmit}
            className="container flex flex-col mx-auto space-y-12">
            <fieldset className="grid grid-cols-4 gap-6 p-6 rounded-md shadow-sm bg-gray-50">
              <div className="space-y-2 col-span-full lg:col-span-1">
                <h1 className="font-medium">Demande de dépannage</h1>
                <p className="text-xs">
                  {" "}
                  Découvrez notre Service Après-Vente dédié à rendre votre
                  expérience client exceptionnelle. Notre équipe dévouée est
                  prête à résoudre rapidement vos problèmes électroménagers,
                  assurant ainsi la longévité et la performance de vos
                  appareils. Profitez d'une assistance personnalisée en visitant
                  notre espace SAV
                </p>
              </div>
              <div className="grid grid-cols-6 gap-4 col-span-full lg:col-span-3">
                <div className="col-span-full sm:col-span-3">
                  <label className="text-sm" htmlFor="last_name">
                    Nom *
                  </label>
                  <input
                    style={
                      formikContact.touched.last_name &&
                      formikContact.errors.last_name && {
                        border: "solid #b91c1c 2px",
                      }
                    }
                    onChange={formikContact.handleChange}
                    onBlur={formikContact.handleBlur}
                    value={formikContact.values.last_name}
                    type="text"
                    className="p-1 w-full rounded-md focus:ring focus:ring-opacity-75 focus:ring-sky-600 border-gray-300 text-gray-900"
                    id="last_name"
                    name="last_name"
                  />
                  {formikContact.touched.last_name &&
                  formikContact.errors.last_name ? (
                    <div
                      className={
                        "formik-error-container text-red-700 text-center font-semibold text-sm my-2"
                      }>
                      {formikContact.errors.last_name}
                    </div>
                  ) : null}
                </div>
                <div className="col-span-full sm:col-span-3">
                  <label className="text-sm" htmlFor="first_name">
                    Prénom *
                  </label>
                  <input
                    style={
                      formikContact.touched.first_name &&
                      formikContact.errors.first_name && {
                        border: "solid #b91c1c 2px",
                      }
                    }
                    onChange={formikContact.handleChange}
                    onBlur={formikContact.handleBlur}
                    value={formikContact.values.first_name}
                    type="text"
                    className="p-1 w-full rounded-md focus:ring focus:ring-opacity-75 focus:ring-sky-600 border-gray-300 text-gray-900"
                    id="first_name"
                    name="first_name"
                  />
                  {formikContact.touched.first_name &&
                  formikContact.errors.first_name ? (
                    <div
                      className={
                        "formik-error-container text-red-700 text-center font-semibold text-sm my-2"
                      }>
                      {formikContact.errors.first_name}
                    </div>
                  ) : null}
                </div>
                <div className="col-span-full sm:col-span-3">
                  <label className="text-sm" htmlFor="phone_number">
                    Numéro de téléphone *
                  </label>
                  <input
                    style={
                      formikContact.touched.phone_number &&
                      formikContact.errors.phone_number && {
                        border: "solid #b91c1c 2px",
                      }
                    }
                    onChange={formikContact.handleChange}
                    onBlur={formikContact.handleBlur}
                    value={formikContact.values.phone_number}
                    type="text"
                    className="p-1 w-full rounded-md focus:ring focus:ring-opacity-75 focus:ring-sky-600 border-gray-300 text-gray-900"
                    id="phone_number"
                    name="phone_number"
                  />
                  {formikContact.touched.phone_number &&
                  formikContact.errors.phone_number ? (
                    <div
                      className={
                        "formik-error-container text-red-700 text-center font-semibold text-sm my-2"
                      }>
                      {formikContact.errors.phone_number}
                    </div>
                  ) : null}
                </div>
                <div className="col-span-full sm:col-span-3">
                  <label className="text-sm" htmlFor="email">
                    Email *
                  </label>
                  <input
                    style={
                      formikContact.touched.email &&
                      formikContact.errors.email && {
                        border: "solid #b91c1c 2px",
                      }
                    }
                    onChange={formikContact.handleChange}
                    onBlur={formikContact.handleBlur}
                    value={formikContact.values.email}
                    type="text"
                    className="p-1 w-full rounded-md focus:ring focus:ring-opacity-75 focus:ring-sky-600 border-gray-300 text-gray-900"
                    id="email"
                    name="email"
                  />
                  {formikContact.touched.email && formikContact.errors.email ? (
                    <div
                      className={
                        "formik-error-container text-red-700 text-center font-semibold text-sm my-2"
                      }>
                      {formikContact.errors.email}
                    </div>
                  ) : null}
                </div>
                <div className="col-span-full">
                  <label className="text-sm" htmlFor="adress">
                    Addresse *
                  </label>
                  <input
                    style={
                      formikContact.touched.adress &&
                      formikContact.errors.adress && {
                        border: "solid #b91c1c 2px",
                      }
                    }
                    onChange={formikContact.handleChange}
                    onBlur={formikContact.handleBlur}
                    value={formikContact.values.adress}
                    type="text"
                    className="p-1 w-full rounded-md focus:ring focus:ring-opacity-75 focus:ring-sky-600 border-gray-300 text-gray-900"
                    id="adress"
                    name="adress"
                  />
                  {formikContact.touched.adress &&
                  formikContact.errors.adress ? (
                    <div
                      className={
                        "formik-error-container text-red-700 text-center font-semibold text-sm my-2"
                      }>
                      {formikContact.errors.adress}
                    </div>
                  ) : null}
                </div>
                <div className="col-span-3">
                  <label className="text-sm" htmlFor="post_code">
                    Code postal *
                  </label>
                  <input
                    style={
                      formikContact.touched.post_code &&
                      formikContact.errors.post_code && {
                        border: "solid #b91c1c 2px",
                      }
                    }
                    onChange={formikContact.handleChange}
                    onBlur={formikContact.handleBlur}
                    value={formikContact.values.post_code}
                    type="text"
                    className="p-1 w-full rounded-md focus:ring focus:ring-opacity-75 focus:ring-sky-600 border-gray-300 text-gray-900"
                    id="post_code"
                    name="post_code"
                  />
                  {formikContact.touched.post_code &&
                  formikContact.errors.post_code ? (
                    <div
                      className={
                        "formik-error-container text-red-700 text-center font-semibold text-sm my-2"
                      }>
                      {formikContact.errors.post_code}
                    </div>
                  ) : null}
                </div>
                <div className="col-span-3">
                  <label className="text-sm" htmlFor="city">
                    Ville *
                  </label>
                  <input
                    style={
                      formikContact.touched.city &&
                      formikContact.errors.city && {
                        border: "solid #b91c1c 2px",
                      }
                    }
                    onChange={formikContact.handleChange}
                    onBlur={formikContact.handleBlur}
                    value={formikContact.values.city}
                    type="text"
                    className="p-1 w-full rounded-md focus:ring focus:ring-opacity-75 focus:ring-sky-600 border-gray-300 text-gray-900"
                    id="city"
                    name="city"
                  />
                  {formikContact.touched.city && formikContact.errors.city ? (
                    <div
                      className={
                        "formik-error-container text-red-700 text-center font-semibold text-sm my-2"
                      }>
                      {formikContact.errors.city}
                    </div>
                  ) : null}
                </div>
                <div className="col-span-full sm:col-span-2">
                  <label className="text-sm" htmlFor="product_category">
                    Type de produit *
                  </label>
                  <input
                    style={
                      formikContact.touched.product_category &&
                      formikContact.errors.product_category && {
                        border: "solid #b91c1c 2px",
                      }
                    }
                    onChange={formikContact.handleChange}
                    onBlur={formikContact.handleBlur}
                    value={formikContact.values.product_category}
                    type="text"
                    className="p-1 w-full rounded-md focus:ring focus:ring-opacity-75 focus:ring-sky-600 border-gray-300 text-gray-900"
                    id="product_category"
                    name="product_category"
                  />
                  {formikContact.touched.product_category &&
                  formikContact.errors.product_category ? (
                    <div
                      className={
                        "formik-error-container text-red-700 text-center font-semibold text-sm my-2"
                      }>
                      {formikContact.errors.product_category}
                    </div>
                  ) : null}
                </div>
                <div className="col-span-full sm:col-span-2">
                  <label className="text-sm" htmlFor="brand">
                    Marque *
                  </label>
                  <input
                    style={
                      formikContact.touched.brand &&
                      formikContact.errors.brand && {
                        border: "solid #b91c1c 2px",
                      }
                    }
                    onChange={formikContact.handleChange}
                    onBlur={formikContact.handleBlur}
                    value={formikContact.values.brand}
                    type="text"
                    className="p-1 w-full rounded-md focus:ring focus:ring-opacity-75 focus:ring-sky-600 border-gray-300 text-gray-900"
                    id="brand"
                    name="brand"
                  />
                  {formikContact.touched.brand && formikContact.errors.brand ? (
                    <div
                      className={
                        "formik-error-container text-red-700 text-center font-semibold text-sm my-2"
                      }>
                      {formikContact.errors.brand}
                    </div>
                  ) : null}
                </div>
                <div className="col-span-full sm:col-span-2">
                  <label className="text-sm" htmlFor="reference">
                    Rérérence *
                  </label>
                  <input
                    style={
                      formikContact.touched.reference &&
                      formikContact.errors.reference && {
                        border: "solid #b91c1c 2px",
                      }
                    }
                    onChange={formikContact.handleChange}
                    onBlur={formikContact.handleBlur}
                    value={formikContact.values.reference}
                    type="text"
                    className="p-1 w-full rounded-md focus:ring focus:ring-opacity-75 focus:ring-sky-600 border-gray-300 text-gray-900"
                    id="reference"
                    name="reference"
                  />
                  {formikContact.touched.reference &&
                  formikContact.errors.reference ? (
                    <div
                      className={
                        "formik-error-container text-red-700 text-center font-semibold text-sm my-2"
                      }>
                      {formikContact.errors.reference}
                    </div>
                  ) : null}
                </div>
                <div className="col-span-full">
                  <label className="text-sm" htmlFor="message">
                    Nature de la panne et/ou code panne *
                  </label>
                  <textarea
                    cols={80}
                    rows={10}
                    style={
                      formikContact.touched.message &&
                      formikContact.errors.message && {
                        border: "solid #b91c1c 2px",
                      }
                    }
                    onChange={formikContact.handleChange}
                    onBlur={formikContact.handleBlur}
                    value={formikContact.values.message}
                    id="message"
                    name="message"
                    className={`w-full resize-none rounded-md focus:ring focus:ri focus:ri  text-gray-800 p-2 border-2 border-gray-800  shadow-sm`}
                  />
                  {formikContact.touched.message &&
                  formikContact.errors.message ? (
                    <div
                      className={
                        "formik-error-container text-red-700 text-center font-semibold text-sm my-2"
                      }>
                      {formikContact.errors.message}
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="col-span-full flex justify-center">
                <button
                  className="px-8 py-3 font-semibold rounded bg-gray-800 text-gray-100 block mx-auto"
                  type="submit">
                  Envoyer
                </button>
              </div>
            </fieldset>
          </form>
        </section>
      </div>
    </main>
  );
};

export default Contact;
