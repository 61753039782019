import React from "react";

import Slider from "react-slick";

import ClientProductCard from "../ClientProductCard/ClientProductCard";
const CarouselSlick = ({ brandsData, brand }) => {
  const settingsSlider = {
    mobileFirst: true,
    dots: false,
    arrows: true,
    autoplay: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          arrows: false,
        },
      },
    ],
    // centerMode: true,
  };

  return (
    <Slider className="carrousel-home" {...settingsSlider}>
      {brandsData &&
        brandsData.length > 0 &&
        brandsData.map((item) => {
          if (item.logo && brand) {
            return (
              <div key={item._id} className="p-5">
                <img
                  src={item.logo}
                  alt={`logo de la marque ${item["name"]}`}
                />
              </div>
            );
          }
        })}
    </Slider>
  );
};

export default CarouselSlick;
