import React from "react";

import { useNavigate } from "react-router-dom";

import { statutUpdate, updateSingleField } from "../../utils/RequestFunctions";

const TableDeleteUpdateButton = ({
  setIsLoading,
  setErrorMessage,
  collection,
  token,
  index,
  dataList,
  setDataList,
  setDisplayList,
  setModale,
  fieldsArray,
}) => {
  const navigate = useNavigate();
  return (
    <>
      {!fieldsArray.find((elmt) => elmt.colTitle === "Statut") && (
        <td>
          <div
            title={`${
              dataList && dataList[index] && dataList[index]["statut"]
                ? "désactiver"
                : "activer"
            }`}
            className={`rounded-3xl ${
              dataList && dataList[index] && dataList[index]["statut"]
                ? "bg-green-700"
                : "bg-red-600"
            } h-5 w-5 cursor-pointer`}
            onClick={() => {
              updateSingleField(
                setIsLoading,
                setErrorMessage,
                "statut",
                collection,
                token,
                index,
                dataList,
                setDataList
              );
            }}></div>
        </td>
      )}
      {/* <td>
        <div
          title={`${
            dataList && dataList[index] && dataList[index]["statut"]
              ? "désactiver"
              : "activer"
          }`}
          className={`rounded-3xl ${
            dataList && dataList[index] && dataList[index]["statut"]
              ? "bg-green-700"
              : "bg-red-600"
          } h-5 w-5 cursor-pointer`}
          onClick={() => {
            updateSingleField(
              setIsLoading,
              setErrorMessage,
              "statut",
              collection,
              token,
              index,
              dataList,
              setDataList
            );
          }}></div>
      </td> */}
      <td className="flex flex-col items-center">
        <button
          className="px-4 py-2 my-2 font-semibold rounded  hover:bg-orange-700 bg-orange-600 text-gray-100"
          onClick={() =>
            navigate(
              `/backoffice/${collection}/update/${dataList[index]["_id"]}`
            )
          }>
          Modifier
        </button>

        {setModale && (
          <button
            className="px-4 py-2 my-2 font-semibold rounded hover:bg-red-900 bg-red-800 text-gray-100"
            onClick={async () => {
              setModale({
                list: dataList,
                item: dataList[index],
                index: index,
                setDataList: setDataList,
                setDisplayList: setDisplayList,
                collection: collection,
              });
            }}>
            Supprimer
          </button>
        )}
      </td>
    </>
  );
};

export default TableDeleteUpdateButton;
