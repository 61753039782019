import React, { useEffect, useState } from "react";

// IMPORT LIB
import axios from "axios";

// IMPORT UTILS
import {
  washingMachineFeatureToKeepList,
  dishWasherFeatureToKeepList,
  dryerFeatureToKeepList,
  refrigeratorFeatureToKeepList,
  freezerFeatureToKeepList,
  wineCellarFeatureToKeepList,
  ovenFeatureToKeepList,
  microWaveFeatureToKeepList,
  integratedHoodFeatureToKeepList,
  cookTopFeatureToKeepList,
  vacuumFeatureToKeepList,
  steamCentralFeatureToKeepList,
} from "../../../Shared/utils/ProductFeatureToKeepArrays";

import { useLoadingErrorContext } from "../../../Shared/Context/LoadingErrorContext";
import FormInput from "../../../Shared/components/FormInput/FormInput";

import { sendDataToServer } from "../../../Shared/utils/RequestFunctions";

const IceCatComponent = ({
  productToSend,
  setProductToSend,
  category,
  validateFunction,
  brandsDataToDisplay,
  setBrandsDataToDisplay,
  userSession,
  setModale,
  pageType,
}) => {
  const { isLoading, setIsLoading, errorMessage, setErrorMessage } =
    useLoadingErrorContext();

  const [iceCatLoading, setIceCatLoading] = useState(false);

  const [formatedInputArrayForDisplay, setFormatedInputArrayForDisplay] =
    useState([]);

  const inputArray = [
    {
      type: "select",
      objectKey: "category",
      required: true,
      label: "Choisissez une catégorie de navigation",
      multipleAttribute: false,
      arrayOptions: category,
    },
    {
      type: "select",
      objectKey: "sub_category",
      required: true,
      label: "Choisissez une sous catégorie de navigation",
      arrayOptions: category?.find(
        (elmt) => elmt["_id"] === productToSend.values.category
      )?.sub_category,
    },
    {
      type: "text",
      objectKey: "EAN",
      required: true,
      label: "Code EAN",
    },
  ];

  // const formatInputJSX = () => {
  //   const formattedInputs = [];
  //   const maxIndex = inputArray.length;

  //   for (let i = 0; i < maxIndex; i += 2) {
  //     const inputPair = inputArray.slice(i, i + 2);

  //     const inputGroupElements = [];

  //     inputPair.forEach((input, index) => {
  //       if (
  //         input["objectKey"] === "category" ||
  //         productToSend.values["category"]
  //       ) {
  //         inputGroupElements.push(
  //           <FormInput
  //             iceCat={true}
  //             key={i + index}
  //             index={i + index}
  //             page={"iceCat-request-component"}
  //             state={productToSend}
  //             setState={setProductToSend}
  //             validateFunction={validateFunction}
  //             inputType={input["type"]}
  //             objectKey={input["objectKey"]}
  //             required={input["required"] ? true : false}
  //             label={input["label"] ? input["label"] : ""}
  //             placeholder={input["placeholder"] ? input["placeholder"] : ""}
  //             isError={
  //               input["required"] &&
  //               productToSend.touched[input["objectKey"]] &&
  //               productToSend.errors[input["objectKey"]]
  //             }
  //             arrayOptions={input["arrayOptions"] ? input["arrayOptions"] : []}
  //           />
  //         );
  //       }
  //     });

  //     formattedInputs.push(
  //       <fieldset className="w-[75%]" key={i}>
  //         {inputGroupElements}
  //       </fieldset>
  //     );
  //   }

  //   setFormatedInputArrayForDisplay(formattedInputs);
  // };

  const formatIceCatData = async (data) => {
    const iceCatData = data;

    let galleryPicArray = [];
    const productDataValues = {};

    productDataValues["name"] = iceCatData.GeneralInfo.Title
      ? iceCatData.GeneralInfo.Title
      : "";

    productDataValues["description"] = iceCatData.GeneralInfo?.Description
      ?.LongDesc
      ? iceCatData.GeneralInfo.Description.LongDesc
      : iceCatData.GeneralInfo.SummaryDescription.LongSummaryDescription
      ? iceCatData.GeneralInfo.SummaryDescription.LongSummaryDescription
      : "";

    productDataValues["picture_presentation"] = iceCatData.Image?.HighPic
      ? iceCatData.Image.HighPic
      : "";

    iceCatData.Gallery?.filter((elmt) => elmt.Type === "ProductImage").map(
      (galleryElmt, index) =>
        index < 5 && galleryPicArray.push(galleryElmt["Pic"])
    );

    productDataValues["picture_gallery"] = galleryPicArray;

    productDataValues["brand_name"] = iceCatData.GeneralInfo?.Brand
      ? iceCatData.GeneralInfo.Brand
      : "";

    if (iceCatData.GeneralInfo.Brand) {
      const isBrandAlreadySaved = brandsDataToDisplay.find(
        (elmt) => elmt.name === iceCatData.GeneralInfo.Brand.toLowerCase()
      );

      if (isBrandAlreadySaved) {
        productDataValues["brand_id"] = isBrandAlreadySaved["_id"];
      } else {
        const associated_categories = [];
        category.forEach((cate) => {
          associated_categories.push(cate["_id"]);
        });

        const brandResponse = await sendDataToServer(
          setIsLoading,
          setErrorMessage,
          userSession.token,
          "POST",
          "brand",
          {
            associated_categories: JSON.stringify(associated_categories),
            brand_name: iceCatData.GeneralInfo.Brand,
            brand_logo: iceCatData.GeneralInfo.BrandLogo,
          },
          () => {},
          `Succès enregistrement de la marque`,
          "marques"
        );

        productDataValues["brand_id"] = brandResponse.message["_id"];

        setBrandsDataToDisplay((prevState) => {
          return [...prevState, brandResponse.message];
        });
      }
    }

    productDataValues["EAN"] = iceCatData.GeneralInfo?.GTIN[0]
      ? iceCatData.GeneralInfo.GTIN[0]
      : "";

    productDataValues["reference"] = iceCatData.GeneralInfo?.BrandPartCode
      ? iceCatData.GeneralInfo.BrandPartCode
      : "";

    productDataValues["repairability_picture"] = iceCatData.Multimedia?.find(
      (elmt) => elmt.Type === "Repairability index overview"
    )
      ? iceCatData.Multimedia.find(
          (elmt) => elmt.Type === "Repairability index overview"
        )["URL"]
      : "";

    productDataValues["energy_label"] = iceCatData.Multimedia?.find(
      (elmt) => elmt.Type === "EU Energy Label"
    )
      ? iceCatData.Multimedia.find((elmt) => elmt.Type === "EU Energy Label")[
          "URL"
        ]
      : "";

    productDataValues["eu_product_fiche"] = iceCatData.Multimedia?.find(
      (elmt) => elmt.Type === "EU Product Fiche"
    )
      ? iceCatData.Multimedia.find((elmt) => elmt.Type === "EU Product Fiche")[
          "URL"
        ]
      : "";

    productDataValues["leaflet"] = iceCatData.Multimedia?.find(
      (elmt) => elmt.Type === "leaflet"
    )
      ? iceCatData.Multimedia.find((elmt) => elmt.Type === "leaflet")["URL"]
      : "";

    productDataValues["manual"] = iceCatData.Multimedia?.find(
      (elmt) => elmt.Type === "manual pdf"
    )
      ? iceCatData.Multimedia.find((elmt) => elmt.Type === "manual pdf")["URL"]
      : "";

    productDataValues["features"] = [];

    iceCatData.FeaturesGroups.forEach((featureGroup) => {
      featureGroup.Features.forEach((feature) => {
        let repairabilityIndexData =
          feature.Feature?.ID === "42930" ? feature : null;

        if (repairabilityIndexData) {
          productDataValues["repairability_index"] =
            repairabilityIndexData.LocalValue;
        }

        let filterFeatureArray = [];

        switch (productToSend.values.sub_category) {
          case "656e410e9e4553cf1eab1aaf":
          case "656e410e9e4553cf1eab1abf":
            filterFeatureArray = washingMachineFeatureToKeepList;
            break;
          case "656e410e9e4553cf1eab1ab0":
          case "656e410e9e4553cf1eab1abe":
            filterFeatureArray = dishWasherFeatureToKeepList;
            break;
          case "656e410e9e4553cf1eab1ab1":
            filterFeatureArray = dryerFeatureToKeepList;
            break;
          case "656e410e9e4553cf1eab1ab2":
          case "656e410e9e4553cf1eab1abb":
            filterFeatureArray = refrigeratorFeatureToKeepList;
            break;
          case "656e410e9e4553cf1eab1ab3":
          case "656e410e9e4553cf1eab1abc":
            filterFeatureArray = freezerFeatureToKeepList;
            break;
          case "656e410e9e4553cf1eab1ab4":
            filterFeatureArray = wineCellarFeatureToKeepList;
            break;
          case "656e410e9e4553cf1eab1ab8":
          case "656e410e9e4553cf1eab1ab7":
            filterFeatureArray = ovenFeatureToKeepList;
            break;
          case "656e410e9e4553cf1eab1ab6":
          case "656e410e9e4553cf1eab1ab9":
            filterFeatureArray = microWaveFeatureToKeepList;
            break;
          case "656e410e9e4553cf1eab1abd":
            filterFeatureArray = integratedHoodFeatureToKeepList;

            break;
          case "656e410e9e4553cf1eab1aba":
            filterFeatureArray = cookTopFeatureToKeepList;
            break;
          case "666b8d828d42be25962a3cc2":
            filterFeatureArray = vacuumFeatureToKeepList;
            break;
          case "666b8e048d42be25962a3cc4":
            filterFeatureArray = steamCentralFeatureToKeepList;
            break;
          default:
            filterFeatureArray = [];
            break;
        }

        let featureToKeep = filterFeatureArray.find(
          (elmt) => elmt.featureName === feature.Feature.Name.Value
        );

        if (featureToKeep) {
          productDataValues["features"].push({
            id: feature.Feature.ID,
            value: feature.LocalValue,
          });
        }
      });
    });

    return productDataValues;
  };

  const fetchProductFromIceCat = async (e) => {
    e.preventDefault();

    setIceCatLoading(true);
    setErrorMessage("");

    if (!validateFunction(productToSend)) {
      try {
        const url_iceCat = `https://live.icecat.biz/api?UserName=pierredecreesy&Language=fr&GTIN=${productToSend.values.EAN}`;
        // console.log(url_iceCat);

        const response = await axios.get(url_iceCat);

        const iceCatDataFormated = await formatIceCatData(response.data.data);

        setProductToSend((prevState) => {
          return {
            ...prevState,
            values: Object.assign(prevState.values, iceCatDataFormated),
          };
        });
      } catch (error) {
        if (error.response && error.response.data?.Message) {
          setErrorMessage({
            title: `Erreur ${
              error.response.status ? error.response.status : ""
            }`,
            content: error.response.data.Message,
          });
        } else if (error && error.message) {
          setErrorMessage({
            title: `Error ${
              error.response && error.response.status
                ? error.response.status
                : ""
            }`,
            content: error.message,
          });
        }

        setProductToSend((prevState) => {
          prevState.values = {
            category: prevState.values.category,
            sub_category: prevState.values.sub_category,
          };
          return prevState;
        });
      }
    }
    setIceCatLoading(false);
  };

  return (
    <form
      className="bg-gray-100 flex flex-col border-b-2 border-gray-800 shadow-lg relative"
      onSubmit={(e) => fetchProductFromIceCat(e)}>
      <h1 className="py-4 mb-5 font-bold text-center text-xl border-b-2 border-red-800">
        {pageType === "create"
          ? "Créer un nouveau produit"
          : "Mettre à jour un produit"}
      </h1>
      <div className="w-full flex items-end">
        {inputArray &&
          inputArray.length > 0 &&
          inputArray.map((input, index) => {
            if (
              input["objectKey"] === "category" ||
              productToSend.values["category"]
            ) {
              return (
                <FormInput
                  key={index}
                  index={index}
                  state={productToSend}
                  setState={setProductToSend}
                  validateFunction={validateFunction}
                  page={"api-request-component"}
                  inputType={input["type"]}
                  objectKey={input["objectKey"]}
                  required={input["required"] ? true : false}
                  label={input["label"] ? input["label"] : ""}
                  placeholder={input["placeholder"] ? input["placeholder"] : ""}
                  isError={
                    input["required"] &&
                    productToSend.touched[input["objectKey"]] &&
                    productToSend.errors[input["objectKey"]]
                  }
                  arrayOptions={
                    input["arrayOptions"] ? input["arrayOptions"] : []
                  }
                  iceCat={true}
                />
              );
            }
          })}
      </div>

      {/* {formatedInputArrayForDisplay.map((item) => {
        return item;
      })} */}

      <button
        className="px-8 py-3 w-[25%] mx-auto flex justify-center items-center my-8 font-semibold rounded bg-gray-800 text-gray-100"
        type="submit">
        {iceCatLoading ? (
          <div className="w-10 h-10 border-4 border-dashed rounded-full animate-spin border-red-800"></div>
        ) : (
          "Demander à la base de données"
        )}
      </button>
    </form>
  );
};

export default IceCatComponent;
