import React, { useEffect, useState } from "react";

import { useLoadingErrorContext } from "../../Context/LoadingErrorContext";

import { sendDataToServer } from "../../utils/RequestFunctions";
import { useFormik } from "formik";

const ModalBrand = ({ modale, setModale, productID, productSingleData }) => {
  const { setErrorMessage, setIsLoading, isLoading } = useLoadingErrorContext();

  const validateInformationRequest = (value) => {
    const errors = {};
    if (!value.last_name) {
      errors.last_name = "Merci de renseigner de champ";
    }

    if (!value.last_name.toLowerCase().match(/^[a-zA-Z\-]+$/)) {
      errors.last_name =
        "Le nom de famille ne doit contenir que des lettres ou des tirets (-)";
    }

    if (!value.phone_number) {
      errors.phone_number = "Merci de renseigner de champ";
    }

    if (
      !value.phone_number
        .toLowerCase()
        .match(/^([+]?\d{1,2}[-\s]?|)\d{3}[-\s]?\d{3}[-\s]?\d{4}$/)
    ) {
      errors.phone_number = "Merci de renseigner un numéro de téléphone valide";
    }

    if (!value.message) {
      errors.message = "Merci de renseigner de champ";
    }

    if (!value.email) {
      errors.email = "Merci de renseigner de champ";
    }

    if (
      !value.email
        .toLowerCase()
        .match(
          /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/
        )
    ) {
      errors.email = "Merci de renseigner un email valide";
    }

    return errors;
  };

  const sendInformationRequestToServer = async (v) => {
    const response = await sendDataToServer(
      setIsLoading,
      setErrorMessage,
      "",
      "POST",
      "informations_request",
      v,
      setModale,
      "Réussi",
      ""
    );

    if (response && response.statut === "OK") {
      setModale({
        succes: true,
      });
    }
  };

  const formikInformationRequest = useFormik({
    initialValues: {
      last_name: "",
      phone_number: "",
      email: "",
      message: `Je souhaite avoir des informations sur la réference: ${
        productSingleData["reference"]
      } de chez ${productSingleData["brand"]["name"].toUpperCase()}.`,
      product: productID,
    },
    validate: validateInformationRequest,
    onSubmit: (v) => {
      sendInformationRequestToServer(v);
      setErrorMessage("");
    },
  });

  return (
    <div className="z-50 min-h-[100vh] fixed top-0 left-0 w-full border-2 border-red-800 flex justify-center items-center">
      <div className="flex flex-col max-w-md gap-2 p-6 rounded-md shadow-md bg-gray-50 text-gray-800">
        <div
          className="flex justify-end items-center cursor-pointer text-red-800 hover:text-gray-800"
          onClick={() => setModale(false)}>
          {" "}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            fill="currentColor"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className=" w-[1.4em]">
            <path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM175 175c-9.4 9.4-9.4 24.6 0 33.9l47 47-47 47c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l47-47 47 47c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-47-47 47-47c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-47 47-47-47c-9.4-9.4-24.6-9.4-33.9 0z" />
          </svg>
        </div>
        <h2 className="flex justify-center items-center gap-2 text-xl font-semibold leading-tight tracking-wide">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            className="w-6 h-6 fill-current shrink-0 text-gray-800">
            <path d="M451.671,348.569,408,267.945V184c0-83.813-68.187-152-152-152S104,100.187,104,184v83.945L60.329,348.568A24,24,0,0,0,81.432,384h86.944c-.241,2.636-.376,5.3-.376,8a88,88,0,0,0,176,0c0-2.7-.135-5.364-.376-8h86.944a24,24,0,0,0,21.1-35.431ZM312,392a56,56,0,1,1-111.418-8H311.418A55.85,55.85,0,0,1,312,392ZM94.863,352,136,276.055V184a120,120,0,0,1,240,0v92.055L417.137,352Z"></path>
            <rect width="32" height="136" x="240" y="112"></rect>
            <rect width="32" height="32" x="240" y="280"></rect>
          </svg>
          {modale["succes"]
            ? "Demande bien enregistrée"
            : "Demande de renseignement"}
        </h2>
        {!modale.succes ? (
          <form
            onSubmit={formikInformationRequest.handleSubmit}
            className="container flex flex-col mx-auto space-y-12">
            <fieldset className="grid gap-6 p-6 rounded-md shadow-sm bg-gray-50">
              <div className="grid grid-cols-6 gap-4 col-span-full lg:col-span-3">
                <div className="col-span-full sm:col-span-3">
                  <label className="text-sm" htmlFor="last_name">
                    Nom *
                  </label>
                  <input
                    style={
                      formikInformationRequest.touched.last_name &&
                      formikInformationRequest.errors.last_name && {
                        border: "solid #b91c1c 2px",
                      }
                    }
                    onChange={formikInformationRequest.handleChange}
                    onBlur={formikInformationRequest.handleBlur}
                    value={formikInformationRequest.values.last_name}
                    type="text"
                    className="p-1 w-full rounded-md focus:ring focus:ring-opacity-75 focus:ring-sky-600 border-gray-300 text-gray-900"
                    id="last_name"
                    name="last_name"
                  />
                  {formikInformationRequest.touched.last_name &&
                  formikInformationRequest.errors.last_name ? (
                    <div
                      className={
                        "formik-error-container text-red-700 text-center font-semibold text-sm my-2"
                      }>
                      {formikInformationRequest.errors.last_name}
                    </div>
                  ) : null}
                </div>

                <div className="col-span-full sm:col-span-3">
                  <label className="text-sm" htmlFor="phone_number">
                    Numéro de téléphone *
                  </label>
                  <input
                    style={
                      formikInformationRequest.touched.phone_number &&
                      formikInformationRequest.errors.phone_number && {
                        border: "solid #b91c1c 2px",
                      }
                    }
                    onChange={formikInformationRequest.handleChange}
                    onBlur={formikInformationRequest.handleBlur}
                    value={formikInformationRequest.values.phone_number}
                    type="text"
                    className="p-1 w-full rounded-md focus:ring focus:ring-opacity-75 focus:ring-sky-600 border-gray-300 text-gray-900"
                    id="phone_number"
                    name="phone_number"
                  />
                  {formikInformationRequest.touched.phone_number &&
                  formikInformationRequest.errors.phone_number ? (
                    <div
                      className={
                        "formik-error-container text-red-700 text-center font-semibold text-sm my-2"
                      }>
                      {formikInformationRequest.errors.phone_number}
                    </div>
                  ) : null}
                </div>
                <div className="col-span-full ">
                  <label className="text-sm" htmlFor="email">
                    Email *
                  </label>
                  <input
                    style={
                      formikInformationRequest.touched.email &&
                      formikInformationRequest.errors.email && {
                        border: "solid #b91c1c 2px",
                      }
                    }
                    onChange={formikInformationRequest.handleChange}
                    onBlur={formikInformationRequest.handleBlur}
                    value={formikInformationRequest.values.email}
                    type="text"
                    className="p-1 w-full rounded-md focus:ring focus:ring-opacity-75 focus:ring-sky-600 border-gray-300 text-gray-900"
                    id="email"
                    name="email"
                  />
                  {formikInformationRequest.touched.email &&
                  formikInformationRequest.errors.email ? (
                    <div
                      className={
                        "formik-error-container text-red-700 text-center font-semibold text-sm my-2"
                      }>
                      {formikInformationRequest.errors.email}
                    </div>
                  ) : null}
                </div>

                <div className="col-span-full">
                  <label className="text-sm" htmlFor="message">
                    Message
                  </label>
                  <textarea
                    cols={80}
                    rows={5}
                    style={
                      formikInformationRequest.touched.message &&
                      formikInformationRequest.errors.message && {
                        border: "solid #b91c1c 2px",
                      }
                    }
                    onChange={formikInformationRequest.handleChange}
                    onBlur={formikInformationRequest.handleBlur}
                    value={formikInformationRequest.values.message}
                    id="message"
                    name="message"
                    className={`w-full resize-none rounded-md focus:ring focus:ri focus:ri  text-gray-800 p-2 border-2 border-gray-800  shadow-sm`}
                  />
                  {formikInformationRequest.touched.message &&
                  formikInformationRequest.errors.message ? (
                    <div
                      className={
                        "formik-error-container text-red-700 text-center font-semibold text-sm my-2"
                      }>
                      {formikInformationRequest.errors.message}
                    </div>
                  ) : null}
                </div>
                <div className="col-span-full  flex justify-center">
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      formikInformationRequest.handleSubmit(e);
                    }}
                    className="px-8 py-3 w-[25%] mx-auto flex justify-center items-center my-8 font-semibold rounded bg-gray-800 text-gray-100"
                    type="submit">
                    {isLoading ? (
                      <div className="w-10 h-10 border-4 border-dashed rounded-full animate-spin border-red-800"></div>
                    ) : (
                      "Envoyer"
                    )}
                  </button>
                </div>
              </div>
            </fieldset>
          </form>
        ) : (
          <div className="flex flex-col justify-between items-center">
            <h3>Votre demande à bien été envoyée</h3>
          </div>
        )}
      </div>
    </div>
  );
};

export default ModalBrand;
