import React, { useEffect, useRef } from "react";
import "ol/ol.css"; // Importez les styles CSS d'OpenLayers
import Map from "ol/Map";
import View from "ol/View";
import TileLayer from "ol/layer/Tile";
import OSM from "ol/source/OSM";
import Feature from "ol/Feature";
import Point from "ol/geom/Point";
import { fromLonLat } from "ol/proj";
import { Vector as VectorLayer } from "ol/layer";
import { Vector as VectorSource } from "ol/source";
import { Icon, Style } from "ol/style";
import Zoom from "ol/control/Zoom";

import MarkerLogo from "../../../../assets/img/kc_marker.png";

const FooterMap = ({ nameClass }) => {
  const position = [-1.621733, 43.40787383643871];

  const mapRef = useRef(null);

  useEffect(() => {
    document.getElementById("map").innerHTML = "";

    const map = new Map({
      target: "map",
      interactions: [],
      controls: [],
      layers: [
        new TileLayer({
          source: new OSM(),
        }),
      ],
      view: new View({
        center: fromLonLat(position),
        zoom: 14,
      }),
    });

    const marker = new Feature({
      geometry: new Point(fromLonLat(position)),
    });

    const markerStyle = new Style({
      image: new Icon({
        src: MarkerLogo,
        scale: 0.2,
        anchor: [0.5, 1],
      }),
    });

    marker.setStyle(markerStyle);

    const vectorSource = new VectorSource({
      features: [marker],
    });

    const vectorLayer = new VectorLayer({
      source: vectorSource,
    });

    map.addLayer(vectorLayer);
  }, []);

  return <div ref={mapRef} id="map" className="h-[30vh] lg:w-[65%]"></div>;
};

export default FooterMap;
