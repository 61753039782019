import { useEffect } from "react";

import { createContext, useState, useContext } from "react";

const LoadingErrorContext = createContext();

export const LoadingErrorProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [innerWidthState, setInnerWidthState] = useState(window.innerWidth);

  const updateInnerWidthStateHandle = () => {
    setInnerWidthState(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", updateInnerWidthStateHandle);

    return () => {
      window.removeEventListener("resize", updateInnerWidthStateHandle);
    };
  }, []);

  const LoadingErrorContextValue = {
    isLoading,
    errorMessage,
    setIsLoading,
    setErrorMessage,
    innerWidthState,
    setInnerWidthState,
  };

  return (
    <LoadingErrorContext.Provider value={LoadingErrorContextValue}>
      {children}
    </LoadingErrorContext.Provider>
  );
};

export const useLoadingErrorContext = () => {
  return useContext(LoadingErrorContext);
};
