import React, { useState, useEffect } from "react";

// IMPORT LIB

// IMPORT CONTEXT
import { useLoadingErrorContext } from "../../Shared/Context/LoadingErrorContext";

// IMPORT UTILS
import { requestHandle } from "../../Shared/utils/RequestFunctions";

// IMPORT COMPONENT
import BackofficeGoBack from "../../Shared/components/BackofficeGoBack/BackofficeGoBack";
import AlertMessage from "../../Shared/components/AlertMessage/AlertMessage";
import ModalDelete from "../../Shared/components/Modals/ModalDelete";
import LoadingWrapper from "../../Shared/components/LoadingWrapper/LoadingWrapper";
import TableComponent from "../../Shared/components/TableComponent/TableComponent";

const ContactBackList = ({ userSession }) => {
  const { isLoading, setIsLoading, errorMessage, setErrorMessage } =
    useLoadingErrorContext();

  const [modaleDelete, setModaleDelete] = useState(false);

  const [contactList, setContactList] = useState([]);
  const [listToDisplay, setListToDisplay] = useState([]);

  const fetchContactList = async () => {
    const response = await requestHandle(
      setIsLoading,
      setErrorMessage,
      userSession.token,
      "GET",
      "sav"
    );
    if (response && response.statut === "OK") {
      setContactList(response.message);
      setListToDisplay(response.message);
    }
  };

  useEffect(() => {
    fetchContactList();
  }, []);

  return (
    <main id="contact-liste-container" className="backoffice-container">
      {errorMessage ? (
        <AlertMessage
          errorMessage={errorMessage}
          setErrorMessage={setErrorMessage}
        />
      ) : null}

      {modaleDelete ? (
        <ModalDelete
          setIsLoading={setIsLoading}
          setErrorMessage={setErrorMessage}
          modale={modaleDelete}
          setModale={setModaleDelete}
          token={userSession.token}
        />
      ) : null}

      <TableComponent
        token={userSession.token}
        // setModaleDelete={setModaleDelete}
        collection={"sav"}
        listTitle={"Les demandes de dépannages"}
        setListArray={setContactList}
        listArray={contactList.sort(
          (a, b) => new Date(b.created_at) - new Date(a.created_at)
        )}
        listToDisplay={listToDisplay}
        setListToDisplay={setListToDisplay}
        fieldsArray={[
          { colTitle: "Nom", type: "text", objectKey: "last_name" },
          { colTitle: "Prénom", type: "text", objectKey: "first_name" },
          {
            colTitle: "Numéro de téléphone",
            type: "text",
            objectKey: "phone_number",
          },
          { colTitle: "Email", type: "text", objectKey: "email" },
          { colTitle: "Adresse", type: "text", objectKey: "adress" },
          { colTitle: "Code postal", type: "text", objectKey: "post_code" },
          { colTitle: "Ville", type: "text", objectKey: "city" },
          {
            colTitle: "Catégorie de produit",
            type: "text",
            objectKey: "product_category",
          },

          { colTitle: "Marque", type: "text", objectKey: "brand" },
          { colTitle: "Référence", type: "text", objectKey: "reference" },
          {
            colTitle: "Numéro de série",
            type: "text",
            objectKey: "serial_nummber",
          },

          { colTitle: "Message", type: "text", objectKey: "message" },
          { colTitle: "Memo", type: "text", objectKey: "personal_notes" },

          {
            colTitle: "Date d'envoi",
            type: "date",
            objectKey: "sending_date",
          },
          { colTitle: "Statut", type: "trileen", objectKey: "statut" },
        ]}
      />
    </main>
  );
};

export default ContactBackList;
