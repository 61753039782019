import React from "react";

import PreviewPicture from "./PreviewPicture";

import { onChangeFormInput } from "../../../utils/EventFunctions";
const FileInput = ({
  index,
  page,
  state,
  setState,
  objectKey,
  isError,
  disableCondition,
  validateFunction,
  multipleAttribute,
  pdf,
}) => {
  return (
    <>
      {state.values && state.values[objectKey] && !pdf && (
        <PreviewPicture
          objectKey={objectKey}
          state={state}
          setState={setState}
          multiple={multipleAttribute ? true : false}
        />
      )}
      <input
        type="file"
        className={`block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 p-3`}
        id={`form-input-${page}-${index}}`}
        name={`form-input-${page}-${index}}`}
        multiple={multipleAttribute ? true : false}
        disabled={disableCondition ? true : false}
        style={isError ? { border: "solid var(--danger-color) 2px" } : {}}
        onChange={(e) => {
          if (!multipleAttribute) {
            onChangeFormInput(
              objectKey,
              e.target.files[0],
              state,
              setState,
              validateFunction
            );
          } else {
            onChangeFormInput(
              objectKey,

              Array.from(e.target.files),
              state,
              setState,
              validateFunction
            );
          }
        }}
      />

      {pdf ? (
        <div
          title={
            !state.values[objectKey] &&
            "Aucun document, merci d’en téléverser un"
          }
          className="flex flex-col items-center">
          <a
            className={`${
              !state.values[objectKey] ? "bg-gray-300" : "bg-gray-800"
            } rounded-md px-4 py-2 my-2 text-gray-100 block`}
            style={
              !state.values[objectKey]
                ? {
                    cursor: "not-allowed",
                    pointerEvents: "none",
                  }
                : {}
            }
            href={state.values[objectKey] ? state.values[objectKey] : ""}
            target="_blank">
            Ouvrir le document
          </a>

          <button
            style={
              !state.values[objectKey]
                ? {
                    cursor: "not-allowed",
                    pointerEvents: "none",
                  }
                : {}
            }
            className={`${
              !state.values[objectKey] ? "bg-gray-300" : "bg-gray-800"
            } rounded-md px-4 py-2 my-2 text-gray-100 block`}
            onClick={() => {
              const copyState = { ...state };
              copyState.values[objectKey] = "";
              setState(copyState);
            }}>
            Supprimer le document
          </button>
        </div>
      ) : null}
    </>
  );
};

export default FileInput;
