import React from "react";

import { Link } from "react-router-dom";

const BackofficeGoBack = ({ collection, listeName }) => {
  return (
    <div>
      {listeName && collection && (
        <Link to={`/backoffice/${collection}/liste`}>
          Aller à la liste des {listeName}
        </Link>
      )}

      <Link to={"/backoffice/home"}>
        Aller à la page d'acceuil du backoffice
      </Link>
    </div>
  );
};

export default BackofficeGoBack;
