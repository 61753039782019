import React from "react";

import Select from "react-select";

import {
  onChangeFormInput,
  onChangeFilters,
} from "../../../utils/EventFunctions";

const SelectInput = ({
  feature,
  index,
  page,
  state,
  setState,
  objectKey,
  placeholder,
  isError,
  disableCondition,
  validateFunction,
  arrayOptions,
  multipleAttribute,
  clientProductList,
  nameClass,
}) => {
  let formatedOptions;

  if (!clientProductList) {
    formatedOptions = arrayOptions.map((option) => {
      return {
        value: option["_id"],
        label: option["name"],
      };
    });
  } else {
    formatedOptions = arrayOptions;
  }

  return (
    <Select
      className={nameClass}
      value={
        !clientProductList && state.values[objectKey]
          ? formatedOptions.find(
              (elmt) => elmt.value === state.values[objectKey]
            )
          : clientProductList && state[objectKey] && state[objectKey]["values"]
          ? state[objectKey]["values"]
          : ""
      }
      isMulti={multipleAttribute ? true : false}
      onChange={(e) => {
        if (!clientProductList) {
          onChangeFormInput(
            objectKey,
            multipleAttribute ? e : e.value,
            state,
            setState,
            validateFunction
          );
          if (objectKey === "category") {
            let copyState = { ...state };
            copyState.values["sub_category"] = "";
            setState(copyState);
          }
        } else {
          let filterObject = { values: e, type: feature.type };
          onChangeFilters(filterObject, objectKey, state, setState);
        }
      }}
      isDisabled={disableCondition ? disableCondition : false}
      options={formatedOptions}
    />
  );
};

export default SelectInput;
