import React from "react";

import { AnimatePresence, motion } from "framer-motion";

import IconComponent from "../IconComponent/IconComponent";

import { requestHandle } from "../../utils/RequestFunctions";

const ModalDelete = ({
  setIsLoading,
  setErrorMessage,
  modale,
  setModale,
  token,
}) => {
  const variants = {
    active: { opacity: 1, scale: 1 },
    hidden: { opacity: 0, scale: 0 },
  };

  const deleteDocument = async () => {
    const copyDataList = [...modale["list"]];
    copyDataList.splice(modale.index, 1);
    modale.setDataList(copyDataList);
    modale.setDisplayList(copyDataList);

    const response = await requestHandle(
      setIsLoading,
      setErrorMessage,
      token,
      "DELETE",
      modale.collection,
      { type: "document", id: modale.item["_id"] }
    );
  };
  return (
    // <AnimatePresence initial={false}>
    //   <motion.div
    //     id="modal-delete"
    //     animate={modale ? "active" : "hidden"}
    //     variants={variants}>
    //     <div className="close-container">
    //       <IconComponent
    //         icon="close-filled"
    //         size={20}
    //         color="#fff"
    //         style={{ cursor: "pointer" }}
    //         onClick={() => setModale(false)}
    //       />
    //     </div>
    //     <h3>Êtes-vous sur de voulair supprimer ce document ?</h3>
    //     <div className="modal-delete-button-container">
    //       <button
    //         onClick={() => {
    //           deleteDocument();
    //           setModale(false);
    //         }}>
    //         Supprimer
    //       </button>
    //       <button
    //         onClick={() => {
    //           setModale(false);
    //         }}>
    //         Annuler
    //       </button>
    //     </div>
    //   </motion.div>
    // </AnimatePresence>

    <div className="opacity-100 z-50 fixed top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 flex flex-col max-w-md gap-2 p-6 rounded-md shadow-md bg-gray-50 text-gray-800">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
        className="w-6 h-6 fill-current shrink-0 text-red-800 mx-auto">
        <path d="M451.671,348.569,408,267.945V184c0-83.813-68.187-152-152-152S104,100.187,104,184v83.945L60.329,348.568A24,24,0,0,0,81.432,384h86.944c-.241,2.636-.376,5.3-.376,8a88,88,0,0,0,176,0c0-2.7-.135-5.364-.376-8h86.944a24,24,0,0,0,21.1-35.431ZM312,392a56,56,0,1,1-111.418-8H311.418A55.85,55.85,0,0,1,312,392ZM94.863,352,136,276.055V184a120,120,0,0,1,240,0v92.055L417.137,352Z"></path>
        <rect width="32" height="136" x="240" y="112"></rect>
        <rect width="32" height="32" x="240" y="280"></rect>
      </svg>
      <h2 className="flex items-center gap-2 text-xl font-semibold leadi tracki text-center">
        Êtes-vous sur de voulair supprimer ce document ?
      </h2>

      <div className="flex flex-col justify-center gap-3 mt-6 sm:flex-row">
        <button
          className="px-6 py-2 rounded-sm shadow-sm bg-gray-800 text-gray-50"
          onClick={() => {
            setModale(false);
          }}>
          {" "}
          Annuler
        </button>
        <button
          className="px-6 py-2 rounded-sm shadow-sm bg-red-800 text-gray-50"
          onClick={() => {
            deleteDocument();
            setModale(false);
          }}>
          {" "}
          Supprimer
        </button>
      </div>
    </div>
  );
};

export default ModalDelete;
