import React from "react";

import { Link, useNavigate } from "react-router-dom";

const BackOfficeHome = ({ setUser }) => {
  const navigate = useNavigate();

  const backofficeCollectionsArray = [
    {
      collectionLabel: "Gestion produits",
      crudLinks: [
        {
          title: "Ajouter un produit",
          route: "/backoffice/product/create",
        },
        {
          title: "Liste des produits",
          route: "/backoffice/product/liste",
        },
      ],
    },
    {
      collectionLabel: "Service après-vente",
      crudLinks: [
        {
          title: "Liste des demandes de dépannage",
          route: "/backoffice/sav/liste",
        },
      ],
    },
    {
      collectionLabel: "Demandes informations produit",
      crudLinks: [
        {
          title: "Liste des demandes d'information produit",
          route: "/backoffice/informations_request/liste",
        },
      ],
    },
    {
      collectionLabel: "Page d'accueil",
      crudLinks: [
        {
          title: "Modification texte page d'accueil",
          route: "/backoffice/presentation/liste",
        },
        {
          title: "Ajout de photo de carrousel page d'acceuil",
          route: "/backoffice/carrousel/create",
        },
        {
          title: "Liste photos de carrousel page d'acceuil",
          route: "/backoffice/carrousel/liste",
        },
      ],
    },
    {
      collectionLabel: "Page services",
      crudLinks: [
        {
          title: "Création d'une section page service",
          route: "/backoffice/service/create",
        },
        {
          title: "Liste des sections page service",
          route: "/backoffice/service/liste",
        },
      ],
    },

    {
      collectionLabel: "Gestion marques",
      crudLinks: [
        {
          title: "Ajouter une marque",
          route: "/backoffice/brand/create",
        },
        {
          title: "Liste des marques",
          route: "/backoffice/brand/liste",
        },
      ],
    },
  ];
  return (
    <main
      id="backoffice-home-container"
      className="bg-gray-800 text-gray-100  min-h-[100vh] flex flex-col justify-center">
      <button
        className="p-4 bg-red-800 rounded w-[25%] absolute right-2 top-2"
        onClick={() => {
          setUser(null);
          navigate("/");
        }}>
        Déconnexion
      </button>
      <div className="bg-gray-100 text-gray-800 p-5 h-[80vh]">
        <h1 className="font-bold text-xl py-5 text-center mb-5 border-b-2 border-red-900">
          Accueil administration
        </h1>
        <div className="flex flex-wrap justify-between h-full leading-loose ">
          {backofficeCollectionsArray.map((collection, index) => {
            return (
              <ul key={index} className="w-[33%] px-4">
                <li>
                  <h2 className="text-lg font-bold mb-2 text-center">
                    {collection["collectionLabel"]}
                  </h2>
                  <ul className="ml-4 text-lg">
                    {collection["crudLinks"].map((link, index) => {
                      return (
                        <Link key={index} to={link["route"]}>
                          <li className="p-2 shadow-sm shadow-black font-semibold hover:cursor-pointer hover:bg-red-900 hover:text-gray-100">
                            {link["title"]}
                          </li>
                        </Link>
                      );
                    })}
                  </ul>
                </li>
              </ul>
            );
          })}
        </div>
      </div>
    </main>
  );
};

export default BackOfficeHome;
