import React, { useState, useEffect } from "react";

// IMPORT LIB
import { sanitize } from "dompurify";

// IMPORT CONTEXT
import { useLoadingErrorContext } from "../../Shared/Context/LoadingErrorContext";

// IMPORT UTILS
import { requestHandle } from "../../Shared/utils/RequestFunctions";

// IMPORT COMPONENT
import AlertMessage from "../../Shared/components/AlertMessage/AlertMessage";
import TableComponent from "../../Shared/components/TableComponent/TableComponent";
import ModalDelete from "../../Shared/components/Modals/ModalDelete";

const InformationRequestList = ({ userSession }) => {
  const { isLoading, setIsLoading, errorMessage, setErrorMessage } =
    useLoadingErrorContext();

  const [modaleDelete, setModaleDelete] = useState(false);
  const [informationRequestList, setInformationRequestList] = useState([]);
  const [listToDisplay, setListToDisplay] = useState([]);

  const fetchInformationRequest = async () => {
    const response = await requestHandle(
      setIsLoading,
      setErrorMessage,
      userSession.token,
      "GET",
      "informations_request"
    );
    if (response && response.statut === "OK") {
      setInformationRequestList(response.message);
      setListToDisplay(response.message);
    }
  };

  useEffect(() => {
    fetchInformationRequest();
  }, []);

  return (
    <main
      id="informations-request-liste-container"
      className="backoffice-container">
      {errorMessage ? (
        <AlertMessage
          errorMessage={errorMessage}
          setErrorMessage={setErrorMessage}
        />
      ) : null}

      {modaleDelete ? (
        <ModalDelete
          setIsLoading={setIsLoading}
          setErrorMessage={setErrorMessage}
          modale={modaleDelete}
          setModale={setModaleDelete}
          token={userSession.token}
        />
      ) : null}

      <TableComponent
        token={userSession.token}
        setModaleDelete={setModaleDelete}
        collection={"informations_request"}
        listTitle={"Les demandes d'informations"}
        setListArray={setInformationRequestList}
        listArray={informationRequestList.sort(
          (a, b) => new Date(b.created_at) - new Date(a.created_at)
        )}
        listToDisplay={listToDisplay}
        setListToDisplay={setListToDisplay}
        fieldsArray={[
          { colTitle: "Nom", type: "text", objectKey: "last_name" },
          { colTitle: "Email", type: "text", objectKey: "email" },
          {
            colTitle: "Numéro de téléphone",
            type: "text",
            objectKey: "phone_number",
          },

          { colTitle: "Message", type: "text", objectKey: "message" },
          {
            colTitle: "Référence produit",
            type: "text",
            objectKey: "product",
            nestedField: "brand",
          },
          {
            colTitle: "Produit",
            type: "text",
            objectKey: "product",
            nestedField: "name",
          },

          {
            colTitle: "Date d'envoi",
            type: "date",
            objectKey: "sending_date",
          },
          { colTitle: "Memo", type: "text", objectKey: "personal_notes" },
          { colTitle: "Statut", type: "trileen", objectKey: "statut" },
        ]}
      />
    </main>
  );
};

export default InformationRequestList;
