import React, { useState, useEffect } from "react";

// IMPORT LIB
import { useLocation } from "react-router-dom";

// IMPORT UTILS
import {
  sendDataToServer,
  requestHandle,
} from "../../Shared/utils/RequestFunctions";

import { onChangeStateObject } from "../../Shared/utils/EventFunctions";

// IMPORT CONTEXT
import { useLoadingErrorContext } from "../../Shared/Context/LoadingErrorContext";

// IMPORT COMPONENT
import AlertMessage from "../../Shared/components/AlertMessage/AlertMessage";
import ModalSuccess from "../../Shared/components/Modals/ModalSuccess";
import BackofficeGoBack from "../../Shared/components/BackofficeGoBack/BackofficeGoBack";
import LoadingWrapper from "../../Shared/components/LoadingWrapper/LoadingWrapper";

const CarCreateUpdate = ({ userSession }) => {
  const { setIsLoading, isLoading, setErrorMessage, errorMessage } =
    useLoadingErrorContext();

  const pageType = useLocation().pathname.split("/").pop();

  const [modale, setModale] = useState(false);
  const [carrouselItem, setCarrouselItem] = useState({ target: "Home" });

  const sendCarouselItemToServer = (e) => {
    e.preventDefault();
    sendDataToServer(
      setIsLoading,
      setErrorMessage,
      userSession.token,
      pageType === "create" ? "POST" : "PUT",
      "carrousel",
      carrouselItem,
      setModale,
      pageType === "create"
        ? "Une nouvelle photo de carrousel à été ajoutée"
        : "Cette photo de carrousel à été mise à jour",
      "photos de carrousel"
    );
  };
  const fetchCarrouselSingle = async () => {
    if (pageType !== "create") {
      const response = await requestHandle(
        setIsLoading,
        setErrorMessage,
        userSession.token,
        "POST",
        "carrousel",
        { single: pageType }
      );

      if (response && response.statut === "OK") {
        setCarrouselItem(response.message);
      }
    }
  };

  useEffect(() => {
    if (pageType !== "create") {
      fetchCarrouselSingle();
    }
  }, []);

  if (isLoading) return <LoadingWrapper isLoading={isLoading} />;

  return (
    <main
      id="carrousel-create-update-container"
      className="backoffice-container bg-gray-800">
      {errorMessage ? (
        <AlertMessage
          errorMessage={errorMessage}
          setErrorMessage={setErrorMessage}
        />
      ) : null}

      {modale && <ModalSuccess modale={modale} setModale={setModale} />}

      {/* <BackofficeGoBack
        listeName={"images de carrousel"}
        collection={"carrousel"}
      /> */}

      <form
        className="bg-gray-100 p-5 text-center w-full min-h-[100vh]"
        onSubmit={(e) => sendCarouselItemToServer(e)}>
        <h1 className="mb-5 font-bold text-lg">
          {pageType === "create"
            ? "Ajout d'une photo de carrousel"
            : pageType === "update"
            ? "Mis à jour d'une photo de carrousel"
            : null}
        </h1>

        {carrouselItem && carrouselItem.picture && (
          <>
            <img
              style={{ width: "25vw", margin: "0 auto" }}
              src={
                typeof carrouselItem.picture === "string"
                  ? carrouselItem.picture
                  : URL.createObjectURL(carrouselItem.picture)
              }
              alt=""
            />
            <button
              onClick={(e) => {
                setCarrouselItem({ target: "Home" });
              }}>
              Supprimer
            </button>
          </>
        )}

        <fieldset className="w-full space-y-1 text-gray-800">
          <div className="flex justify-center">
            <input
              onChange={(e) =>
                onChangeStateObject(
                  "picture",
                  e.target.files[0],
                  carrouselItem,
                  setCarrouselItem
                )
              }
              type="file"
              name="files"
              id="files"
              className="px-8 py-12 border-2 border-dashed rounded-md border-gray-300 text-gray-600 bg-gray-100"
            />
          </div>
        </fieldset>

        <button
          type="submit"
          className="px-8 py-3 font-semibold rounded bg-gray-800 text-gray-100">
          Envoyer
        </button>
      </form>
    </main>
  );
};

export default CarCreateUpdate;
