import React, { useEffect, useState } from "react";

import { sanitize } from "dompurify";
import { useNavigate } from "react-router-dom";

import TableDeleteUpdateButton from "../TableDeleteUpdateButton/TableDeleteUpdateButton";

import { useLoadingErrorContext } from "../../Context/LoadingErrorContext";

import { updateSingleField } from "../../utils/RequestFunctions";

import ListeFilters from "../../../Backoffice/Products/components/ListeFilters";
import Select from "react-select";

const TableComponent = ({
  listArray,
  setListArray,
  fieldsArray,
  listTitle,
  collection,
  setModaleDelete,
  token,
  addButton,
  filterList,
  listToDisplay,
  setListToDisplay,
}) => {
  const { setIsLoading, setErrorMessage } = useLoadingErrorContext();
  const navigate = useNavigate();

  return (
    <div className={`p-4 rounded-md`}>
      <h1 className="mb-3 text-2xl font-semibold text-center">{listTitle}</h1>
      {addButton ? (
        <div className="flex justify-center py-4">
          <button
            onClick={(e) => {
              e.preventDefault();
              navigate(addButton["link"]);
            }}
            type="button"
            className="px-8 py-3 font-semibold rounded bg-gray-800 text-gray-100">
            Ajouter {addButton["text"]}
          </button>
        </div>
      ) : null}

      {filterList && (
        <ListeFilters
          radioKeysArray={filterList}
          listArray={listArray}
          listeToDisplay={listToDisplay}
          setListeToDisplay={setListToDisplay}
        />
      )}

      <div className="overflow-x-auto">
        <table className="min-w-full text-sm">
          <thead className="rounded-t-lg bg-gray-300">
            <tr className="text-right">
              {fieldsArray &&
                fieldsArray.length > 0 &&
                fieldsArray.map((headField, index) => {
                  return (
                    <th
                      key={index}
                      title={headField["colTitle"]}
                      className="p-3 text-left">
                      {headField["colTitle"]}
                    </th>
                  );
                })}

              {!fieldsArray.find((elmt) => elmt.type === "trileen") && (
                <th>État</th>
              )}
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {listToDisplay &&
              listToDisplay.length > 0 &&
              listToDisplay.map((listItem, index) => {
                return (
                  <tr
                    key={index}
                    className="text-right border-b border-opacity-20 border-gray-300 bg-gray-100 even:bg-gray-500 even:text-gray-100 odd:bg-gray-100 odd:text-gray-800">
                    {fieldsArray &&
                      fieldsArray.length > 0 &&
                      fieldsArray.map((bodyField, index) => {
                        return (
                          <td
                            key={index}
                            className="px-3 py-2 font-semibold text-justify w-[80vw] relative">
                            {bodyField.type === "text" && (
                              <span>
                                {listItem[bodyField["objectKey"]] &&
                                !bodyField["nestedField"]
                                  ? listItem[bodyField["objectKey"]]
                                  : listItem[bodyField["objectKey"]] &&
                                    bodyField["nestedField"]
                                  ? listItem[bodyField["objectKey"]][
                                      bodyField["nestedField"]
                                    ]
                                  : "introuvable"}
                              </span>
                            )}
                            {bodyField.type === "innerHtml" && (
                              <span
                                className="px-3 py-2 text-left"
                                dangerouslySetInnerHTML={{
                                  __html: sanitize(
                                    listItem[bodyField["objectKey"]]
                                  ),
                                }}></span>
                            )}
                            {bodyField.type === "pdf" && (
                              <span>
                                {listItem[bodyField["objectKey"]] && (
                                  <a
                                    href={listItem[bodyField["objectKey"]]}
                                    target="_blank">
                                    {bodyField["colTitle"]}
                                  </a>
                                )}
                              </span>
                            )}

                            {bodyField.type === "date" && (
                              <span>
                                {new Date(
                                  listItem[bodyField["objectKey"]]
                                ).toLocaleDateString()}
                              </span>
                            )}

                            {bodyField.type === "features" && (
                              <span>
                                {listItem[bodyField["objectKey"]] &&
                                  Array.isArray(
                                    listItem[bodyField["objectKey"]]
                                  ) &&
                                  listItem[bodyField["objectKey"]].length >
                                    0 && (
                                    <ul>
                                      {listItem[bodyField["objectKey"]].map(
                                        (feature) => {
                                          if (feature.name && feature.value) {
                                            return (
                                              <li key={feature.id}>
                                                {feature.name} : {feature.value}{" "}
                                                {feature.measurement
                                                  ? feature.measurement
                                                  : ""}
                                              </li>
                                            );
                                          }
                                        }
                                      )}
                                    </ul>
                                  )}
                              </span>
                            )}

                            {bodyField.type === "picture_gallery" && (
                              <span>
                                {listItem[bodyField["objectKey"]].split(",") &&
                                  listItem[bodyField["objectKey"]].split(",")
                                    .length > 0 &&
                                  listItem[bodyField["objectKey"]]
                                    .split(",")
                                    .map((pic, index) => {
                                      return (
                                        <img
                                          className="h-20 w-20"
                                          key={index}
                                          src={pic}
                                          alt=""
                                        />
                                      );
                                    })}
                              </span>
                            )}
                            {bodyField.type === "picture" && (
                              <span>
                                {listItem[bodyField["objectKey"]] && (
                                  <img
                                    className="h-20 w-20"
                                    src={listItem[bodyField["objectKey"]]}
                                    alt=""
                                  />
                                )}
                              </span>
                            )}
                            {bodyField.type === "boolean" && (
                              <div
                                title={`${
                                  listItem[bodyField["objectKey"]]
                                    ? "désactiver"
                                    : "activer"
                                }`}
                                onClick={() => {
                                  updateSingleField(
                                    setIsLoading,
                                    setErrorMessage,
                                    bodyField["objectKey"],
                                    collection,
                                    token,
                                    index,
                                    listArray,
                                    setListArray
                                  );
                                }}
                                className={`rounded-3xl ${
                                  listItem[bodyField["objectKey"]]
                                    ? "bg-green-700"
                                    : "bg-red-600"
                                } h-5 w-5 cursor-pointer`}></div>
                            )}
                            {bodyField.type === "trileen" && (
                              // <div
                              //   title={`${
                              //     listItem &&
                              //     listItem[bodyField["objectKey"]] &&
                              //     listItem[bodyField["objectKey"]] ===
                              //       "notChecked"
                              //       ? "Nouveau"
                              //       : listItem &&
                              //         listItem[bodyField["objectKey"]] &&
                              //         listItem[bodyField["objectKey"]] ===
                              //           "checked"
                              //       ? "En cours"
                              //       : "Terminé"
                              //   }`}
                              //   className={`w-full`}>
                              <Select
                                className="text-gray-800 w-full z-20 absolute bottom-0 left-0"
                                value={
                                  listItem[bodyField["objectKey"]] === "checked"
                                    ? {
                                        label: "En cours",
                                        value: listItem[bodyField["objectKey"]],
                                      }
                                    : listItem[bodyField["objectKey"]] ===
                                      "notChecked"
                                    ? {
                                        label: "Nouveau",
                                        value: listItem[bodyField["objectKey"]],
                                      }
                                    : listItem[bodyField["objectKey"]] ===
                                      "answered"
                                    ? {
                                        label: "Terminé",
                                        value: listItem[bodyField["objectKey"]],
                                      }
                                    : ""
                                }
                                options={[
                                  { label: "Nouveau", value: "notChecked" },
                                  { label: "En cours", value: "checked" },
                                  { label: "Terminé", value: "answered" },
                                ]}
                                onChange={(e) => {
                                  updateSingleField(
                                    setIsLoading,
                                    setErrorMessage,
                                    "statut",
                                    collection,
                                    token,
                                    index,
                                    listArray,
                                    setListArray,
                                    e.value
                                  );
                                }}
                              />
                              // </div>
                            )}
                          </td>
                        );
                      })}

                    <TableDeleteUpdateButton
                      setIsLoading={setIsLoading}
                      setErrorMessage={setErrorMessage}
                      collection={collection}
                      token={token}
                      index={index}
                      dataList={listArray}
                      setDataList={setListArray}
                      setDisplayList={setListToDisplay}
                      setModale={setModaleDelete}
                      fieldsArray={fieldsArray}
                    />
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TableComponent;
