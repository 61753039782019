import React, { useState, useEffect } from "react";

// IMPORT PICTURE
import LogoKC from "../../../assets/img/kcMenagerLogo.jpg";

// IMPORT LIB
import { Link, useNavigate, useLocation } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";

// IMPORT CONTEXT
import { useLoadingErrorContext } from "../../Context/LoadingErrorContext";

// IMPORT COMPONENT
import IconComponent from "../IconComponent/IconComponent";

// IMPORT UTILS

const Header = ({
  navigationData,
  searchReference,
  setSearchReference,
  searchResults,
  searchByReference,
}) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [categoryIndexToDevelop, setCategoryIndexToDevelop] = useState();
  const [isVisible, setIsVisible] = useState(false);
  const [searchHover, setSearchHover] = useState(false);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  return (
    <header className="hidden md:bg-white md:text-gray-80 md:flex md:relative md:z-50">
      <div className="flex flex-auto md:flex-col h-100 ">
        <div className="md:flex flex-auto items-center gap-12 px-4">
          <Link
            rel="noopener noreferrer"
            to={"/"}
            aria-label="Back to homepage"
            className="flex items-center p-2">
            <img
              src={LogoKC}
              className="w-[8vw] rounded-xl object-contain"
              alt=""
            />
          </Link>
          <AnimatePresence>
            <motion.form
              style={{ width: 0 }}
              animate={isVisible ? "open" : "close"}
              variants={{
                close: {
                  width: 0,
                },
                open: {
                  width: 100,
                },
              }}
              transition={{ duration: 1 }}
              onSubmit={(e) => {
                e.preventDefault();
                searchByReference();
              }}
              className="relative md:flex md:flex-1 ml-5 space-y-1 rounded-md border-2 border-gray-700 text-gray-800">
              <label htmlFor="Search" className="hidden">
                Search
              </label>
              <div className="relative w-full">
                <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                  <button
                    type="button"
                    title="search"
                    className="p-1 focus:outline-none focus:ring"
                    onClick={(e) => {
                      e.preventDefault();
                      searchByReference();
                    }}>
                    <svg
                      fill="currentColor"
                      viewBox="0 0 512 512"
                      className="w-4 h-4 text-gray-800">
                      <path d="M479.6,399.716l-81.084-81.084-62.368-25.767A175.014,175.014,0,0,0,368,192c0-97.047-78.953-176-176-176S16,94.953,16,192,94.953,368,192,368a175.034,175.034,0,0,0,101.619-32.377l25.7,62.2L400.4,478.911a56,56,0,1,0,79.2-79.195ZM48,192c0-79.4,64.6-144,144-144s144,64.6,144,144S271.4,336,192,336,48,271.4,48,192ZM456.971,456.284a24.028,24.028,0,0,1-33.942,0l-76.572-76.572-23.894-57.835L380.4,345.771l76.573,76.572A24.028,24.028,0,0,1,456.971,456.284Z"></path>
                    </svg>
                  </button>
                </span>
                <input
                  type="search"
                  value={searchReference ? searchReference : ""}
                  name="productSearch"
                  placeholder="Référence, EAN"
                  onClick={() => setCategoryIndexToDevelop()}
                  onChange={(e) => {
                    setSearchReference(e.target.value);
                  }}
                  className="w-full py-2 pl-10 text-sm rounded-md focus:outline-none bg-white text-gray-800 focus:bg-gray-50 focus:border-violet-600"
                />
              </div>
              {searchResults && searchResults.length > 0 && (
                <div className="w-full absolute bg-white shadow-lg top-[100%] left-0 text-center p-3 max-h-[45vh] overflow-y-scroll">
                  <div className="flex flex-wrap ">
                    {searchResults.map((searchedProduct) => {
                      return (
                        <div
                          onMouseEnter={() => setSearchHover(true)}
                          onMouseLeave={() => setSearchHover(false)}
                          className="flex items-center rounded shadow-md overflow- relative bg-white text-gray-700 hover:bg-gray-300 w-full">
                          <div className="self-stretch flex items-center px-3 flex-shrink-0 bg-white relative">
                            {searchedProduct["picture_presentation"] && (
                              <img
                                className="h-20 w-20 object-contain"
                                src={searchedProduct["picture_presentation"]}
                                alt=""
                              />
                            )}
                            {searchedProduct["brand"] &&
                              searchedProduct["brand"]["logo"] && (
                                <motion.div
                                  animate={
                                    searchHover
                                      ? { right: "0" }
                                      : { right: "-1.25rem" }
                                  }
                                  transition={{ duration: 0.3 }}
                                  className="absolute bottom-2  h-10 w-10">
                                  <img
                                    src={searchedProduct["brand"]["logo"]}
                                    alt="logo de marque"
                                  />
                                </motion.div>
                              )}
                          </div>
                          <Link
                            onClick={() => {
                              setCategoryIndexToDevelop();
                              setSearchReference("");
                            }}
                            to={
                              searchedProduct["sub_category"] &&
                              searchedProduct["sub_category"]["slug"]
                                ? `/product/${searchedProduct["category"]["slug"]}/${searchedProduct["sub_category"]["slug"]}/${searchedProduct["_id"]}`
                                : `/product/${searchedProduct["category"]["slug"]}/${searchedProduct["_id"]}`
                            }
                            className="text-xl font-bold p-4 flex-1">
                            {searchedProduct["name"]}
                          </Link>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </motion.form>
          </AnimatePresence>

          <ul className=" md:flex md:h-[50%] ">
            <li className="flex">
              {pathname === "/qui_sommes_nous" ? (
                <h1 className="m-0">
                  <Link
                    onClick={() => {
                      setCategoryIndexToDevelop();
                      setSearchReference();
                    }}
                    rel="noopener noreferrer"
                    to={"/qui_sommes_nous"}
                    className={`flex items-center px-4 h-full rounded-sm hover:border-b-4 text-gray-800 hover:border-red-800 ${
                      pathname === "/qui_sommes_nous"
                        ? "border-b-4 border-red-800"
                        : ""
                    }`}>
                    Qui Sommes-Nous
                  </Link>
                </h1>
              ) : (
                <Link
                  onClick={() => {
                    setCategoryIndexToDevelop();
                    setSearchReference();
                  }}
                  rel="noopener noreferrer"
                  to={"/qui_sommes_nous"}
                  className={`flex items-center px-4 h-full rounded-sm hover:border-b-4 text-gray-800 hover:border-red-800 ${
                    pathname === "/qui_sommes_nous"
                      ? "border-b-4 border-red-800"
                      : ""
                  }`}>
                  Qui Sommes-Nous
                </Link>
              )}
            </li>
            <li className="flex">
              {pathname === "/services" ? (
                <h1 className="m-0">
                  <Link
                    onClick={() => {
                      setCategoryIndexToDevelop();
                      setSearchReference();
                    }}
                    rel="noopener noreferrer"
                    to={"/services"}
                    className={`flex items-center px-4 h-full rounded-sm hover:border-b-4 text-gray-800 hover:border-red-800 ${
                      pathname === "/services"
                        ? "border-b-4 border-red-800"
                        : ""
                    }`}>
                    Services
                  </Link>
                </h1>
              ) : (
                <Link
                  onClick={() => {
                    setCategoryIndexToDevelop();
                    setSearchReference();
                  }}
                  rel="noopener noreferrer"
                  to={"/services"}
                  className={`flex items-center px-4 h-full rounded-sm hover:border-b-4 text-gray-800 hover:border-red-800 ${
                    pathname === "/services" ? "border-b-4 border-red-800" : ""
                  }`}>
                  Services
                </Link>
              )}
            </li>
            <li className="flex">
              {pathname === "/contact" ? (
                <h1 className="m-0">
                  <Link
                    onClick={() => {
                      setCategoryIndexToDevelop();
                      setSearchReference();
                    }}
                    rel="noopener noreferrer"
                    to={"/contact"}
                    className={`flex items-center px-4 h-full rounded-sm hover:border-b-4 text-gray-800 hover:border-red-800 ${
                      pathname === "/contact" ? "border-b-4 border-red-800" : ""
                    }`}>
                    Nous Contacter
                  </Link>
                </h1>
              ) : (
                <Link
                  onClick={() => {
                    setCategoryIndexToDevelop();
                    setSearchReference();
                  }}
                  rel="noopener noreferrer"
                  to={"/contact"}
                  className={`flex items-center px-4 h-full rounded-sm hover:border-b-4 text-gray-800 hover:border-red-800 ${
                    pathname === "/contact" ? "border-b-4 border-red-800" : ""
                  }`}>
                  Nous Contacter
                </Link>
              )}
            </li>
          </ul>

          {/* {userSession && userSession.token ? (
            <div className="flex flex-1 flex-col justify-between md:flex-row md:justify-end items-center">
              <button
                type="button"
                className="px-8 py-2 mx-2 font-semibold rounded bg-sky-800 text-gray-100"
                onClick={() => navigate("/backoffice/home")}>
                Administration
              </button>

              <button
                type="button"
                className="px-8 py-2 mx-2 font-semibold rounded bg-red-800 text-gray-100"
                onClick={() => setUser(null)}>
                Déconnexion
              </button>
            </div>
          ) : (
            <div className="flex justify-end flex-1">
              <button
                type="button"
                className="px-8 py-3 font-semibold rounded bg-emerald-500 text-gray-100"
                onClick={() => navigate("/admin/0982/auth")}>
                Login
              </button>
            </div>
          )} */}
        </div>

        {navigationData && navigationData.length > 0 && (
          <ul className=" md:flex flex-auto overflow-y- flex-nowrap justify-center bg-gray-800">
            {navigationData
              .sort((a, b) => {
                let AsubCategoryLength = a["sub_category"]?.length;
                let BsubCategoryLength = b["sub_category"]?.length;
                return AsubCategoryLength > BsubCategoryLength
                  ? -1
                  : AsubCategoryLength < BsubCategoryLength
                  ? 1
                  : 0;
              })
              .map((category, index) => {
                console.log(category["slug"]);
                return (
                  <li
                    key={category["_id"]}
                    className={`flex border-red-800 ${
                      (!categoryIndexToDevelop &&
                        pathname.includes(category["slug"]) &&
                        pathname.split("/").length < 3) ||
                      (categoryIndexToDevelop &&
                        categoryIndexToDevelop === index.toString()) ||
                      (!categoryIndexToDevelop &&
                        pathname.includes(`${category["slug"]}/all`))
                        ? "border-4 border-b-0 rounded-t-lg bg-gray-100 font-bold"
                        : "border-b-4 "
                    } `}>
                    {category["sub_category"] &&
                    category["sub_category"].length > 0 ? (
                      <div
                        className="flex"
                        onClick={() => {
                          if (categoryIndexToDevelop === index.toString()) {
                            setCategoryIndexToDevelop();
                          } else {
                            setCategoryIndexToDevelop(index.toString());
                          }
                        }}>
                        {pathname.includes(category["slug"]) ? (
                          <h1
                            className={`flex justify-around items-center flex-shrink-0 px-5 py-3 border-red-800  hover:cursor-pointer ${
                              (!categoryIndexToDevelop &&
                                pathname.includes(category["slug"]) &&
                                pathname.split("/").length < 3) ||
                              (categoryIndexToDevelop &&
                                categoryIndexToDevelop === index.toString())
                                ? "text-gray-800"
                                : "text-gray-100"
                            }`}>
                            <div className="hover:scale-150">
                              <IconComponent
                                icon={
                                  category["slug"] === "pieces-detachees"
                                    ? "power-plug"
                                    : category["slug"] === "accessoires"
                                    ? "wrench-alt"
                                    : category["slug"] ===
                                      "electromenager-encastrable"
                                    ? "oven-gen"
                                    : "null"
                                }
                                size={20}

                                // color="#1f2937"
                              />
                            </div>

                            <span className="ml-3">{category["name"]}</span>
                          </h1>
                        ) : (
                          <p
                            className={`flex items-center flex-shrink-0 px-5 py-3 border-red-800  hover:cursor-pointer ${
                              (!categoryIndexToDevelop &&
                                pathname.includes(category["slug"]) &&
                                pathname.split("/").length < 3) ||
                              (categoryIndexToDevelop &&
                                categoryIndexToDevelop === index.toString())
                                ? "text-gray-800"
                                : "text-gray-100"
                            }`}>
                            <div></div>
                            <IconComponent
                              icon={
                                category["slug"] === "pieces-detachees"
                                  ? "power-plug"
                                  : category["slug"] === "accessoires"
                                  ? "wrench-alt"
                                  : category["slug"] ===
                                    "electromenager-encastrable"
                                  ? "oven-gen"
                                  : "blender-phone"
                              }
                              size={20}
                              // color="#fff"
                            />
                            <span className="ml-3">{category["name"]}</span>
                          </p>
                        )}
                        <button
                          type="button"
                          title="Toggle dropdown"
                          className="p-3 hover:cursor-pointer">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            className={`w-5 h-5 ${
                              (!categoryIndexToDevelop &&
                                pathname.includes(category["slug"]) &&
                                pathname.split("/").length < 3) ||
                              (categoryIndexToDevelop &&
                                categoryIndexToDevelop === index.toString())
                                ? "text-gray-800"
                                : "text-gray-100"
                            }`}>
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M19 9l-7 7-7-7"></path>
                          </svg>
                        </button>
                        {categoryIndexToDevelop &&
                          categoryIndexToDevelop === index.toString() && (
                            <div className="w-full absolute bg-gray-100 top-[100%] left-0 text-center p-3 border-b-4 border-red-800">
                              <div className="relative flex justify-center items-center text-gray-800 mb-5 pb-5  border-b-2  border-red-700">
                                <Link
                                  className="ml-3  hover:text-red-700"
                                  to={`/${category["slug"]}`}>
                                  <h3>Découvrir la section</h3>
                                </Link>
                                <div
                                  onClick={() => {
                                    setCategoryIndexToDevelop();
                                  }}
                                  className="absolute top-0 right-20 text-gray-800 hover:text-red-800 hover:cursor-pointer">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 512 512"
                                    fill="currentColor"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className=" w-[1.4em]">
                                    <path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM175 175c-9.4 9.4-9.4 24.6 0 33.9l47 47-47 47c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l47-47 47 47c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-47-47 47-47c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-47 47-47-47c-9.4-9.4-24.6-9.4-33.9 0z" />
                                  </svg>
                                </div>
                              </div>
                              <div className="flex flex-wrap justify-start">
                                {category["sub_category"].map((subCategory) => {
                                  return (
                                    <p
                                      key={subCategory["_id"]}
                                      onClick={() =>
                                        navigate(
                                          `/${category["slug"]}/${subCategory["slug"]}`
                                        )
                                      }
                                      className="hover:cursor-pointer text-gray-800 text-center font-semibold w-[25%] p-2 hover:text-red-700">
                                      {subCategory["name"]}
                                    </p>
                                  );
                                })}
                              </div>
                            </div>
                          )}
                      </div>
                    ) : pathname.includes(category["slug"]) ? (
                      <h1
                        onClick={() => setCategoryIndexToDevelop()}
                        className={`flex items-center flex-shrink-0 px-5 py-3 border-red-800 ${
                          pathname.includes(category["slug"]) &&
                          !categoryIndexToDevelop
                            ? "bg-white text-gray-800"
                            : "text-gray-100"
                        }`}>
                        <IconComponent
                          icon={
                            category["slug"] === "pieces-detachees"
                              ? "power-plug"
                              : category["slug"] === "accessoires"
                              ? "wrench-alt"
                              : category["slug"] ===
                                "electromenager-encastrable"
                              ? "oven-gen"
                              : "blender-phone"
                          }
                          size={20}
                          // color="#1f2937"
                        />
                        <span className="ml-3">{category["name"]}</span>
                      </h1>
                    ) : (
                      <Link
                        onClick={() => setCategoryIndexToDevelop()}
                        rel="noopener noreferrer"
                        to={`/${category["slug"]}/all`}
                        className={`flex items-center flex-shrink-0 px-5 py-3 border-red-800 ${
                          pathname.includes(category["slug"]) &&
                          !categoryIndexToDevelop
                            ? "bg-white text-gray-800"
                            : "text-gray-100"
                        }`}>
                        {/* <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="w-4 h-4">
                      <path d="M2 3h6a4 4 0 0 1 4 4v14a3 3 0 0 0-3-3H2z"></path>
                      <path d="M22 3h-6a4 4 0 0 0-4 4v14a3 3 0 0 1 3-3h7z"></path>
                    </svg> */}
                        <IconComponent
                          icon={
                            category["slug"] === "pieces-detachees"
                              ? "power-plug"
                              : category["slug"] === "accessoires"
                              ? "wrench-alt"
                              : category["slug"] ===
                                "electromenager-encastrable"
                              ? "oven-gen"
                              : "blender-phone"
                          }
                          size={20}
                          // color="#fff"
                        />
                        <span className="ml-3">{category["name"]}</span>
                      </Link>
                    )}
                  </li>
                );
              })}
          </ul>
        )}
        <button title="Button" type="button" className="p-4 md:hidden">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            className="w-6 h-6 text-gray-100">
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M4 6h16M4 12h16M4 18h16"></path>
          </svg>
        </button>
      </div>
    </header>
  );
};

export default Header;
