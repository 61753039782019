import React, { useState, useEffect } from "react";

// IMPORT LIB
import axios from "axios";
import { useLocation } from "react-router-dom";

// IMPORT CONTEXT
import { useLoadingErrorContext } from "../../Shared/Context/LoadingErrorContext";

// iMPORT COMPONENT
import LoadingWrapper from "../../Shared/components/LoadingWrapper/LoadingWrapper";
import AlertMessage from "../../Shared/components/AlertMessage/AlertMessage";
import IceCatComponent from "./components/IceCatComponent";

// IMPORT UTILS
import { requestHandle } from "../../Shared/utils/RequestFunctions";
import ProductForm from "./components/ProductForm";
import ModalBrand from "./components/ModalBrand";
import ModalSuccess from "../../Shared/components/Modals/ModalSuccess";
import BackofficeGoBack from "../../Shared/components/BackofficeGoBack/BackofficeGoBack";

const ProductsCreateUpdate = ({ userSession }) => {
  const { isLoading, setIsLoading, errorMessage, setErrorMessage } =
    useLoadingErrorContext();

  const pageType = useLocation().pathname.split("/").pop();

  //   State Display
  const [modale, setModale] = useState(false);

  //   State Data
  const [category, setCategory] = useState([]);
  const [brandsDataToDisplay, setBrandsDataToDisplay] = useState([]);

  const [productToSend, setProductToSend] = useState({
    values: {},
    touched: {},
    errors: {},
  });

  const fetchCategoryBrandsData = async () => {
    const navigationCategoryResponse = await requestHandle(
      setIsLoading,
      setErrorMessage,
      userSession.token,
      "GET",
      "navigation"
    );

    if (
      navigationCategoryResponse &&
      navigationCategoryResponse.statut === "OK"
    ) {
      setCategory(navigationCategoryResponse.message);
    }

    const brandsResponse = await requestHandle(
      setIsLoading,
      setErrorMessage,
      userSession.token,
      "GET",
      "brands/client/read"
    );

    if (brandsResponse && brandsResponse.statut === "OK") {
      setBrandsDataToDisplay(brandsResponse.message);
    }
  };

  const validateProductForm = (value) => {
    const errorsValidation = {};
    let isError = false;

    if (value) {
      // if (!value.values.product_ean) {
      //   errorsValidation.product_ean = "Merci de renseigner de champ";
      //   isError = true;
      // } else {
      //   errorsValidation.product_ean = 0;
      // }
    } else {
      isError = true;
    }

    const copyFormObject = { ...productToSend };
    copyFormObject["errors"] = errorsValidation;
    setProductToSend(copyFormObject);
    return isError;
  };

  const fetchProductSingle = async () => {
    if (!isLoading) {
      setIsLoading(true);
    }
    if (pageType !== "create") {
      const response = await requestHandle(
        setIsLoading,
        setErrorMessage,
        userSession.token,
        "POST",
        "product",
        { single: pageType }
      );

      if (response && response.statut === "OK") {
        const valuesFromFetch = response.message;
        const { picture_gallery, features, brand } = response.message;
        valuesFromFetch["picture_gallery"] = picture_gallery.split(",");
        valuesFromFetch["features"] = JSON.parse(features);
        valuesFromFetch["brand_id"] = brand;

        setProductToSend((prevState) => {
          prevState.values = valuesFromFetch;
          return prevState;
        });
      }
    }
  };

  useEffect(() => {
    if (pageType !== "create") {
      fetchProductSingle();
    }
    fetchCategoryBrandsData();
  }, []);

  if (isLoading) return <LoadingWrapper />;

  return (
    <main className="backoffice-container bg-gray-800 min-h-[100vh]">
      {errorMessage ? (
        <AlertMessage
          errorMessage={errorMessage}
          setErrorMessage={setErrorMessage}
        />
      ) : null}

      {modale && modale.type === "brand" ? (
        <ModalBrand
          modale={modale}
          setModale={setModale}
          userSession={userSession}
          productData={productToSend}
          setProductData={setProductToSend}
          brandsDataToDisplay={brandsDataToDisplay}
          setBrandsDataToDisplay={setBrandsDataToDisplay}
        />
      ) : (
        modale &&
        modale.type !== "brand" && (
          <ModalSuccess modale={modale} setModale={setModale} />
        )
      )}

      {/* <BackofficeGoBack collection={"product"} listeName={"produits"} /> */}

      {category && category.length > 0 ? (
        <>
          <IceCatComponent
            pageType={pageType}
            productToSend={productToSend}
            setProductToSend={setProductToSend}
            category={category}
            validateFunction={validateProductForm}
            brandsDataToDisplay={brandsDataToDisplay}
            setBrandsDataToDisplay={setBrandsDataToDisplay}
            modale={modale}
            setModale={setModale}
            userSession={userSession}
          />
          {productToSend.values.notFound && (
            <h3>Aucun produit trouvé dans la base IceCat</h3>
          )}

          {productToSend.values.category && (
            <ProductForm
              productToSend={productToSend}
              setProductToSend={setProductToSend}
              brandsDataToDisplay={brandsDataToDisplay}
              setBrandDataToDisplay={setBrandsDataToDisplay}
              validateFunction={validateProductForm}
              setModale={setModale}
              userSession={userSession}
              pageType={pageType}
            />
          )}
        </>
      ) : (
        <h3 className="text-center bg-gray-50 text-lg">
          Une erreur s'est produite, merci de ré essayer
        </h3>
      )}
    </main>
  );
};

export default ProductsCreateUpdate;
