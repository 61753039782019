import React, { useState, useEffect } from "react";

import { Link, useLocation } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";

import { featureToKeepHandle } from "../../Shared/utils/ProductFeatureToKeepArrays";

import { requestHandle } from "../../Shared/utils/RequestFunctions";

import { useLoadingErrorContext } from "../../Shared/Context/LoadingErrorContext";

import AlertMessage from "../../Shared/components/AlertMessage/AlertMessage";
import ClientProductCard from "../../Shared/components/ClientProductCard/ClientProductCard";
import FiltersComponent from "./components/FiltersComponent";
import FiltersComponentMobile from "./components/FiltersComponentMobile";

import SEOHelmet from "../../Shared/components/SeoHelmet/SEOHelmet";
const ClientProductsList = ({ userSession }) => {
  const { pathname } = useLocation();
  const slugCategoryToFetch = useLocation().pathname.split("/").pop();

  const {
    isLoading,
    setIsLoading,
    errorMessage,
    setErrorMessage,
    innerWidthState,
  } = useLoadingErrorContext();

  const [displayFilters, setDisplayFilters] = useState(
    innerWidthState > 768 ? true : false
  );

  const [filters, setFilters] = useState({});
  const [featuresArray, setFeaturesArray] = useState([]);

  const [productsData, setProductsData] = useState({});
  const [arrayToDisplay, setArrayToDisplay] = useState([]);

  const fetchProductsFromSubCategorySlug = async () => {
    const response = await requestHandle(
      setIsLoading,
      setErrorMessage,
      "",
      "POST",
      "client/product",
      {
        type: slugCategoryToFetch !== "all" ? "subCategory" : "category",
        slug:
          slugCategoryToFetch === "all"
            ? pathname.split("/")[1]
            : slugCategoryToFetch,
      }
    );

    if (response && response.message) {
      setProductsData(response.message);

      const productsList = response.message["products"];

      productsList.map((product) => {
        const formatedFeatures = JSON.parse(product["features"]);
        let formatedObjectArray = [];
        formatedFeatures.map((feature) => {
          formatedObjectArray.push({ id: feature.id, value: feature.value });
        });
        product["formatedFeatures"] = formatedObjectArray;
      });

      setArrayToDisplay(productsList);

      setFeaturesArray(
        featureToKeepHandle(
          response.message["sub_category"]["id"],
          response.message["products"]
        )
      );
    }
  };

  useEffect(() => {
    fetchProductsFromSubCategorySlug();
  }, [pathname]);

  return (
    <main id="products-container" className="relative flex min-h-[45vh]">
      {errorMessage && (
        <AlertMessage
          errorMessage={errorMessage}
          setErrorMessage={setErrorMessage}
        />
      )}
      {productsData &&
        productsData["category"] &&
        productsData["category"]["name"] && (
          <SEOHelmet
            title={`
              ${
                productsData &&
                productsData["category"] &&
                productsData["category"]["name"]
              } ${
              productsData["sub_category"] &&
              `- ${productsData["sub_category"]["name"]}`
            }
              `}
          />
        )}

      <div className="flex min-height-[40vh] h-full w-full">
        {innerWidthState > 768 && !pathname.includes("all") && (
          <FiltersComponent
            displayFilters={displayFilters}
            setDisplayFilters={setDisplayFilters}
            featuresArray={featuresArray}
            setFeaturesArray={setFeaturesArray}
            filters={filters}
            setFilters={setFilters}
            arrayToDisplay={arrayToDisplay}
            setArrayToDisplay={setArrayToDisplay}
            productsData={productsData}
            innerWidthState={innerWidthState}
          />
        )}

        <AnimatePresence>
          <motion.div
            title="Afficher les filtres"
            animate={
              displayFilters || innerWidthState < 768
                ? { display: "none" }
                : { display: "flex" }
            }
            transition={{ duration: 0.3 }}
            className="h-[100vh] md:flex items-center hidden">
            <motion.div
              animate={displayFilters ? { opacity: 0 } : { opacity: 1 }}
              transition={{ duration: 0.3 }}
              className="cursor-pointer"
              onClick={() => setDisplayFilters(true)}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 320 512"
                className="h-10 w-10">
                <path d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z" />
              </svg>
            </motion.div>
          </motion.div>
        </AnimatePresence>
        <AnimatePresence>
          <motion.div className="px-5 w-[100%]">
            <h1 className="text-4xl text-center my-5 font-bold">
              {productsData && productsData["sub_category"]
                ? productsData["sub_category"]["name"]
                : productsData &&
                  productsData["category"] &&
                  productsData["category"]["name"]}
            </h1>
            {innerWidthState < 768 && !pathname.includes("all") && (
              <FiltersComponentMobile
                displayFilters={displayFilters}
                setDisplayFilters={setDisplayFilters}
                featuresArray={featuresArray}
                setFeaturesArray={setFeaturesArray}
                filters={filters}
                setFilters={setFilters}
                arrayToDisplay={arrayToDisplay}
                setArrayToDisplay={setArrayToDisplay}
                productsData={productsData}
                innerWidthState={innerWidthState}
              />
            )}

            <div className="flex flex-wrap justify-center md:justify-start">
              {arrayToDisplay &&
                arrayToDisplay.length > 0 &&
                arrayToDisplay.map((product, index) => {
                  return (
                    // <Link
                    //   key={`${product["_id"]}-${index}`}
                    //   to={`/${product["category"]["slug"]}/product/${product["_id"]}`}>
                    <ClientProductCard
                      key={index}
                      productData={product}
                      featuresArray={featuresArray}
                    />
                    // </Link>
                  );
                })}
            </div>
          </motion.div>
        </AnimatePresence>
      </div>
    </main>
  );
};

export default ClientProductsList;
