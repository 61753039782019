import React, { useState, useEffect } from "react";

// IMPORT LIB
import { sanitize } from "dompurify";
import { Link } from "react-router-dom";

// IMPORT CONTEXT
import { useLoadingErrorContext } from "../../Shared/Context/LoadingErrorContext";

// IMPORT UTILS
import { requestHandle } from "../../Shared/utils/RequestFunctions";

// IMPORT COMPONENT
import AlertMessage from "../../Shared/components/AlertMessage/AlertMessage";
import LoadingWrapper from "../../Shared/components/LoadingWrapper/LoadingWrapper";
import ClientProductCard from "../../Shared/components/ClientProductCard/ClientProductCard";
import Carousel from "../../Shared/components/Carousel/Carousel";
import SEOHelmet from "../../Shared/components/SeoHelmet/SEOHelmet";

const Home = ({ userSession }) => {
  const {
    isLoading,
    setIsLoading,
    errorMessage,
    setErrorMessage,
    innerWidthState,
  } = useLoadingErrorContext();
  const [homeData, setHomeData] = useState({});

  const settingsSlider = {
    mobileFirst: true,
    dots: true,
    arrows: true,
    autoplay: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          arrows: false,
        },
      },
    ],
    centerMode: true,
  };

  const fetchHomeData = async () => {
    if (!isLoading) {
      setIsLoading(true);
    }
    const homeData = await requestHandle(
      setIsLoading,
      setErrorMessage,
      null,
      "GET",
      "home_data"
    );

    if (homeData) {
      setHomeData(homeData.message);
    }
  };

  useEffect(() => {
    fetchHomeData();
  }, []);

  if (isLoading) return <LoadingWrapper isLoading={isLoading} />;

  return (
    <main id="home-container">
      {errorMessage ? (
        <AlertMessage
          errorMessage={errorMessage}
          setErrorMessage={setErrorMessage}
        />
      ) : null}

      <SEOHelmet title={"Accueil"} />

      {homeData && homeData.carrousel && homeData.carrousel.length > 0 && (
        <section id="home-carrousel" className="carrousel-section">
          <Carousel picturesArray={homeData.carrousel} />
        </section>
      )}

      {homeData && homeData.presentation && (
        <section
          id="home-presentation"
          className="leading-7 bg-gray-800 text-gray-50 p-20 text-center">
          <div
            className="mb-10 leading-8"
            dangerouslySetInnerHTML={{
              __html: sanitize(homeData.presentation.content),
            }}></div>
          <Link
            className="px-8 py-3 mt-5 font-semibold rounded bg-red-800 hover:bg-red-900 text-gray"
            to={"/qui_sommes_nous"}>
            Découvrez nous
          </Link>
        </section>
      )}

      {homeData && homeData.products && homeData.products.length > 0 && (
        <section className="my-20">
          <h3 className="font-bold text-2xl text-center my-3">
            Notre séléction de produits
          </h3>
          <div
            id="home-product-selection"
            className="flex justify-center items-center flex-wrap ">
            {homeData.products
              .sort((a, b) => new Date(b.update_at) - new Date(a.update_at))
              .map((product, index) => {
                // console.log(product);
                if (innerWidthState > 1150) {
                  if (index < 10) {
                    return (
                      <ClientProductCard key={index} productData={product} />
                    );
                  }
                } else if (innerWidthState < 1150 && innerWidthState > 790) {
                  if (index < 9) {
                    return (
                      <ClientProductCard key={index} productData={product} />
                    );
                  }
                } else if (innerWidthState < 790 && innerWidthState > 528) {
                  if (index < 8) {
                    return (
                      <ClientProductCard key={index} productData={product} />
                    );
                  }
                } else if (innerWidthState < 528) {
                  if (index < 6) {
                    return (
                      <ClientProductCard key={index} productData={product} />
                    );
                  }
                }
              })}
          </div>
        </section>
      )}

      <section
        id="home-services"
        className="px-5 p-5 flex flex-col  md:flex-row justify-around bg-red-800 text-white text-center my-10">
        <article className="service-container my-10 relative">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 640 512"
            className="w-14 h-14 mx-auto"
            fill="currentColor">
            <path d="M208 352c114.9 0 208-78.8 208-176S322.9 0 208 0S0 78.8 0 176c0 38.6 14.7 74.3 39.6 103.4c-3.5 9.4-8.7 17.7-14.2 24.7c-4.8 6.2-9.7 11-13.3 14.3c-1.8 1.6-3.3 2.9-4.3 3.7c-.5 .4-.9 .7-1.1 .8l-.2 .2 0 0 0 0C1 327.2-1.4 334.4 .8 340.9S9.1 352 16 352c21.8 0 43.8-5.6 62.1-12.5c9.2-3.5 17.8-7.4 25.3-11.4C134.1 343.3 169.8 352 208 352zM448 176c0 112.3-99.1 196.9-216.5 207C255.8 457.4 336.4 512 432 512c38.2 0 73.9-8.7 104.7-23.9c7.5 4 16 7.9 25.2 11.4c18.3 6.9 40.3 12.5 62.1 12.5c6.9 0 13.1-4.5 15.2-11.1c2.1-6.6-.2-13.8-5.8-17.9l0 0 0 0-.2-.2c-.2-.2-.6-.4-1.1-.8c-1-.8-2.5-2-4.3-3.7c-3.6-3.3-8.5-8.1-13.3-14.3c-5.5-7-10.7-15.4-14.2-24.7c24.9-29 39.6-64.7 39.6-103.4c0-92.8-84.9-168.9-192.6-175.5c.4 5.1 .6 10.3 .6 15.5z" />
          </svg>
          <h3 className="font-bold text-2xl my-3">Conseil</h3>
          <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit.</p>
        </article>

        <article className="service-container my-10 relative">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 640 512"
            className="w-14 h-14 mx-auto"
            fill="currentColor">
            <path d="M48 0C21.5 0 0 21.5 0 48V368c0 26.5 21.5 48 48 48H64c0 53 43 96 96 96s96-43 96-96H384c0 53 43 96 96 96s96-43 96-96h32c17.7 0 32-14.3 32-32s-14.3-32-32-32V288 256 237.3c0-17-6.7-33.3-18.7-45.3L512 114.7c-12-12-28.3-18.7-45.3-18.7H416V48c0-26.5-21.5-48-48-48H48zM416 160h50.7L544 237.3V256H416V160zM112 416a48 48 0 1 1 96 0 48 48 0 1 1 -96 0zm368-48a48 48 0 1 1 0 96 48 48 0 1 1 0-96z" />
          </svg>
          <h3 className="font-bold text-2xl my-3">Livraison</h3>
          <p className="">
            Lorem ipsum dolor sit amet consectetur, adipisicing elit.
          </p>
        </article>

        <article className="service-container my-10">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            className="w-14 h-14 mx-auto"
            fill="currentColor">
            <path d="M352 320c88.4 0 160-71.6 160-160c0-15.3-2.2-30.1-6.2-44.2c-3.1-10.8-16.4-13.2-24.3-5.3l-76.8 76.8c-3 3-7.1 4.7-11.3 4.7H336c-8.8 0-16-7.2-16-16V118.6c0-4.2 1.7-8.3 4.7-11.3l76.8-76.8c7.9-7.9 5.4-21.2-5.3-24.3C382.1 2.2 367.3 0 352 0C263.6 0 192 71.6 192 160c0 19.1 3.4 37.5 9.5 54.5L19.9 396.1C7.2 408.8 0 426.1 0 444.1C0 481.6 30.4 512 67.9 512c18 0 35.3-7.2 48-19.9L297.5 310.5c17 6.2 35.4 9.5 54.5 9.5zM80 408a24 24 0 1 1 0 48 24 24 0 1 1 0-48z" />
          </svg>
          <h3 className="font-bold text-2xl my-3">Dépannage</h3>
          <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit.</p>
        </article>
      </section>
    </main>
  );
};

export default Home;
