import React, { useEffect, useState } from "react";

// IMPORT LIB
import { useLocation, Link } from "react-router-dom";

// IMPORT UTILS
import {
  sendDataToServer,
  requestHandle,
} from "../../Shared/utils/RequestFunctions";

// IMPORT CONTEXT
import { useLoadingErrorContext } from "../../Shared/Context/LoadingErrorContext";

// IMPORT COMPONENT
import AlertMessage from "../../Shared/components/AlertMessage/AlertMessage";
import ModalSuccess from "../../Shared/components/Modals/ModalSuccess";
import BackofficeGoBack from "../../Shared/components/BackofficeGoBack/BackofficeGoBack";
import LoadingWrapper from "../../Shared/components/LoadingWrapper/LoadingWrapper";
import FormInput from "../../Shared/components/FormInput/FormInput";
import TailWindTest from "../../TailWindTest";

const ServiceSAVCreateUpdate = ({ userSession }) => {
  const { setIsLoading, isLoading, setErrorMessage, errorMessage } =
    useLoadingErrorContext();

  const pageType = useLocation().pathname.split("/").pop();

  const [serviceToSend, setServiceToSend] = useState({
    values: {},
    touched: {},
    errors: {},
  });

  const [modale, setModale] = useState(false);

  // const titleSectionOnChangeHandler = (e, arrayOfKey) => {
  //   const copyStateObject = { ...serviceToSend };

  //   arrayOfKey.forEach((key) => {
  //     if (key === "section") {
  //       copyStateObject[key] = e.target.value
  //         .toLowerCase()
  //         .split(" ")
  //         .join("-")
  //         .normalize("NFD")
  //         .replace(/[\u0300-\u036f]/g, "");
  //     } else {
  //       copyStateObject[key] = e.target.value;
  //     }
  //   });
  //   setServiceToSend(copyStateObject);
  // };

  const inputArray = [
    {
      type: "text",
      required: true,
      objectKey: "title",
      placeholder: "Titre du nouveau service",
      label: "Ajouter un titre",
    },
    {
      type: "ckEditor",
      objectKey: "content",
      required: true,
      label: "Contenu de la section",
    },
    {
      type: "file",
      objectKey: "picture",
      label: "Choisissez une photo à ajouter à la section SAV",
    },
  ];

  const validateServiceForm = (value) => {
    const errorsValidation = {};
    let isError = false;
    if (value) {
      if (!value.values.title && value.touched.title) {
        errorsValidation.title = "Merci de renseigner ce champ";
        isError = true;
      }

      if (!value.values.content && value.touched.content) {
        errorsValidation.content = "Merci de renseigner ce champ";
        isError = true;
      }

      const copyFormObject = { ...serviceToSend };
      copyFormObject["errors"] = errorsValidation;
      setServiceToSend(copyFormObject);
    } else {
      isError = true;
    }
    return isError;
  };

  const sendServiceToServer = async (e) => {
    e.preventDefault();
    if (!validateServiceForm(serviceToSend)) {
      serviceToSend.values["section"] = serviceToSend.values["title"]
        .toLowerCase()
        .split(" ")
        .join("-")
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "");

      await sendDataToServer(
        setIsLoading,
        setErrorMessage,
        userSession.token,
        pageType === "create" ? "POST" : "PUT",
        "service",
        { ...serviceToSend.values, _id: pageType },
        setModale,
        pageType === "create"
          ? "Un nouveau texte de section de la page service"
          : "Ce texte de section de la page service à été mis à jour",
        "services"
      );
    } else {
      const keysObjectToSend = Object.keys(serviceToSend.errors);

      let newTouchedObject = {};

      keysObjectToSend.forEach((key) => {
        newTouchedObject = { ...newTouchedObject, [`${key}`]: true };
      });

      const copyServiceToSend = { ...serviceToSend };
      copyServiceToSend.touched = newTouchedObject;
      setServiceToSend(copyServiceToSend);

      setErrorMessage({
        title: "Erreur formulaire",
        content: "Erreur de validation du formulaire",
      });
    }
  };

  const fetchServiceSingle = async () => {
    if (!isLoading) {
      setIsLoading(true);
    }
    if (pageType !== "create") {
      const response = await requestHandle(
        setIsLoading,
        setErrorMessage,
        userSession.token,
        "POST",
        "service",
        { single: pageType }
      );

      if (response && response.statut === "OK") {
        setServiceToSend({
          values: response.message,
          errors: {},
          touched: {},
        });
      }
    }
  };

  useEffect(() => {
    if (pageType !== "create") {
      fetchServiceSingle();
    }
  }, []);

  return (
    <main
      id="presentation-create-update-container"
      className="backoffice-container bg-gray-800">
      {errorMessage && (
        <AlertMessage
          errorMessage={errorMessage}
          setErrorMessage={setErrorMessage}
        />
      )}
      {modale && <ModalSuccess modale={modale} setModale={setModale} />}

      <form
        className="bg-gray-100 p-5 text-center w-[100vw] min-h-[100vh]"
        onSubmit={(e) => sendServiceToServer(e)}>
        <h1 className="mb-5 font-bold text-lg">
          {pageType === "create"
            ? "Création d'une section de la page service"
            : "Mis à jour d'une section de la page service"}
        </h1>

        {inputArray &&
          inputArray.length > 0 &&
          inputArray.map((input, index) => (
            <FormInput
              index={index}
              state={serviceToSend}
              setState={setServiceToSend}
              validateFunction={validateServiceForm}
              page={pageType === "create" ? "service-create" : "service-update"}
              inputType={input["type"]}
              objectKey={input["objectKey"]}
              required={input["required"] ? true : false}
              label={input["label"] ? input["label"] : ""}
              placeholder={input["placeholder"] ? input["placeholder"] : ""}
              isError={
                input["required"] &&
                serviceToSend.touched[input["objectKey"]] &&
                serviceToSend.errors[input["objectKey"]]
              }
            />
          ))}

        <button
          type="submit"
          className="px-8 py-3 font-semibold rounded bg-gray-800 text-gray-100">
          Envoyer
        </button>
      </form>
    </main>
  );
};

export default ServiceSAVCreateUpdate;
