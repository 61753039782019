import React, { useState } from "react";

import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";

// IMPORT CONTEXT
import { useLoadingErrorContext } from "../../../Shared/Context/LoadingErrorContext";

// IMPORT UTILS
import { requestHandle } from "../../../Shared/utils/RequestFunctions";

import FormInput from "../../../Shared/components/FormInput/FormInput";

const LoginForm = ({ setUser }) => {
  const navigate = useNavigate();
  const { isLoading, setIsLoading, setErrorMessage } = useLoadingErrorContext();

  const [loginData, setLoginData] = useState({
    values: {},
    errors: {},
    touched: {},
  });

  const inputArray = [
    {
      type: "checkbox",
      objectKey: "loginCheckBox",
      label: "",
      placeholder: "",
      hidden: true,
    },
    {
      type: "email",
      objectKey: "loginEmail",
      required: true,
      label: "Email",
      placeholder: "un email",
    },
    {
      type: "password",
      objectKey: "loginPassword",
      required: true,
      label: "Mot de passe",
      placeholder: "un mot de passe",
    },
  ];

  const validateLogin = (value) => {
    const errorsValidation = {};
    let isError = false;

    if (value) {
      if (!value.values.loginEmail) {
        errorsValidation.loginEmail = "Merci de renseigner de champ";
      }

      if (
        !value.values.loginEmail
          .toLowerCase()
          .match(
            /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/
          )
      ) {
        errorsValidation.loginEmail = "Merci de renseigner un email valide";
      }

      if (!value.values.loginPassword) {
        errorsValidation.loginPassword = "Merci de renseigner de champ";
      }

      const copyFormObject = { ...loginData };
      copyFormObject["errors"] = errorsValidation;
      setLoginData(copyFormObject);
    } else {
      isError = true;
    }
    return isError;
  };

  const sendLoginDataToServer = async (e) => {
    e.preventDefault();
    if (!validateLogin(loginData)) {
      const results = await requestHandle(
        setIsLoading,
        setErrorMessage,
        null,
        "POST",
        "user/login",
        loginData.values
      );

      if (results && results.statut === "OK") {
        setUser(results.message.token);
        navigate("/backoffice/home");
      }
    } else {
      const keysObjectToSend = Object.keys(loginData.errors);

      let newTouchedObject = {};

      keysObjectToSend.forEach((key) => {
        newTouchedObject = { ...newTouchedObject, [`${key}`]: true };
      });

      const copyLogindata = { ...loginData };
      copyLogindata.touched = newTouchedObject;
      setLoginData(copyLogindata);

      setErrorMessage({
        title: "Erreur formulaire",
        content: "Erreur de validation du formulaire",
      });
    }
  };

  const formikLogin = useFormik({
    initialValues: {
      loginEmail: "",
      loginPassword: "",
      loginCheckBox: false,
    },
    validate: validateLogin,
    onSubmit: async (v) => {},
  });
  return (
    <>
      <section className="block p-6 w-[100vw]">
        <form
          className="container mx-auto space-y-12"
          onSubmit={(e) => sendLoginDataToServer(e)}>
          <fieldset className="gap-6 p-10 rounded-md flex flex-col  shadow-sm dark:bg-gray-100">
            <h1 className="text-center font-bold text-lg text-gray-800">
              Connectez-vous
            </h1>

            <div className=" gap-4  lg:col-span-3">
              {inputArray &&
                inputArray.length > 0 &&
                inputArray.map((input, index) => {
                  return (
                    <FormInput
                      index={index}
                      state={loginData}
                      setState={setLoginData}
                      validateFunction={validateLogin}
                      page={"login-page"}
                      inputType={input["type"]}
                      objectKey={input["objectKey"]}
                      required={input["required"] ? true : false}
                      label={input["label"] ? input["label"] : ""}
                      placeholder={
                        input["placeholder"] ? input["placeholder"] : ""
                      }
                      isError={
                        input["required"] &&
                        loginData.touched[input["objectKey"]] &&
                        loginData.errors[input["objectKey"]]
                      }
                      hidden={input["hidden"] ? true : false}
                    />
                  );
                })}
            </div>
            <button
              type="submit"
              className="px-8 py-3 inline-block w-25 mx-auto font-semibold rounded text-gray-100 bg-gray-800 ">
              Envoyer
            </button>
          </fieldset>
        </form>
      </section>
    </>
  );
};

export default LoginForm;
