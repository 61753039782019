import React, { useState, useEffect, useRef } from "react";

import { useFormik, Formik } from "formik";
import { useLocation, Link } from "react-router-dom";

// IMPORT UTILS
import {
  sendDataToServer,
  requestHandle,
} from "../../Shared/utils/RequestFunctions";

import { onChangeFormInput } from "../../Shared/utils/EventFunctions";

// IMPORT CONTEXT
import { useLoadingErrorContext } from "../../Shared/Context/LoadingErrorContext";

// IMPORT COMPONENT
import AlertMessage from "../../Shared/components/AlertMessage/AlertMessage";
import ModalSuccess from "../../Shared/components/Modals/ModalSuccess";
import BackofficeGoBack from "../../Shared/components/BackofficeGoBack/BackofficeGoBack";
import LoadingWrapper from "../../Shared/components/LoadingWrapper/LoadingWrapper";
import FormInput from "../../Shared/components/FormInput/FormInput";

const ContactBackSingle = ({ userSession }) => {
  const { setIsLoading, isLoading, setErrorMessage, errorMessage } =
    useLoadingErrorContext();

  const savID = useLocation().pathname.split("/").pop();

  const [modale, setModale] = useState(false);
  const [savSingleData, setSavSingleData] = useState({
    values: {},
    touched: {},
    errors: {},
  });

  const inputArray = [
    {
      type: "text",
      objectKey: "last_name",
      required: true,
      label: "Nom",
      placeholder: "un nom",
    },
    {
      type: "text",
      objectKey: "first_name",
      required: true,
      label: "Prénom",
      placeholder: "un prénom",
    },
    {
      type: "text",
      objectKey: "phone_number",
      required: true,
      label: "Numéro de téléphone",
      placeholder: "un numéro de téléphone",
    },

    {
      type: "text",
      objectKey: "email",
      required: true,
      label: "Email",
      placeholder: "un email",
    },
    {
      type: "text",
      objectKey: "adress",
      required: true,
      label: "Adresse",
      placeholder: "une adresse",
    },

    {
      type: "text",
      objectKey: "post_code",
      required: true,
      label: "Code postal",
      placeholder: "un code postal",
    },
    {
      type: "text",
      objectKey: "city",
      required: true,
      label: "Ville",
      placeholder: "une ville",
    },
    {
      type: "text",
      objectKey: "product_category",
      required: true,
      label: "Catégorie de produit",
      placeholder: "une catégorie de produit",
    },
    {
      type: "text",
      objectKey: "brand",
      required: true,
      label: "Marque",
      placeholder: "une marque",
    },
    {
      type: "text",
      objectKey: "reference",
      required: true,
      label: "Référence",
      placeholder: "une référence",
    },
    {
      type: "text",
      objectKey: "serial_number",
      required: true,
      label: "Numéro de série",
      placeholder: "un numéro de série",
    },
    {
      type: "textarea",
      objectKey: "message",
      required: true,
      label: "Nature de la panne",
      placeholder: "un message",
    },
    {
      type: "textarea",
      objectKey: "personal_notes",
      required: true,
      label: "Notes",
      placeholder: "un memo",
    },
  ];

  const validateContact = (value) => {
    const errorsValidation = {};
    if (!value.values.last_name && value.touched.last_name) {
      errorsValidation.last_name = "Merci de renseigner de champ";
    }

    if (!value.values.phone_number && value.touched.phone_number) {
      errorsValidation.phone_number = "Merci de renseigner de champ";
    }

    if (!value.values.message && value.touched.message) {
      errorsValidation.message = "Merci de renseigner de champ";
    }

    if (!value.values.email && value.touched.email) {
      errorsValidation.email = "Merci de renseigner de champ";
    }

    if (
      value.values.email &&
      !value.values.email
        .toLowerCase()
        .match(
          /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/
        ) &&
      value.touched.email
    ) {
      errorsValidation.email = "Merci de renseigner un email valide";
    }

    const copyFormObject = { ...savSingleData };
    copyFormObject["errors"] = errorsValidation;
    setSavSingleData(copyFormObject);
  };

  const sendContactUpdate = async (e) => {
    e.preventDefault();
    if (!validateContact(savSingleData)) {
      const response = await sendDataToServer(
        setIsLoading,
        setErrorMessage,
        userSession.token,
        "PUT",
        "sav",
        { ...savSingleData.values, type: "UPDATE" },
        setModale,
        "Modification réusse sur la demande de dépannage",
        "demandes de dépannage"
      );
    } else {
      const keysObjectToSend = Object.keys(savSingleData.errors);

      let newTouchedObject = {};

      keysObjectToSend.forEach((key) => {
        newTouchedObject = { ...newTouchedObject, [`${key}`]: true };
      });

      const copySavSingleData = { ...savSingleData };
      copySavSingleData.touched = newTouchedObject;
      setSavSingleData(copySavSingleData);

      setErrorMessage({
        title: "Erreur formulaire",
        content: "Erreur de validation du formulaire",
      });
    }
  };

  const fetchSavSingleData = async () => {
    if (savID) {
      const response = await requestHandle(
        setIsLoading,
        setErrorMessage,
        userSession.token,
        "PUT",
        "sav",
        { id: savID, type: "READ" }
      );
      if (response && response.statut === "OK") {
        setSavSingleData({
          values: {
            ...response.message,
            personal_notes: response.message.personal_notes
              ? response.message.personal_notes
              : "",
            robotCheckBox: false,
          },
          touched: {},
          errors: {},
        });
      }
    }
  };

  useEffect(() => {
    fetchSavSingleData();
  }, []);

  if (isLoading) return <LoadingWrapper isLoading={isLoading} />;

  return (
    <main
      id="contact-backoffice-container"
      className="bg-gray-100 p-5 text-center w-[100vw] min-h-[100vh]">
      {errorMessage && (
        <AlertMessage
          errorMessage={errorMessage}
          setErrorMessage={setErrorMessage}
        />
      )}

      <h1 className="text-2xl text-center my-5 text-red-800 font-bold">
        Demande de service après vente
      </h1>

      {modale && <ModalSuccess modale={modale} setModale={setModale} />}

      <div className="">
        <form
          className="feature-input-container"
          onSubmit={async (e) => sendContactUpdate(e)}>
          {inputArray &&
            inputArray.length > 0 &&
            inputArray.map((input, index) => {
              return (
                <FormInput
                  index={index}
                  state={savSingleData}
                  setState={setSavSingleData}
                  validateFunction={validateContact}
                  page={"contact-update"}
                  inputType={input["type"]}
                  objectKey={input["objectKey"]}
                  required={input["required"] ? true : false}
                  label={input["label"] ? input["label"] : ""}
                  placeholder={input["placeholder"] ? input["placeholder"] : ""}
                  isError={
                    input["required"] &&
                    savSingleData.touched[input["objectKey"]] &&
                    savSingleData.errors[input["objectKey"]]
                  }
                />
              );
            })}
          <div className="w-full">
            <button
              className=" block mx-auto py-2 px-5 border-2 border-red-800 hover:bg-red-800 hover:text-gray-100 rounded"
              type="submit">
              Envoyer
            </button>
          </div>
        </form>
      </div>
    </main>
  );
};

export default ContactBackSingle;
