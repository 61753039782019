import React from "react";

import { motion, AnimatePresence } from "framer-motion";
import IconComponent from "../IconComponent/IconComponent";

const AlertMessage = ({ errorMessage, setErrorMessage }) => {
  const variants = {
    dislpay: { opacity: 1, scale: 1 },
    hidden: { opacity: 0, scale: 0 },
  };
  return (
    <AnimatePresence initial={false}>
      <div className=" w-[40%]  rounded shadow-lg overflow-hidden z-30 fixed bottom-10 right-5 bg-gray-50 mx-auto">
        <div className="relative flex text-center items-center text-gray-800 ">
          <div className="self-stretch flex items-center px-3 flex-shrink-0 bg-gray-300 text-violet-600">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
              fill="#991b1b"
              stroke="currentColor"
              className="h-10 w-10">
              <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zm0-384c13.3 0 24 10.7 24 24V264c0 13.3-10.7 24-24 24s-24-10.7-24-24V152c0-13.3 10.7-24 24-24zM224 352a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z" />
            </svg>
          </div>
          <div className="p-4 flex-1">
            {errorMessage && errorMessage.title && (
              <h3 className="text-xl font-bold text-red-800">
                {errorMessage.title}
              </h3>
            )}
            {errorMessage && errorMessage.content && (
              <p className="text-sm text-gray-600">{errorMessage.content}</p>
            )}
          </div>
          <button className="absolute top-2 right-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
              fill="#991b1b"
              className="h-5 w-5"
              onClick={() => setErrorMessage("")}>
              <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM175 175c9.4-9.4 24.6-9.4 33.9 0l47 47 47-47c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-47 47 47 47c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-47-47-47 47c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l47-47-47-47c-9.4-9.4-9.4-24.6 0-33.9z" />
            </svg>
          </button>
        </div>
      </div>
    </AnimatePresence>
  );
};

export default AlertMessage;
