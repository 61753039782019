import React, { useEffect, useState } from "react";

const FeatureForm = ({ productToSend, setProductToSend, arrayFeature }) => {
  return (
    <div className="">
      <div className="feature-input-container p-10 flex-wrap">
        {arrayFeature &&
          arrayFeature.length > 0 &&
          arrayFeature.map((feature, index) => {
            return (
              <div
                key={index}
                className="mx-auto text-center mb-4 w-[30%] flex flex-col shadow-sm p-4 bg-gray-50">
                <label
                  className="text-md font-semibold inline-block mb-2 text-gray-800"
                  htmlFor={`product-form-input-${feature.id}`}>
                  {feature.featureName}{" "}
                  {feature.measurement ? ` en ${feature.measurement}` : ""}
                </label>
                <input
                  value={
                    productToSend.values.features &&
                    Array.isArray(productToSend.values.features) &&
                    productToSend.values.features.find(
                      (elmt) => elmt.id === feature.id
                    )
                      ? productToSend.values.features.find(
                          (elmt) => elmt.id === feature.id
                        ).value
                      : ""
                  }
                  type="text"
                  className={`w-full rounded-md focus:ring focus:ri focus:ri  text-gray-800 p-2 border-2 border-gray-800 shadow-sm`}
                  id={`product-form-input-${feature.featureName}`}
                  name={`product-form-input-${feature.featureName}`}
                  onChange={(e) => {
                    const copyproductToSend = { ...productToSend };

                    if (!Array.isArray(copyproductToSend.values.features)) {
                      copyproductToSend.values.features = [
                        {
                          id: feature.id,
                          value: e.target.value,
                        },
                      ];
                    } else if (
                      copyproductToSend.values.features.find(
                        (elmt) => elmt.id === feature.id
                      )
                    ) {
                      let elmtToUpdate = copyproductToSend.values.features.find(
                        (elmt) => elmt.id === feature.id
                      );

                      elmtToUpdate.value = e.target.value;
                    } else {
                      copyproductToSend.values.features.push({
                        id: feature.id,
                        value: e.target.value,
                      });
                    }

                    setProductToSend(copyproductToSend);
                  }}
                />
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default FeatureForm;
