import React, { useState, useEffect } from "react";

import { Link, useLocation, useNavigate } from "react-router-dom";
import { requestHandle } from "../../Shared/utils/RequestFunctions";

import { useLoadingErrorContext } from "../../Shared/Context/LoadingErrorContext";

import AlertMessage from "../../Shared/components/AlertMessage/AlertMessage";
import ClientProductCard from "../../Shared/components/ClientProductCard/ClientProductCard";
import SEOHelmet from "../../Shared/components/SeoHelmet/SEOHelmet";
import Carousel from "../../Shared/components/Carousel/Carousel";
import CarouselSlick from "../../Shared/components/Carousel/CarouselSlick";

const CategoryPage = ({ userSession }) => {
  const navigate = useNavigate();
  const categorySlug = useLocation().pathname.split("/").pop();

  const { isLoading, setIsLoading, errorMessage, setErrorMessage, innerWidth } =
    useLoadingErrorContext();

  const [categoryData, setCategoryData] = useState([]);

  const fetchCategoryData = async () => {
    const response = await requestHandle(
      setIsLoading,
      setErrorMessage,
      "",
      "POST",
      "client/category",
      { slug: categorySlug }
    );
    if (response && response.message) {
      setCategoryData(response.message);
    }
  };

  useEffect(() => {
    fetchCategoryData();
  }, [categorySlug]);

  return (
    <main id="category-page-container">
      {errorMessage && (
        <AlertMessage
          errorMessage={errorMessage}
          setErrorMessage={setErrorMessage}
        />
      )}

      {categoryData["category"] && categoryData["category"]["name"] && (
        <SEOHelmet title={categoryData["category"]["name"]} />
      )}

      {categoryData && categoryData["category"] && innerWidth < 768 && (
        <h1 className="text-4xl font-bold text-center my-5 text-red-800">{`${categoryData["category"]["name"]} `}</h1>
      )}
      <section
        id="category-page-sub_category-list"
        className="flex flex-wrap justify-center pt-5">
        {categoryData &&
          categoryData["sub_category"] &&
          categoryData["sub_category"].length > 0 &&
          categoryData["sub_category"].map((item) => {
            return (
              <div
                key={item["_id"]}
                className="relative w-[90%] mx-auto md:w-[25%] md:mx-5 h-[20vh] m-5 rounded">
                {item["picture"] && (
                  <img
                    src={item["picture"]}
                    alt=""
                    className="opacity-6 object-cover rounded"
                  />
                )}
                <div
                  onClick={() =>
                    navigate(
                      `/${categoryData["category"]["slug"]}/${item["slug"]}`
                    )
                  }
                  className="cursor-pointer absolute bottom-0 flex items-center justify-center left-0 right-0 text-center bg-gray-800/50 h-[100%] hover:bg-gray-800/80">
                  <Link
                    className="text-gray-100 font-semibold "
                    to={`/${categoryData["category"]["slug"]}/${item["slug"]}`}>
                    {item["name"]}
                  </Link>
                </div>
              </div>
            );
          })}
      </section>

      {categoryData &&
        categoryData["products"] &&
        categoryData["products"].length > 0 && (
          <section id="category-page-product-list" className="w-[85%] mx-auto">
            <h2 className="text-center my-5 font-bold text-4xl text-gray-600">
              Nos produits
            </h2>

            <div className="flex overflow-x-scroll">
              {categoryData["products"].map((item, index) => {
                if (index < 5)
                  return <ClientProductCard key={index} productData={item} />;
              })}
            </div>
          </section>
        )}

      {categoryData &&
        categoryData["brands"] &&
        categoryData["brands"].length > 0 && (
          <section
            id="category-page-brand-list"
            className="w-[85%] mx-auto pb-4">
            <h2 className="text-center my-5 font-bold text-4xl text-gray-600">
              Nos marques
            </h2>
            <CarouselSlick brand={true} brandsData={categoryData["brands"]} />
            {/* <div className="flex overflow-x-scroll justify-around items-center">
              {categoryData["brands"].map((item) => {
                return (
                  <div
                    key={item["_id"]}
                    className="flex-none shadow-xl category-page-sub-category-list-item h-[24vw] w-[24vw] md:h-[12vw] md:w-[12vw] rounded object-contain">
                    {item["logo"] && (
                      <img
                        className="shadow-lg py-2 px-4 "
                        src={item["logo"]}
                        alt=""
                      />
                    )}
                  </div>
                );
              })}
            </div> */}
          </section>
        )}
    </main>
  );
};

export default CategoryPage;
