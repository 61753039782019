import React, { useState, useEffect } from "react";

// IMPORT LIB
import Cookies from "js-cookie";
import { sanitize } from "dompurify";
import { useNavigate } from "react-router-dom";
// IMPORT CONTEXT
import { useLoadingErrorContext } from "../../Shared/Context/LoadingErrorContext";

// IMPORT UTILS
import { requestHandle } from "../../Shared/utils/RequestFunctions";

// IMPORT COMPONENT
import BackofficeGoBack from "../../Shared/components/BackofficeGoBack/BackofficeGoBack";
import AlertMessage from "../../Shared/components/AlertMessage/AlertMessage";
import TableComponent from "../../Shared/components/TableComponent/TableComponent";
import ModalDelete from "../../Shared/components/Modals/ModalDelete";

const PresList = ({ userSession }) => {
  const { isLoading, setIsLoading, errorMessage, setErrorMessage } =
    useLoadingErrorContext();

  const [modaleDelete, setModaleDelete] = useState(false);
  const [presentationList, setPresentationList] = useState([]);

  const [listToDisplay,setListToDisplay] = useState([])

  const fetchPresentationList = async () => {
    const response = await requestHandle(
      setIsLoading,
      setErrorMessage,
      userSession.token,
      "GET",
      "presentation"
    );
    if (response && response.statut === "OK") {
      setPresentationList(response.message);
      setListToDisplay(response.message)
    }
  };

  useEffect(() => {
    fetchPresentationList();
  }, []);

  return (
    <main id="presentation-liste-container" className="backoffice-container">
      {errorMessage ? (
        <AlertMessage
          errorMessage={errorMessage}
          setErrorMessage={setErrorMessage}
        />
      ) : null}

      {modaleDelete ? (
        <ModalDelete
          setIsLoading={setIsLoading}
          setErrorMessage={setErrorMessage}
          modale={modaleDelete}
          setModale={setModaleDelete}
          token={userSession.token}
        />
      ) : null}

      <TableComponent
        token={userSession.token}
        // setModaleDelete={setModaleDelete}
        collection={"presentation"}
        listTitle={"Le texte de présentation"}
        setListArray={setPresentationList}
        listArray={presentationList.sort(
          (a, b) => new Date(b.created_at) - new Date(a.created_at)
        )}
        listToDisplay={listToDisplay}
        setListToDisplay={setListToDisplay}
        fieldsArray={[
          { colTitle: "Présentation", type: "innerHtml", objectKey: "content" },
          {
            colTitle: "Date de création",
            type: "date",
            objectKey: "created_at",
          },
        ]}
      />
    </main>
  );
};

export default PresList;
