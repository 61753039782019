import React, { useState, useEffect, useRef } from "react";

import { useFormik, Formik } from "formik";
import { useLocation, Link } from "react-router-dom";

// IMPORT UTILS
import {
  sendDataToServer,
  requestHandle,
} from "../../Shared/utils/RequestFunctions";

// IMPORT CONTEXT
import { useLoadingErrorContext } from "../../Shared/Context/LoadingErrorContext";

// IMPORT COMPONENT
import ProductInput from "../Products/components/ProductInput";
import ClientProductCard from "../../Shared/components/ClientProductCard/ClientProductCard";
import AlertMessage from "../../Shared/components/AlertMessage/AlertMessage";
import ModalSuccess from "../../Shared/components/Modals/ModalSuccess";
import BackofficeGoBack from "../../Shared/components/BackofficeGoBack/BackofficeGoBack";
import LoadingWrapper from "../../Shared/components/LoadingWrapper/LoadingWrapper";
import FormInput from "../../Shared/components/FormInput/FormInput";

const ContactBackSingle = ({ userSession }) => {
  const { setIsLoading, isLoading, setErrorMessage, errorMessage } =
    useLoadingErrorContext();

  const informationRequestID = useLocation().pathname.split("/").pop();

  const [modale, setModale] = useState(false);
  const [informationRequestData, setInformationRequestData] = useState({
    values: {},
    touched: {},
    errors: {},
  });

  const inputArray = [
    {
      type: "text",
      objectKey: "last_name",
      required: true,
      label: "Nom",
      placeholder: "",
    },

    {
      type: "text",
      objectKey: "email",
      required: true,
      label: "Email",
      placeholder: "",
    },
    {
      type: "text",
      objectKey: "phone_number",
      label: "Numéro de téléphone",
      placeholder: "",
    },

    {
      type: "textarea",
      objectKey: "message",
      required: true,
      label: "Message du client",
      placeholder: "",
    },
    {
      type: "textarea",
      objectKey: "personal_notes",
      label: "Memo",
      placeholder: "",
    },
  ];

  const validateInformationRequestSingle = (value) => {
    const errorsValidation = {};
    if (!value.values.last_name && value.touched.last_name) {
      errorsValidation.last_name = "Merci de renseigner de champ";
    }

    if (!value.values.phone_number && value.touched.phone_number) {
      errorsValidation.phone_number = "Merci de renseigner de champ";
    }

    if (!value.values.message && value.touched.message) {
      errorsValidation.message = "Merci de renseigner de champ";
    }

    if (!value.values.email && value.touched.email) {
      errorsValidation.email = "Merci de renseigner de champ";
    }

    if (
      value.values.email &&
      !value.values.email
        .toLowerCase()
        .match(
          /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/
        ) &&
      value.touched.email
    ) {
      errorsValidation.email = "Merci de renseigner un email valide";
    }

    const copyFormObject = { ...informationRequestData };
    copyFormObject["errors"] = errorsValidation;
    setInformationRequestData(copyFormObject);
  };

  const sendInformationRequestUpdate = async (e) => {
    e.preventDefault();
    if (!validateInformationRequestSingle(informationRequestData)) {
      const dataToSend = informationRequestData.values;

      delete dataToSend["product"];

      dataToSend["type"] = "UPDATE";

      const response = await sendDataToServer(
        setIsLoading,
        setErrorMessage,
        userSession.token,
        "PUT",
        "informations_request",
        dataToSend,
        setModale,
        "Modification réusse sur la demande d'information produit",
        "demandes d'information produit"
      );
    } else {
      setErrorMessage({
        title: "Erreur de saisie",
        content: "Merci de remplir tout les champs requis",
      });
    }
  };

  const fetchSingleInformationsRequest = async () => {
    if (!isLoading) {
      setIsLoading(true);
    }
    if (informationRequestID) {
      const response = await requestHandle(
        setIsLoading,
        setErrorMessage,
        userSession.token,
        "PUT",
        "informations_request",
        { id: informationRequestID, type: "READ" }
      );
      if (response && response.statut === "OK") {
        setInformationRequestData({
          values: {
            ...response.message,
            personal_notes: response.message.personal_notes
              ? response.message.personal_notes
              : "",
            robotCheckBox: false,
          },
          touched: {},
          errors: {},
        });
      }
    }
  };

  useEffect(() => {
    fetchSingleInformationsRequest();
  }, []);

  if (isLoading) return <LoadingWrapper isLoading={isLoading} />;

  return (
    <main
      id="information-request-backoffice-container"
      className="bg-gray-100 p-5 text-center w-[100vw] min-h-[100vh]">
      {errorMessage && (
        <AlertMessage
          errorMessage={errorMessage}
          setErrorMessage={setErrorMessage}
        />
      )}

      <h1 className="text-2xl text-center my-5 text-red-800 font-bold">
        Demande de renseigement produit
      </h1>

      {modale && <ModalSuccess modale={modale} setModale={setModale} />}

      {informationRequestData && informationRequestData.values["product"] && (
        <div className="product-container flex justify-center">
          <ClientProductCard
            productData={informationRequestData.values["product"]}
          />
        </div>
      )}

      {informationRequestData && (
        <form
          className="feature-input-container"
          onSubmit={(e) => sendInformationRequestUpdate(e)}>
          {inputArray &&
            inputArray.length > 0 &&
            inputArray.map((input, index) => {
              return (
                <FormInput
                  index={index}
                  state={informationRequestData}
                  setState={setInformationRequestData}
                  validateFunction={validateInformationRequestSingle}
                  page={"information-request-update"}
                  inputType={input["type"]}
                  objectKey={input["objectKey"]}
                  required={input["required"] ? true : false}
                  label={input["label"] ? input["label"] : ""}
                  placeholder={input["placeholder"] ? input["placeholder"] : ""}
                  isError={
                    input["required"] &&
                    informationRequestData.touched[input["objectKey"]] &&
                    informationRequestData.errors[input["objectKey"]]
                  }
                />
              );
            })}
          <div className="w-full">
            <button
              className=" block mx-auto py-2 px-5 border-2 border-red-800 hover:bg-red-800 hover:text-gray-100 rounded"
              type="submit">
              Envoyer
            </button>
          </div>
        </form>
      )}
    </main>
  );
};

export default ContactBackSingle;
