import React, { useRef, useEffect } from "react";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import CustomEditor from "@ckeditor/ckeditor5-HomeMade";

import {
  onChangeFormInput,
  onChangeStateObject,
} from "../../utils/EventFunctions";

const CKEditorComponent = ({
  state,
  setState,
  objectKey,
  validateFunction,
}) => {
  const editorRef = useRef(null);

  useEffect(() => {
    if (editorRef) {
      const editor = editorRef.current.editor;
      if (editor) {
        editor.on("instanceReady", function (evt) {
          editor.setData(
            state.values[objectKey]
              ? state.values[objectKey]
              : state.content
              ? state.content
              : ""
          );
        });
      }
    }
  }, []);
  return (
    <CKEditor
      editor={CustomEditor}
      ref={editorRef}
      config={{
        toolbar: {
          items: [
            "undo",
            "redo",
            "alignment",
            "|",
            "heading",
            "bold",
            "italic",
            "|",

            "bulletedList",
            "numberedList",
          ],
          shouldNotGroupWhenFull: true,
        },
        heading: {
          options: [
            {
              model: "paragraph",
              title: "Paragraph",
              class: "ck-heading_paragraph",
            },
            // {
            //   model: "heading1",
            //   view: "h1",
            //   title: "Heading 1",
            //   class: "ck-heading_heading1",
            // },
            {
              model: "heading3",
              view: "h3",
              title: "Heading 3",
              class: "ck-heading_heading3",
            },
            {
              model: "heading4",
              view: "h4",
              title: "Heading 4",
              class: "ck-heading_heading4",
            },
            {
              model: "heading5",
              view: "h5",
              title: "Heading 5",
              class: "ck-heading_heading5",
            },
            {
              model: "heading6",
              view: "h6",
              title: "Heading 6",
              class: "ck-heading_heading6",
            },
          ],
        },
      }}
      data={
        state.content
          ? state.content
          : objectKey && state.values[objectKey]
          ? state.values[objectKey]
          : ""
      }
      onChange={(event, editor) => {
        const data = editor.getData();
        if (!objectKey) {
          onChangeStateObject("content", data, state, setState);
        } else {
          onChangeFormInput(objectKey, data, state, setState, validateFunction);
        }
      }}
    />
  );
};

export default CKEditorComponent;
