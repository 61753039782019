import axios from "axios";

export const requestHandle = async (
  setIsLoading,
  setErrorMessage,
  token,
  axiosMethod,
  routeByCollection,
  values
) => {
  setIsLoading(true);
  setErrorMessage("");

  let response;
  try {
    let formData;
    if (values) {
      formData = new FormData();

      const keys = Object.keys(values);

      keys.map((key) => {
        formData.append(key, values[key]);
      });

      if (values.arrayOfPicture) {
        values.arrayOfPicture.map((pic, index) => {
          formData.append(`galleryPic${index}`, pic);
        });
      }
    }

    const url_server = `https://kc-menager-748ad3999de9.herokuapp.com/${routeByCollection}`;
    // const url_server = `http://localhost:4000/${routeByCollection}`;

    response = await axios({
      method: axiosMethod,
      url: url_server,

      data: formData && !formData["single"] ? formData : null,

      params: formData && formData["single"] ? formData["single"] : null,

      headers: {
        Authorization: token,
        "Content-Type": "multipart/form-data",
      },
    });
  } catch (error) {
    if (error.response && error.response.data.message) {
      setErrorMessage({
        title: `Erreur ${error.response.status ? error.response.status : ""}`,
        content: error.response.data.message,
      });
    } else if (error && error.message) {
      setErrorMessage({
        title: `Error ${
          error.response && error.response.status ? error.response.status : ""
        }`,
        content: error.message,
      });
    }
  }
  setIsLoading(false);
  return response?.data;
};

export const sendDataToServer = async (
  setIsLoading,
  setErrorMessage,
  token,
  requestType,
  mongoCollection,
  dataToSend,
  setModale,
  modaleTitle,
  modaleListeName
) => {
  let response;

  if (!dataToSend) {
    return setErrorMessage({
      title: "Erreur formulaire",
      content: "merci de compléter le formulaire avant envoie",
    });
  } else {
    response = await requestHandle(
      setIsLoading,
      setErrorMessage,
      token,
      requestType,
      mongoCollection,
      dataToSend
    );

    if (response && response.statut === "OK") {
      setModale({
        title: modaleTitle,
        listeName: modaleListeName,
        collection: mongoCollection,
      });

      return response;
    }
  }
};

export const statutUpdate = async (
  setIsLoading,
  setErrorMessage,
  collection,
  token,
  index,
  state,
  setState
) => {
  const copyStateList = [...state];
  copyStateList[index]["statut"] = !copyStateList[index]["statut"];
  setState(copyStateList);

  if (
    copyStateList[index]["category"] &&
    typeof copyStateList[index]["category"] !== "string"
  ) {
    copyStateList[index]["category"] = copyStateList[index]["category"]["_id"];
  }

  if (
    copyStateList[index]["sub_category"] &&
    typeof copyStateList[index]["sub_category"] !== "string"
  ) {
    copyStateList[index]["sub_category"] =
      copyStateList[index]["sub_category"]["_id"];
  }

  const response = await requestHandle(
    setIsLoading,
    setErrorMessage,
    token,
    "PUT",
    collection,
    copyStateList[index]
  );
};

export const updateSingleField = async (
  setIsLoading,
  setErrorMessage,
  objectKey,
  collection,
  token,
  index,
  state,
  setState,
  value
) => {
  const copyStateList = [...state];
  if (value) {
    copyStateList[index][objectKey] = value;
    setState(copyStateList);
  } else {
    copyStateList[index][objectKey] = !copyStateList[index][objectKey];
    setState(copyStateList);
  }

  const response = await requestHandle(
    setIsLoading,
    setErrorMessage,
    token,
    "PUT",
    collection,
    value
      ? {
          [`${objectKey}`]: value,
          _id: copyStateList[index]["_id"],
          singleField: true,
        }
      : {
          [`${objectKey}`]: copyStateList[index][objectKey],
          _id: copyStateList[index]["_id"],
          singleField: true,
        }
  );

  return response;
};
