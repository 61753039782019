import React, { useState, useEffect } from "react";

// IMPORT LIB
import { useNavigate, useLocation } from "react-router-dom";
// IMPORT CONTEXT
import { useLoadingErrorContext } from "../../Shared/Context/LoadingErrorContext";

// IMPORT UTILS
import { requestHandle } from "../../Shared/utils/RequestFunctions";

// IMPORT COMPONENT
import BackofficeGoBack from "../../Shared/components/BackofficeGoBack/BackofficeGoBack";
import AlertMessage from "../../Shared/components/AlertMessage/AlertMessage";

import ModalDelete from "../../Shared/components/Modals/ModalDelete";
import TableComponent from "../../Shared/components/TableComponent/TableComponent";

const BrandsList = ({ userSession }) => {
  const navigate = useNavigate();

  const pageType = useLocation().pathname.split("/").pop();

  const { isLoading, setIsLoading, errorMessage, setErrorMessage } =
    useLoadingErrorContext();

  const [modaleDelete, setModaleDelete] = useState(false);
  const [brandsList, setBrandsList] = useState([]);
  const [listToDisplay, setListToDisplay] = useState([]);

  const fetchBrandsList = async () => {
    const response = await requestHandle(
      setIsLoading,
      setErrorMessage,
      userSession.token,
      "GET",
      "brand"
    );

    if (response && response.statut === "OK") {
      setBrandsList(response.message);
      setListToDisplay(response.message);
    }
  };

  useEffect(() => {
    fetchBrandsList();
  }, []);

  return (
    <main id="brands-liste-container" className="backoffice-container">
      {errorMessage ? (
        <AlertMessage
          errorMessage={errorMessage}
          setErrorMessage={setErrorMessage}
        />
      ) : null}
      {/* <BackofficeGoBack /> */}

      {modaleDelete ? (
        <ModalDelete
          setIsLoading={setIsLoading}
          setErrorMessage={setErrorMessage}
          modale={modaleDelete}
          setModale={setModaleDelete}
          token={userSession.token}
        />
      ) : null}

      <TableComponent
        token={userSession.token}
        modaleDelete={modaleDelete}
        setModaleDelete={setModaleDelete}
        collection={"brand"}
        addButton={{ text: "une marque", link: "/backoffice/brand/create" }}
        listTitle={"Les marques"}
        setListArray={setBrandsList}
        listArray={brandsList.sort(
          (a, b) => new Date(b.created_at) - new Date(a.created_at)
        )}
        listToDisplay={listToDisplay}
        setListToDisplay={setListToDisplay}
        fieldsArray={[
          { colTitle: "Nom de la marque", type: "text", objectKey: "name" },
          { colTitle: "Logo", type: "picture", objectKey: "logo" },
        ]}
      />
    </main>
  );
};

export default BrandsList;
