import React from "react";

import { onChangeFormInput } from "../../../utils/EventFunctions";
const TextEmailPasswordCheckboxInput = ({
  index,
  page,
  state,
  setState,
  inputType,
  objectKey,
  placeholder,
  isError,
  disableCondition,
  validateFunction,
}) => {
  return (
    <input
      disabled={disableCondition ? true : false}
      type={inputType}
      style={isError ? { border: "solid var(--danger-color) 2px" } : {}}
      id={`form-input-${page}-${index}`}
      name={`form-input-${page}-${index}`}
      className={`w-full rounded-md focus:ring focus:ri focus:ri  text-gray-800 p-2 border-2 border-gray-800 shadow-sm`}
      placeholder={`Merci de renseigner ${placeholder}`}
      value={
        state.values[objectKey]
          ? state.values[objectKey]
          : inputType !== "checkbox"
          ? ""
          : false
      }
      onChange={(e) => {
        onChangeFormInput(
          objectKey,
          inputType !== "checkbox" ? e.target.value : e.target.checked,
          state,
          setState,
          validateFunction
        );
      }}
    />
  );
};

export default TextEmailPasswordCheckboxInput;
