import React from "react";

const AboutUs = () => {
  return (
    <main id="about-us-container" className="p-5 bg-gray-100">
      <div className=" md:container mx-auto">
        <article className="bg-gray-50 p-6 border-gray-800 my-5 leading-8 font-semibold text-center">
          <h1 className="text-4xl text-red-800 mb-5 font-bold">
            KC Ménager, la référence pour tous vos équipements électroménagers à
            Saint-Jean-de- Luz
          </h1>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Explicabo,
            veniam suscipit? Adipisci veniam eos aperiam voluptatum quibusdam
            harum placeat consequuntur libero laborum! Suscipit cumque rerum
            dolor. Deleniti eum quidem dicta.
          </p>
        </article>

        <article
          style={{
            borderRadius: "0 2vw 0 2vw",
          }}
          className="bg-gray-50 border-4 border-red-800 p-8 py-20 my-10 leading flex flex-col text-center md:flex-row justify-between items-center md:text-right ">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 640 512"
            className="w-[30%] md:h-[20vh] p-5 md:mr-5 mb-10 mx-auto text-red-800"
            fill="currentColor">
            <path d="M208 352c114.9 0 208-78.8 208-176S322.9 0 208 0S0 78.8 0 176c0 38.6 14.7 74.3 39.6 103.4c-3.5 9.4-8.7 17.7-14.2 24.7c-4.8 6.2-9.7 11-13.3 14.3c-1.8 1.6-3.3 2.9-4.3 3.7c-.5 .4-.9 .7-1.1 .8l-.2 .2 0 0 0 0C1 327.2-1.4 334.4 .8 340.9S9.1 352 16 352c21.8 0 43.8-5.6 62.1-12.5c9.2-3.5 17.8-7.4 25.3-11.4C134.1 343.3 169.8 352 208 352zM448 176c0 112.3-99.1 196.9-216.5 207C255.8 457.4 336.4 512 432 512c38.2 0 73.9-8.7 104.7-23.9c7.5 4 16 7.9 25.2 11.4c18.3 6.9 40.3 12.5 62.1 12.5c6.9 0 13.1-4.5 15.2-11.1c2.1-6.6-.2-13.8-5.8-17.9l0 0 0 0-.2-.2c-.2-.2-.6-.4-1.1-.8c-1-.8-2.5-2-4.3-3.7c-3.6-3.3-8.5-8.1-13.3-14.3c-5.5-7-10.7-15.4-14.2-24.7c24.9-29 39.6-64.7 39.6-103.4c0-92.8-84.9-168.9-192.6-175.5c.4 5.1 .6 10.3 .6 15.5z" />
          </svg>
          <div className="">
            <h2 className="uppercase first-line:text-lg font-semibold mb-3">
              Conseil
            </h2>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Amet
              consequuntur quidem, pariatur incidunt provident quia
              necessitatibus similique nihil alias molestias quibusdam
              consequatur explicabo nostrum, laborum, quas repellendus quam!
              Enim, fugit.
            </p>
          </div>
        </article>

        <article
          style={{
            borderRadius: "2vw 0 2vw 0",
          }}
          className="bg-gray-50 border-4 border-gray-800 p-8 py-20 my-10 flex flex-col text-center md:flex-row-reverse justify-between items-center leading-8 md:text-left">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 640 512"
            className="w-[30%] md:h-[20vh] p-5 mb-10 md:ml-5 mx-auto text-gray-800"
            fill="currentColor">
            <path d="M48 0C21.5 0 0 21.5 0 48V368c0 26.5 21.5 48 48 48H64c0 53 43 96 96 96s96-43 96-96H384c0 53 43 96 96 96s96-43 96-96h32c17.7 0 32-14.3 32-32s-14.3-32-32-32V288 256 237.3c0-17-6.7-33.3-18.7-45.3L512 114.7c-12-12-28.3-18.7-45.3-18.7H416V48c0-26.5-21.5-48-48-48H48zM416 160h50.7L544 237.3V256H416V160zM112 416a48 48 0 1 1 96 0 48 48 0 1 1 -96 0zm368-48a48 48 0 1 1 0 96 48 48 0 1 1 0-96z" />
          </svg>
          <div className="">
            <h2 className="uppercase first-line:text-lg font-semibold mb-3">
              Livraison
            </h2>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Amet
              consequuntur quidem, pariatur incidunt provident quia
              necessitatibus similique nihil alias molestias quibusdam
              consequatur explicabo nostrum, laborum, quas repellendus quam!
              Enim, fugit.
            </p>
          </div>
        </article>

        <article
          style={{
            borderRadius: "0 2vw 0 2vw",
          }}
          className="bg-gray-50 border-4 border-green-800 p-8 py-20 my-10 leading justify flex-col text-center md:flex-row items-center flex md:text-right">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            className="w-[30%] md:h-[20vh] p-5 md:mr-5 mb-10 mx-auto text-green-800"
            fill="currentColor">
            <path d="M352 320c88.4 0 160-71.6 160-160c0-15.3-2.2-30.1-6.2-44.2c-3.1-10.8-16.4-13.2-24.3-5.3l-76.8 76.8c-3 3-7.1 4.7-11.3 4.7H336c-8.8 0-16-7.2-16-16V118.6c0-4.2 1.7-8.3 4.7-11.3l76.8-76.8c7.9-7.9 5.4-21.2-5.3-24.3C382.1 2.2 367.3 0 352 0C263.6 0 192 71.6 192 160c0 19.1 3.4 37.5 9.5 54.5L19.9 396.1C7.2 408.8 0 426.1 0 444.1C0 481.6 30.4 512 67.9 512c18 0 35.3-7.2 48-19.9L297.5 310.5c17 6.2 35.4 9.5 54.5 9.5zM80 408a24 24 0 1 1 0 48 24 24 0 1 1 0-48z" />
          </svg>
          <div>
            <h2 className="uppercase first-line:text-lg font-semibold mb-3">
              Dépannage
            </h2>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Amet
              consequuntur quidem, pariatur incidunt provident quia
              necessitatibus similique nihil alias molestias quibusdam
              consequatur explicabo nostrum, laborum, quas repellendus quam!
              Enim, fugit.
            </p>
          </div>
        </article>
      </div>
    </main>
  );
};

export default AboutUs;
