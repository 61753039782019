import React, { useState, useEffect } from "react";

import { sanitize } from "dompurify";
import { Link } from "react-router-dom";

import { requestHandle } from "../../Shared/utils/RequestFunctions";
import { useLoadingErrorContext } from "../../Shared/Context/LoadingErrorContext";

import AlertMessage from "../../Shared/components/AlertMessage/AlertMessage";
import LoadingWrapper from "../../Shared/components/LoadingWrapper/LoadingWrapper";
import SEOHelmet from "../../Shared/components/SeoHelmet/SEOHelmet";

const Services = ({ userSession }) => {
  const { isLoading, setIsLoading, errorMessage, setErrorMessage } =
    useLoadingErrorContext();

  const [serviceData, setServiceData] = useState([]);

  const fetchServiceData = async () => {
    const serviceRequest = await requestHandle(
      setIsLoading,
      setErrorMessage,
      // userSession.token,
      "",
      "GET",
      "service"
    );

    if (serviceRequest && serviceRequest.message) {
      setServiceData(serviceRequest.message);
    }
  };

  useEffect(() => {
    fetchServiceData();
  }, []);

  if (isLoading) return <LoadingWrapper />;

  return (
    <main id="services-container" className="p-5 bg-gray-100">
      {errorMessage && (
        <AlertMessage
          errorMessage={errorMessage}
          setErrorMessage={setErrorMessage}
        />
      )}

      <SEOHelmet title={"Services"} />

      <div className="md:container mx-auto">
        <article className="bg-gray-50 p-6 border-gray-800 my-5 leading-8 font-semibold text-center">
          <h1 className="text-4xl text-red-800 mb-5 font-bold">Nos services</h1>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Explicabo,
            veniam suscipit? Adipisci veniam eos aperiam voluptatum quibusdam
            harum placeat consequuntur libero laborum! Suscipit cumque rerum
            dolor. Deleniti eum quidem dicta.
          </p>
        </article>
        {serviceData && serviceData.length > 0 ? (
          serviceData.map((service, index) => {
            if (service.statut) {
              return (
                <section
                  id={service.section}
                  key={index}
                  className="services-section">
                  <article
                    className={`bg-white border-4 relative border-green-800 p-8 my-32 leading flex flex-col md:odd:flex-row md:even:flex-row-reverse justify-between items-center`}>
                    {service.picture && (
                      <div className="w-[70%] mb-10 md:w-[30%] ">
                        <img className="" src={service.picture} alt="" />
                      </div>
                    )}

                    <div
                      className="service-article-content md:w-[60%]"
                      dangerouslySetInnerHTML={{
                        __html: sanitize(service.content),
                      }}></div>
                  </article>
                </section>
              );
            }
          })
        ) : (
          <section className="flex items-center h-full p-16 bg-gray-50 text-gray-800">
            <div className="container flex flex-col items-center justify-center px-5 mx-auto my-8">
              <div className="max-w-md text-center">
                <h2 className="mb-8 font-extrabold text-9xl text-gray-400">
                  <span className="sr-only">Erreur</span>404
                </h2>
                <p className="text-2xl font-semibold md:text-3xl">
                  Un problème est survenue
                </p>
                <p className="mt-4 mb-8 text-gray-600">
                  Veuillez essayer plus tard.
                </p>
                <Link
                  rel="noopener noreferrer"
                  to="/"
                  className="px-8 py-3 font-semibold rounded bg-red-800 text-gray-50">
                  Aller à la page d'accueil
                </Link>
              </div>
            </div>
          </section>
        )}
      </div>
    </main>
  );
};

export default Services;
