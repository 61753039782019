import React from "react";

import { useNavigate } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";

import IconComponent from "../IconComponent/IconComponent";

import { requestHandle, sendDataToServer } from "../../utils/RequestFunctions";

const ModalDelete = ({
  setIsLoading,
  setErrorMessage,
  errorMessage,
  modale,
  setModale,
}) => {
  const navigate = useNavigate();
  const variants = {
    active: { opacity: 1, scale: 1 },
    hidden: { opacity: 0, scale: 0 },
  };

  const sendSavToServer = async () => {
    setModale(false);
    const response = await sendDataToServer(
      setIsLoading,
      setErrorMessage,
      "",
      "POST",
      "sav",
      modale.values,
      setModale,
      "Réussi",
      ""
    );

    if (response && response.statut === "OK") {
      setModale({
        checked: true,
      });
    }
  };

  return (
    // <AnimatePresence initial={false}>
    //   <motion.div
    //     id="modal-client"
    //     animate={modale ? "active" : "hidden"}
    //     variants={variants}>
    //     <div className="close-container">
    //       <IconComponent
    //         icon="close-filled"
    //         size={20}
    //         color="#fff"
    //         style={{ cursor: "pointer" }}
    //         onClick={() => setModale(false)}
    //       />
    //     </div>
    //     {errorMessage ? (
    //       <>
    //         <h3>Une erreur est survenue merci de réessayer</h3>
    //         <div className="alert-error-client">
    //           <h4>{errorMessage.title}</h4>
    //           <h5>{errorMessage.content}</h5>
    //         </div>
    //       </>
    //     ) : (
    //       <>
    //         <h3>
    //           {!modale.checked
    //             ? "Merci de vérifier vos informations de contact avant d'envoyer votre demande"
    //             : "Votre message à bien été envoyé ! Nous allons reprendre contact avec vous prochainement"}
    //         </h3>

    //         {!modale.checked ? (
    //           <>
    //             <p>
    //               Votre numéro de téléphone :{" "}
    //               {modale.values && modale.values["phone_number"]}
    //             </p>

    //             <p>Votre email : {modale.values && modale.values["email"]}</p>

    //             {Object.keys[modale.values] &&
    //               Object.keys[modale.values].length > 0 &&
    //               Object.keys[modale.values].map((key) => {
    //                 return (
    //                   <p>
    //                     {key} {modale.values[key]}
    //                   </p>
    //                 );
    //               })}

    //             <div className="modal-client-button-container">
    //               <button onClick={sendSavToServer}>Envoyer</button>
    //               <button onClick={() => setModale(false)}>Modifier</button>
    //             </div>
    //           </>
    //         ) : (
    //           <div>
    //             <p>Vous allez être redirigée vers la page d'acceuil dans </p>
    //             <button onClick={() => navigate("/")}>
    //               Retourner à la page d'acceuil
    //             </button>
    //           </div>
    //         )}
    //       </>
    //     )}
    //   </motion.div>
    // </AnimatePresence>
    <div className="z-50 min-h-[100vh] fixed top-0 left-0 w-full border-2 border-red-800 flex justify-center items-center">
      <div className="flex flex-col max-w-md gap-2 p-6 rounded-md shadow-md bg-gray-50 text-gray-800">
        <h2 className="flex items-center gap-2 text-xl font-semibold leading-tight tracking-wide text-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            className="w-6 h-6 fill-current shrink-0 text-violet-600">
            <path d="M451.671,348.569,408,267.945V184c0-83.813-68.187-152-152-152S104,100.187,104,184v83.945L60.329,348.568A24,24,0,0,0,81.432,384h86.944c-.241,2.636-.376,5.3-.376,8a88,88,0,0,0,176,0c0-2.7-.135-5.364-.376-8h86.944a24,24,0,0,0,21.1-35.431ZM312,392a56,56,0,1,1-111.418-8H311.418A55.85,55.85,0,0,1,312,392ZM94.863,352,136,276.055V184a120,120,0,0,1,240,0v92.055L417.137,352Z"></path>
            <rect width="32" height="136" x="240" y="112"></rect>
            <rect width="32" height="32" x="240" y="280"></rect>
          </svg>{" "}
          {!modale.checked
            ? "Merci de vérifier vos informations de contact avant d'envoyer votre demande"
            : "Votre message à bien été envoyé ! Nous allons reprendre contact avec vous prochainement"}
        </h2>

        {!modale.checked ? (
          <>
            <p className="flex-1 text-center text-gray-600">
              Votre numéro de téléphone :
              <span className="font-semibold">
                {modale.values && modale.values["phone_number"]}
              </span>
            </p>

            <p className="flex-1 text-center text-gray-600">
              Votre email :{" "}
              <span className="font-semibold">
                {modale.values && modale.values["email"]}
              </span>
            </p>

            {Object.keys[modale.values] &&
              Object.keys[modale.values].length > 0 &&
              Object.keys[modale.values].map((key) => {
                return (
                  <p className="flex-1 text-gray-600">
                    {key} {modale.values[key]}
                  </p>
                );
              })}

            <div className="modal-client-button-container flex justify-around items-center">
              <button
                className="px-6 py-2 rounded-sm"
                onClick={sendSavToServer}>
                Envoyer
              </button>
              <button
                className="px-6 py-2 rounded-sm"
                onClick={() => setModale(false)}>
                Modifier
              </button>
            </div>
          </>
        ) : (
          <div>
            <p className="flex-1 text-gray-600 text-center">
              Vous allez être redirigée vers la page d'acceuil
            </p>
            <button
              className="px-6 py-2 rounded-sm border-2 block mx-auto border-red-800 my-5 hover:bg-red-800 hover:text-gray-100"
              onClick={() => navigate("/")}>
              Retourner à la page d'acceuil
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ModalDelete;
