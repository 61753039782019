import React from "react";

import { useNavigate } from "react-router-dom";

// import { AnimatePresence, motion } from "framer-motion";

import BackofficeGoBack from "../BackofficeGoBack/BackofficeGoBack";
// import IconComponent from "../IconComponent/IconComponent";

const ModalSuccess = ({ modale, setModale }) => {
  const variants = {
    active: { opacity: 1, scale: 1 },
    hidden: { opacity: 0, scale: 0 },
  };
  const navigate = useNavigate();
  return (
    // <AnimatePresence initial={false}>
    //   <motion.div
    //     id="modal-success"
    //     animate={modale ? "active" : "hidden"}
    //     variants={variants}>
    //     <div className="close-container">
    //       <IconComponent
    //         icon="close-filled"
    //         size={20}
    //         color="#fff"
    //         style={{ cursor: "pointer" }}
    //         onClick={() => setModale(false)}
    //       />
    //     </div>
    //     <h3>{modale.title}</h3>

    //     <BackofficeGoBack
    //       listeName={modale.listeName}
    //       collection={modale.collection}
    //     />
    //   </motion.div>
    // </AnimatePresence>
    <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center z-20">
      <div className="flex flex-col max-w-md gap-2 p-6 rounded-md shadow-md bg-gray-50 text-gray-800 ">
        <h2 className="text-xl font-semibold leading-tight tracking-wide text-center">
          Document ajouté avec succès
        </h2>
        {/* <p className="flex-1 text-gray-600 text-center">{modale.title}</p> */}
        <div className="flex flex-col justify-center gap-3 mt-6 sm:flex-row">
          <button
            className="px-6 py-2 rounded-sm"
            onClick={() => {
              navigate(`/backoffice/${modale.collection}/liste`);
              setModale("");
            }}>
            Aller à la liste des {modale.listeName}
          </button>
          <button
            onClick={() => {
              navigate(`/backoffice/home`);
              setModale("");
            }}
            className="px-4 py-2 rounded shadow-sm bg-red-800 text-gray-50">
            Aller à la page d'accueil
          </button>
        </div>
      </div>
    </div>
  );
};

export default ModalSuccess;
