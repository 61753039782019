import React, { useState, useEffect } from "react";

const Carousel = ({ picturesArray, brand }) => {
  const [indexToShow, setIndexToShow] = useState(0);
  useEffect(() => {
    const sliderInterval = setTimeout(() => {
      if (indexToShow < picturesArray.length - 1) {
        setIndexToShow((prevState) => prevState + 1);
      } else {
        setIndexToShow(0);
      }
    }, [3000]);

    return () => clearTimeout(sliderInterval);
  }, [indexToShow]);

  return (
    <div
      id="default-carousel"
      className="relative w-full mx-auto bg-gray-200"
      data-carousel="slide">
      <div className="relative h-[45vh] overflow-hidden md:h-96">
        {picturesArray && picturesArray.length > 0 && (
          <div className="duration-700 ease-in-out" data-carousel-item>
            <img
              src={
                !brand
                  ? picturesArray[indexToShow]["picture"]
                  : picturesArray[indexToShow]["logo"]
              }
              className="absolute block object-cover -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2"
              alt="..."
            />
          </div>
        )}
      </div>
      <div className="absolute z-30 flex -translate-x-1/2 bottom-5 left-1/2 space-x-3 rtl:space-x-reverse">
        {picturesArray &&
          picturesArray.length > 1 &&
          picturesArray.map((pictureObject, index) => {
            return (
              <button
                onClick={() => {
                  setIndexToShow(index);
                }}
                type="button"
                className="w-3 h-3 bg-gray-100 rounded-full cursor-pointer z-50"
                aria-current="true"
                aria-label={`Slinde ${index}`}
                data-carousel-slide-to={index}></button>
            );
          })}
      </div>
      {picturesArray && picturesArray.length > 1 && (
        <>
          <button
            type="button"
            disabled={indexToShow === 0 ? true : false}
            onClick={() => {
              if (indexToShow - 1 >= 0) {
                setIndexToShow((prevState) => prevState - 1);
              }
            }}
            className="absolute top-0 start-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none"
            data-carousel-prev>
            <span className="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 dark:bg-gray-800/30 group-hover:bg-white/50 dark:group-hover:bg-gray-800/60 group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none">
              <svg
                className="w-4 h-4 text-white rtl:rotate-180"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 6 10">
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M5 1 1 5l4 4"
                />
              </svg>
              <span className="sr-only">Précédent</span>
            </span>
          </button>
          <button
            type="button"
            disabled={indexToShow === picturesArray.length - 1 ? true : false}
            onClick={() => {
              if (indexToShow + 1 <= picturesArray.length - 1) {
                setIndexToShow((prevState) => prevState + 1);
              }
            }}
            className="absolute top-0 end-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none"
            data-carousel-next>
            <span className="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 dark:bg-gray-800/30 group-hover:bg-white/50 dark:group-hover:bg-gray-800/60 group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none">
              <svg
                className="w-4 h-4 text-white rtl:rotate-180"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 6 10">
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m1 9 4-4-4-4"
                />
              </svg>
              <span className="sr-only">Suivant</span>
            </span>
          </button>
        </>
      )}
    </div>
  );
};

export default Carousel;
