import { useState, useEffect } from "react";

import { Routes, Route, Navigate } from "react-router-dom";

import Home from "../Client/Home/Home";
import AboutUs from "../Client/AboutUs/AboutUs";
import Services from "../Client/Services/Services";
import Contact from "../Client/Contact/Contact";

import CategoryPage from "../Client/CategoryPage/CategoryPage";

import ProductSingle from "../Client/ProductSingle/ProductSingle";
import ClientProductsList from "../Client/ClientProducts/ClientProductsList";

import Header from "../Shared/components/Header/Header";
import HeaderPhone from "../Shared/components/HeaderPhone/HeaderPhone";
import Footer from "../Shared/components/Footer/Footer";

import { useLoadingErrorContext } from "../Shared/Context/LoadingErrorContext";

import { requestHandle } from "../Shared/utils/RequestFunctions.js";
import ScrollToTop from "../Shared/utils/ScrollToTop.js";

const ClientRoutes = ({ setUser, userSession }) => {
  const { innerWidthState, setIsLoading, setErrorMessage } =
    useLoadingErrorContext();

  const [navigationData, setNavigationData] = useState([]);

  const [searchReference, setSearchReference] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  const fetchNavigationData = async () => {
    const response = await requestHandle(
      setIsLoading,
      setErrorMessage,
      "",
      "GET",
      "navigation",
      ""
    );

    if (response && response.statut === "OK") {
      setNavigationData(response.message);
    }
  };

  const searchByReference = async () => {
    if (searchReference) {
      const searchResponse = await requestHandle(
        setIsLoading,
        setErrorMessage,
        "",
        "GET",
        `client/product/search/${searchReference}`,
        searchReference
      );

      if (searchResponse && searchResponse.statut === "OK") {
        setSearchResults(searchResponse.message);
      }
    } else {
      setSearchResults([]);
    }
  };

  useEffect(() => {
    fetchNavigationData();
  }, []);

  useEffect(() => {
    searchByReference();
  }, [searchReference]);

  return (
    <>
      <ScrollToTop />
      {innerWidthState > 768 ? (
        <Header
          userSession={userSession}
          setUser={setUser}
          navigationData={navigationData}
          setNavigationData={setNavigationData}
          searchReference={searchReference}
          setSearchReference={setSearchReference}
          searchResults={searchResults}
          setSearchResults={setSearchResults}
          searchByReference={searchByReference}
        />
      ) : (
        <HeaderPhone
          userSession={userSession}
          setUser={setUser}
          navigationData={navigationData}
          setNavigationData={setNavigationData}
          searchReference={searchReference}
          setSearchReference={setSearchReference}
          searchResults={searchResults}
          setSearchResults={setSearchResults}
          searchByReference={searchByReference}
        />
      )}

      <Routes>
        <Route path="/" element={<Home userSession={userSession} />} />

        <Route
          path="/services"
          element={<Services userSession={userSession} />}
        />
        <Route path="/contact" element={<Contact />} />
        <Route path="/qui_sommes_nous" element={<AboutUs />} />

        <Route
          path="/:categorySlug"
          element={<CategoryPage userSession={userSession} />}
        />

        <Route
          path="/:categorySlug/:subCategorySlug"
          element={<ClientProductsList userSession={userSession} />}
        />

        <Route
          path="product/:categorySlug/:subCategorySlug/:product_ID"
          element={<ProductSingle userSession={userSession} />}
        />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
      <Footer navigationData={navigationData} />
    </>
  );
};

export default ClientRoutes;
