import React from "react";
import IconComponent from "../../IconComponent/IconComponent";

const PreviewPicture = ({ objectKey, state, setState, multiple }) => {
  const deletePictureFromGallery = (index) => {
    const copyState = { ...state };
    copyState.values[objectKey].splice(index, 1);
    setState(copyState);
  };

  return (
    <div>
      {!multiple ? (
        <>
          <div className="previw-picture-img-container flex flex-col items-center justify-center relative">
            <IconComponent
              icon="close-filled"
              className="absolute top-0 right-0"
              removeInlineStyle
              size={20}
              style={{ cursor: "pointer" }}
              onClick={(e) => {
                const copyState = { ...state };
                copyState.values[objectKey] = "";
                setState(copyState);
              }}
            />
            <img
              className="w-60 h-60 object-contain"
              src={
                typeof state.values[objectKey] === "string"
                  ? state.values[objectKey]
                  : URL.createObjectURL(state.values[objectKey])
              }
              alt=""
            />
          </div>
          {/* <button
            className="px-8 py-3 font-semibold rounded bg-gray-800 text-gray-100 my-3"
            onClick={(e) => {
              const copyState = { ...state };
              copyState.values[objectKey] = "";
              setState(copyState);
            }}>
            Supprimer
          </button> */}
        </>
      ) : (
        <div className="preview-gallery-container flex flex-wrap justify-around ">
          {state.values[objectKey] &&
            state.values[objectKey].length > 0 &&
            state.values[objectKey].map((picture, index) => {
              return (
                <div
                  key={index}
                  className="preview-gallery-single-img-container w-40 my-4 flex flex-col items-end relative">
                  <IconComponent
                    removeInlineStyle
                    className="absolute top-0 right-0"
                    icon="close-filled"
                    size={20}
                    style={{ cursor: "pointer" }}
                    onClick={() => deletePictureFromGallery(index)}
                  />

                  <img
                    className="object-contain"
                    src={
                      typeof picture === "string"
                        ? picture
                        : URL.createObjectURL(picture)
                    }
                    alt=""
                  />
                </div>
              );
            })}
        </div>
      )}
    </div>
  );
};

export default PreviewPicture;
