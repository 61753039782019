import React, { useState, useEffect, useRef } from "react";

import { sanitize } from "dompurify";
import { useLocation } from "react-router-dom";

// IMPORT UTILS
import { requestHandle } from "../../Shared/utils/RequestFunctions";
import {
  washingMachineFeatureToKeepList,
  dishWasherFeatureToKeepList,
  dryerFeatureToKeepList,
  refrigeratorFeatureToKeepList,
  freezerFeatureToKeepList,
  wineCellarFeatureToKeepList,
  stoveFeatureToKeepList,
  ovenFeatureToKeepList,
  microWaveFeatureToKeepList,
  integratedHoodFeatureToKeepList,
  cookTopFeatureToKeepList,
  steamCentralFeatureToKeepList,
  vacuumFeatureToKeepList,
} from "../../Shared/utils/ProductFeatureToKeepArrays";

// IMPORT CONTEXT
import { useLoadingErrorContext } from "../../Shared/Context/LoadingErrorContext";

// IMPORT COMPONENT
import GalleryComponent from "./components/GalleryComponent";
import AlertMessage from "../../Shared/components/AlertMessage/AlertMessage";
import LoadingWrapper from "../../Shared/components/LoadingWrapper/LoadingWrapper";
import ModalInformationRequest from "../../Shared/components/Modals/ModalInformationRequest";
import { AnimatePresence, motion } from "framer-motion";
import SEOHelmet from "../../Shared/components/SeoHelmet/SEOHelmet";

const ProductSingle = () => {
  const { setIsLoading, isLoading, setErrorMessage, errorMessage } =
    useLoadingErrorContext();

  const productID = useLocation().pathname.split("/").pop();

  const [productSingleData, setProductSingleData] = useState({});
  const [modalInformationRequest, setModalInformationRequest] = useState(false);

  const descriptionRef = useRef(null);
  const [fullDesc, setFullDesc] = useState(false);

  const formatFeature = (features, id) => {
    let formatedFeatures = [];
    const parsedFeatures = JSON.parse(features);

    let keptFeatureArray = [];

    switch (id) {
      case "656e410e9e4553cf1eab1aaf":
      case "656e410e9e4553cf1eab1abf":
        keptFeatureArray = washingMachineFeatureToKeepList;
        break;

      case "656e410e9e4553cf1eab1ab0":
      case "656e410e9e4553cf1eab1abe":
        keptFeatureArray = dishWasherFeatureToKeepList;
        break;

      case "656e410e9e4553cf1eab1ab1":
        keptFeatureArray = dryerFeatureToKeepList;
        break;

      case "656e410e9e4553cf1eab1ab2":
      case "656e410e9e4553cf1eab1abb":
        keptFeatureArray = refrigeratorFeatureToKeepList;
        break;

      case "656e410e9e4553cf1eab1ab3":
      case "656e410e9e4553cf1eab1abc":
        keptFeatureArray = freezerFeatureToKeepList;
        break;

      case "656e410e9e4553cf1eab1ab8":
      case "656e410e9e4553cf1eab1ab7":
        keptFeatureArray = ovenFeatureToKeepList;
        break;

      case "656e410e9e4553cf1eab1ab6":
      case "656e410e9e4553cf1eab1ab9":
        keptFeatureArray = microWaveFeatureToKeepList;
        break;

      case "656e410e9e4553cf1eab1ab4":
        keptFeatureArray = wineCellarFeatureToKeepList;
        break;

      case "656e410e9e4553cf1eab1ab5":
        keptFeatureArray = stoveFeatureToKeepList;
        break;

      case "656e410e9e4553cf1eab1abd":
        keptFeatureArray = integratedHoodFeatureToKeepList;
        break;
      case "656e410e9e4553cf1eab1aba":
        keptFeatureArray = cookTopFeatureToKeepList;
        break;
      case "666b8d828d42be25962a3cc2":
        keptFeatureArray = vacuumFeatureToKeepList;
        break;
      case "666b8e048d42be25962a3cc4":
        keptFeatureArray = steamCentralFeatureToKeepList;
        break;

      default:
        keptFeatureArray = [];
        break;
    }

    parsedFeatures.forEach((featureFromDataBase) => {
      let checkForFeatureId = keptFeatureArray.find(
        (elmt) => elmt["id"] === featureFromDataBase["id"]
      );
      if (checkForFeatureId) {
        formatedFeatures.push({
          id: checkForFeatureId["id"],
          name: checkForFeatureId["featureName"],
          value: featureFromDataBase["value"],
          measurement: checkForFeatureId["measurement"],
        });
      }
    });

    return formatedFeatures;
  };

  const fetchProductSingleData = async () => {
    if (productID) {
      const productDataResponse = await requestHandle(
        setIsLoading,
        setErrorMessage,
        "",
        "POST",
        "client/product/single",
        { type: "single", id: productID }
      );
      if (productDataResponse && productDataResponse.statut === "OK") {
        const stateToSet = productDataResponse.message;

        stateToSet["picture_gallery"] =
          stateToSet["picture_gallery"]?.split(",");

        // stateToSet["picture_gallery"].push(stateToSet.repairability_picture);

        stateToSet["features"] = formatFeature(
          productDataResponse.message["features"],
          productDataResponse.message["sub_category"]
            ? productDataResponse.message["sub_category"]
            : productDataResponse.message["category"]
            ? productDataResponse.message["category"]
            : ""
        );

        setProductSingleData(productDataResponse.message);
      }
    }
  };

  useEffect(() => {
    fetchProductSingleData();
  }, [productID]);

  if (isLoading) return <LoadingWrapper isLoading={isLoading} />;

  return (
    <main id="product-single-container" className="md:container mx-auto">
      {errorMessage && (
        <AlertMessage
          errorMessage={errorMessage}
          setErrorMessage={setErrorMessage}
        />
      )}
      {productSingleData && productSingleData["name"] && (
        <SEOHelmet title={productSingleData["name"]} />
      )}

      {modalInformationRequest && (
        <ModalInformationRequest
          modale={modalInformationRequest}
          setModale={setModalInformationRequest}
          productID={productID}
          productSingleData={productSingleData}
        />
      )}
      <div className={`${modalInformationRequest ? "opacity-30" : ""}`}>
        <div className="border-b-2 border-gray-800 mb-5">
          <h1 className="text-center font-bold text-3xl mt-5  text-red-800 py-5 md:w-[70%] mx-auto">
            {productSingleData && productSingleData["name"]
              ? productSingleData["name"]
              : ""}
          </h1>
        </div>

        <div className="flex flex-col md:flex-row md:px-5">
          {productSingleData && (
            <GalleryComponent
              picture_gallery={
                productSingleData["picture_gallery"]
                  ? productSingleData["picture_gallery"]
                  : ""
              }
              brand={
                productSingleData["brand"] ? productSingleData["brand"] : ""
              }
              repairability_picture={productSingleData["repairability_picture"]}
            />
          )}

          <div className="w-full flex flex-col items-center text-center bg-gray-800 text-gray-50 p-5 md:w-[40%]">
            <div>
              <h3 className="font-semibold mt-3">Ce produit m'intéresse</h3>
              <button
                className="px-8 py-3 font-semibold border rounded border-white hover:text-gray-50 my-5 hover:bg-red-800 hover:border-red-800"
                onClick={() => setModalInformationRequest(true)}>
                Contacter le magasin
              </button>
            </div>

            {productSingleData && productSingleData["energy_label"] && (
              <div className="text-center flex flex-col items-center">
                <a
                  className="font-semibold hover:text-red-800 my-5 underline "
                  href={productSingleData["energy_label"]}
                  rel="noreferrer"
                  target="_blank">
                  Télécharger le label energie
                </a>
              </div>
            )}
            {productSingleData && productSingleData["leaflet"] && (
              <div className="">
                <a
                  className="font-semibold hover:text-red-800 my-5 underline "
                  href={productSingleData["leaflet"]}
                  rel="noreferrer"
                  target="_blank">
                  Télécharger la fiche technique
                </a>
              </div>
            )}
          </div>
        </div>

        {/* {productSingleData && productSingleData["repairability_index"] && (
        <div>
          <h3>Indice de réparabilité</h3>
          <p>
            <strong>{productSingleData["repairability_index"]}</strong>
          </p>
        </div>
      )} */}

        {productSingleData && productSingleData["description"] && (
          <div className="my-10">
            <AnimatePresence>
              <motion.div
                ref={descriptionRef}
                animate={fullDesc ? "open" : "close"}
                variants={{
                  open: {
                    height: "100%",
                  },
                  close: {
                    height: "10vh",
                  },
                }}
                className={`description-container text-justify px-5 pb-5 shadow-lg overflow-hidden text-ellipsis ${
                  !fullDesc ? "whitespace-nowrap" : ""
                }	`}
                dangerouslySetInnerHTML={{
                  __html: sanitize(productSingleData["description"]),
                }}></motion.div>
            </AnimatePresence>

            <div className="flex justify-center items-center">
              {!fullDesc ? (
                <svg
                  onClick={() => setFullDesc(true)}
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                  className="h-14 w-14 cursor-pointer">
                  <path d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z" />
                </svg>
              ) : (
                <svg
                  onClick={() => setFullDesc(false)}
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                  className="h-14 w-14 cursor-pointer">
                  <path d="M233.4 105.4c12.5-12.5 32.8-12.5 45.3 0l192 192c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L256 173.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l192-192z" />
                </svg>
              )}
            </div>
          </div>
        )}

        <div className="flex flex-col">
          {productSingleData &&
            productSingleData["features"] &&
            productSingleData["features"].length > 0 && (
              <div>
                <h3
                  className={
                    "text-center text-lg my-3 font-semibold text-gray-800"
                  }>
                  Les caractéristiques
                </h3>
                <ul className="features-container">
                  {productSingleData["features"].map((feature) => {
                    return (
                      <li
                        key={feature.id}
                        className="feature-li p-2   even:bg-gray-100 odd:bg-white">
                        {feature.name} : <strong>{feature.value}</strong>{" "}
                        {feature.measurement}
                      </li>
                    );
                  })}
                </ul>
              </div>
            )}
        </div>

        {(productSingleData && productSingleData["eu_product_fiche"]) ||
        (productSingleData && productSingleData["eu_product_fiche"]) ? (
          <div className="my-10 text-white bg-red-800 py-10">
            <div className="flex justify-center items-center mb-10">
              <h4 className="flex items-end justify-between font-bold">
                {/* <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 384 512"
                fill="currentColor"
                className="h-10 w-10 mr-5">
                <path d="M320 464c8.8 0 16-7.2 16-16V160H256c-17.7 0-32-14.3-32-32V48H64c-8.8 0-16 7.2-16 16V448c0 8.8 7.2 16 16 16H320zM0 64C0 28.7 28.7 0 64 0H229.5c17 0 33.3 6.7 45.3 18.7l90.5 90.5c12 12 18.7 28.3 18.7 45.3V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V64z" />
              </svg> */}
                Documentation
              </h4>
            </div>

            <div className="flex flex-col md:flex-row flex-wrap justify-around">
              {productSingleData && productSingleData["eu_product_fiche"] && (
                <div className="text-center flex flex-col items-center">
                  <h3
                    className={
                      "text-center text-lg font-semibold text-gray-00"
                    }>
                    Fiche européenne
                  </h3>
                  <a
                    className="px-6 py-2 font-semibold border rounded border-white hover:text-gray-800 my-5 hover:bg-white "
                    href={productSingleData["eu_product_fiche"]}
                    rel="noreferrer"
                    target="_blank">
                    Lien vers le document
                  </a>
                </div>
              )}

              {productSingleData && productSingleData["manual"] && (
                <div className="text-center flex flex-col items-center">
                  <h3
                    className={
                      "text-center text-lg font-semibold text-gray-00"
                    }>
                    Manuel
                  </h3>
                  <a
                    className="px-6 py-2 font-semibold border rounded border-white hover:text-gray-800 my-5 hover:bg-white "
                    href={productSingleData["manual"]}
                    rel="noreferrer"
                    target="_blank">
                    Lien vers le document
                  </a>
                </div>
              )}
            </div>
          </div>
        ) : null}
      </div>
    </main>
  );
};

export default ProductSingle;
