import React, { useState, useEffect } from "react";

// IMPORT LIB
import Cookies from "js-cookie";
import { useLocation, Link } from "react-router-dom";

// IMPORT UTILS
import {
  sendDataToServer,
  requestHandle,
} from "../../Shared/utils/RequestFunctions";

// IMPORT CONTEXT
import { useLoadingErrorContext } from "../../Shared/Context/LoadingErrorContext";

// IMPORT COMPONENT
import CKEditorComponent from "../../Shared/components/CkEditor/CkeditorComponent";
import AlertMessage from "../../Shared/components/AlertMessage/AlertMessage";
import ModalSuccess from "../../Shared/components/Modals/ModalSuccess";
import BackofficeGoBack from "../../Shared/components/BackofficeGoBack/BackofficeGoBack";
import LoadingWrapper from "../../Shared/components/LoadingWrapper/LoadingWrapper";

const PresCreateUpdate = ({ userSession }) => {
  const { setIsLoading, isLoading, setErrorMessage, errorMessage } =
    useLoadingErrorContext();

  const pageType = useLocation().pathname.split("/").pop();

  const [presentationToSend, setPresentationToSend] = useState({
    _id: pageType === "create" ? "" : pageType,
    content: "",
  });

  const [modale, setModale] = useState(false);

  const sendPresentationForm = async (e) => {
    e.preventDefault();
    if (presentationToSend["content"]) {
      await sendDataToServer(
        setIsLoading,
        setErrorMessage,
        userSession.token,
        pageType === "create" ? "POST" : "PUT",
        "presentation",
        presentationToSend,
        setModale,
        pageType === "create"
          ? "Un nouveau texte de présentation à été ajouté"
          : "Ce texte de présentation à été mis à jour",
        "présentations"
      );
    } else {
      setErrorMessage({ title: "Champ vide" });
    }
  };

  const fetchPresentationSingle = async () => {
    if (pageType !== "create") {
      const response = await requestHandle(
        setIsLoading,
        setErrorMessage,
        userSession.token,
        "POST",
        "presentation",
        { single: pageType }
      );

      if (response && response.statut === "OK") {
        setPresentationToSend(response.message);
      }
    }
  };

  useEffect(() => {
    fetchPresentationSingle();
  }, []);

  if (isLoading) return <LoadingWrapper isLoading={isLoading} />;

  return (
    <main
      id="presentation-create-update-container"
      className="backoffice-container bg-gray-800">
      {errorMessage ? (
        <AlertMessage
          errorMessage={errorMessage}
          setErrorMessage={setErrorMessage}
        />
      ) : null}
      {modale && <ModalSuccess modale={modale} setModale={setModale} />}
      {/* <BackofficeGoBack
        listeName={"presentations"}
        collection={"presentation"}
      /> */}

      <form
        className="bg-gray-100 p-5 text-center w-[100vw] min-h-[100vh]"
        onSubmit={(e) => sendPresentationForm(e)}>
        <h1 className="mb-5 font-bold text-lg">
          {pageType === "create"
            ? "Création du texte présentation"
            : "Mis à jour du texte de présentation"}
        </h1>
        <CKEditorComponent
          state={presentationToSend}
          setState={setPresentationToSend}
        />
        <button
          className="px-8 py-3 font-semibold rounded bg-gray-800 text-gray-100 mt-6"
          type="submit">
          Envoyer
        </button>
      </form>
    </main>
  );
};

export default PresCreateUpdate;
