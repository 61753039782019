import React, { useState } from "react";

import { Link } from "react-router-dom";

import { AnimatePresence, motion } from "framer-motion";

const ClientProductCard = ({ productData, featuresArray }) => {
  const hoverVariants = {
    hover: {
      top: "0",
      opacity: 1,
    },
    hidden: {
      top: "100%",
      opacity: 0,
    },
  };
  const [isHover, setIsHover] = useState(false);
  return (
    <Link
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      to={
        productData["sub_category"] && productData["sub_category"]["slug"]
          ? `/product/${productData["category"]["slug"]}/${productData["sub_category"]["slug"]}/${productData["_id"]}`
          : `/product/${productData["category"]["slug"]}/all/${productData["_id"]}`
      }
      key={productData["_id"]}
      className="relative flex-none shadow-xl w-60 h-[22em] overflow-hidden text-ellipsis m-3 p-4 rounded">
      <div className="selected-img-container">
        {productData["picture_presentation"] && (
          <img
            className="h-60 w-60 object-contain"
            src={productData["picture_presentation"]}
            alt=""
          />
        )}
      </div>
      <div className="selected-text-container">
        <h4 className="text-center font-semibold mb-4 text-ellipsis whitespace-break-spaces">
          {productData["name"] ? productData["name"] : ""}
        </h4>
      </div>
      <AnimatePresence key={productData["_id"]} initial={false}>
        <motion.div
          animate={isHover ? "hover" : "hidden"}
          variants={hoverVariants}
          transition={{ duration: 0.3 }}
          className="absolute top-0 left-0 w-full h-full bg-gray-800/75 text-red-800 flex items-center justify-center">
          <h5 className="text-center font-semibold bg-red-800 text-white p-3 rounded-lg">
            Découvrir le produit
          </h5>
        </motion.div>
      </AnimatePresence>
    </Link>
  );
};

export default ClientProductCard;
