import React, { useState, useEffect } from "react";

import FeatureForm from "./FeatureForm";

import {
  washingMachineFeatureToKeepList,
  dishWasherFeatureToKeepList,
  dryerFeatureToKeepList,
  refrigeratorFeatureToKeepList,
  freezerFeatureToKeepList,
  wineCellarFeatureToKeepList,
  ovenFeatureToKeepList,
  microWaveFeatureToKeepList,
  integratedHoodFeatureToKeepList,
  cookTopFeatureToKeepList,
  vacuumFeatureToKeepList,
  steamCentralFeatureToKeepList,
} from "../../../Shared/utils/ProductFeatureToKeepArrays";
import { useLoadingErrorContext } from "../../../Shared/Context/LoadingErrorContext";
import { sendDataToServer } from "../../../Shared/utils/RequestFunctions";

import FormInput from "../../../Shared/components/FormInput/FormInput";

const ProductForm = ({
  productToSend,
  setProductToSend,
  validateFunction,
  modale,
  setModale,
  brandsDataToDisplay,
  userSession,
  pageType,
}) => {
  const { setErrorMessage, setIsLoading } = useLoadingErrorContext();

  const [dataType, setDataType] = useState("gen");
  const [isFeatureForm, setIsFeatureForm] = useState(false);

  const [arrayFeature, setArrayFeature] = useState([]);
  const switchFeaturesArray = () => {
    // const productToSendFeatures = productToSend.values.features;

    switch (productToSend.values.sub_category) {
      case "656e410e9e4553cf1eab1aaf":
      case "656e410e9e4553cf1eab1abf":
        setArrayFeature(washingMachineFeatureToKeepList);
        setIsFeatureForm(true);
        break;

      case "656e410e9e4553cf1eab1ab0":
      case "656e410e9e4553cf1eab1abe":
        setArrayFeature(dishWasherFeatureToKeepList);
        setIsFeatureForm(true);

        break;
      case "656e410e9e4553cf1eab1ab1":
        setArrayFeature(dryerFeatureToKeepList);
        setIsFeatureForm(true);

        break;
      case "656e410e9e4553cf1eab1ab2":
      case "656e410e9e4553cf1eab1abb":
        setArrayFeature(refrigeratorFeatureToKeepList);
        setIsFeatureForm(true);

        break;
      case "656e410e9e4553cf1eab1ab3":
      case "656e410e9e4553cf1eab1abc":
        setArrayFeature(freezerFeatureToKeepList);
        setIsFeatureForm(true);

        break;
      case "656e410e9e4553cf1eab1ab4":
        setArrayFeature(wineCellarFeatureToKeepList);
        setIsFeatureForm(true);

        break;
      case "656e410e9e4553cf1eab1ab8":
      case "656e410e9e4553cf1eab1ab7":
        setArrayFeature(ovenFeatureToKeepList);
        setIsFeatureForm(true);
        break;
      case "656e410e9e4553cf1eab1ab6":
      case "656e410e9e4553cf1eab1ab9":
        setArrayFeature(microWaveFeatureToKeepList);
        setIsFeatureForm(true);
        break;
      case "656e410e9e4553cf1eab1abd":
        setArrayFeature(integratedHoodFeatureToKeepList);
        setIsFeatureForm(true);
        break;
      case "656e410e9e4553cf1eab1aba":
        setArrayFeature(cookTopFeatureToKeepList);
        setIsFeatureForm(true);
        break;
      case "666b8d828d42be25962a3cc2":
        setArrayFeature(vacuumFeatureToKeepList);
        setIsFeatureForm(true);
        break;
      case "666b8e048d42be25962a3cc4":
        setArrayFeature(steamCentralFeatureToKeepList);
        setIsFeatureForm(true);
        break;
      default:
        setArrayFeature([]);
    }
  };

  const sendproductToSendToServer = async (e) => {
    e.preventDefault();

    if (!validateFunction(productToSend)) {
      if (productToSend.values.picture_gallery) {
        const copyProduct = { ...productToSend };
        let picture_gallery = copyProduct.values.picture_gallery.filter(
          (elmt) => typeof elmt === "string"
        );

        let pictureGalleryToUpload = copyProduct.values.picture_gallery.filter(
          (elmt) => typeof elmt !== "string"
        );

        copyProduct.values.gallery_string_array =
          JSON.stringify(picture_gallery);

        copyProduct.values.featuresToSend = JSON.stringify(
          copyProduct.values.features
        );

        if (pictureGalleryToUpload.length > 0) {
          copyProduct.values.arrayOfPicture = pictureGalleryToUpload;
        }

        setProductToSend(copyProduct);
      }

      await sendDataToServer(
        setIsLoading,
        setErrorMessage,
        userSession.token,
        pageType === "create" ? "POST" : "PUT",
        "product",
        productToSend.values,
        setModale,
        "Retourner à la liste des produits",
        "produits"
      );
    } else {
      setErrorMessage({
        title: "Erreur de saisie",
        content: "Merci de remplir tout les champs requis",
      });
    }
  };

  // const InputByDataType = () => {
  //   if (dataType === "gen") {
  //     return (
  //       <>
  //         <div className="flex justify-between w-[75%]">
  //           <FormInput
  //             inputType={"text"}
  //             label={"Nom du produit"}
  //             objectKey={"name"}
  //             placeholder={"le nom du produit"}
  //             state={productToSend}
  //             setState={setProductToSend}
  //             validateFunction={validateFunction}
  //             gen={true}
  //           />
  //           <FormInput
  //             inputType={"text"}
  //             label={"Reference"}
  //             objectKey={"reference"}
  //             placeholder={"la reference du produit"}
  //             state={productToSend}
  //             setState={setProductToSend}
  //             validateFunction={validateFunction}
  //             gen={true}
  //           />
  //         </div>

  //         <div className="w-full flex flex-col items-center">
  //           <FormInput
  //             inputType={"select"}
  //             label={"Marque"}
  //             objectKey={"brand_id"}
  //             placeholder={"une marque"}
  //             state={productToSend}
  //             setState={setProductToSend}
  //             validateFunction={validateFunction}
  //             arrayOptions={brandsDataToDisplay}
  //             productToSend={productToSend}
  //           />
  //         </div>

  //         <FormInput
  //           inputType={"ckEditor"}
  //           label={"Description du produit"}
  //           objectKey={"description"}
  //           placeholder={"la description du produit"}
  //           state={productToSend}
  //           setState={setProductToSend}
  //           validateFunction={validateFunction}
  //         />
  //       </>
  //     );
  //   }
  //   if (dataType === "caract") {
  //     return (
  //       <FeatureForm
  //         productToSend={productToSend}
  //         setProductToSend={setProductToSend}
  //         setIsFeatureForm={setIsFeatureForm}
  //         arrayFeature={arrayFeature}
  //       />
  //     );
  //   }

  //   if (dataType === "pics") {
  //     return (
  //       <div className="">
  //         <FormInput
  //           inputType={"file"}
  //           label={"Photo de présentation du produit"}
  //           objectKey={"picture_presentation"}
  //           state={productToSend}
  //           setState={setProductToSend}
  //           validateFunction={validateFunction}
  //           gen={true}
  //         />

  //         <FormInput
  //           inputType={"file"}
  //           label={"Gallerie photo"}
  //           objectKey={"picture_gallery"}
  //           state={productToSend}
  //           setState={setProductToSend}
  //           validateFunction={validateFunction}
  //           multipleAttribute={true}
  //           disableCondition={
  //             productToSend.values["picture_gallery"] &&
  //             productToSend.values["picture_gallery"].length === 5
  //               ? true
  //               : false
  //           }
  //           gen={true}
  //         />
  //       </div>
  //     );
  //   }
  //   if (dataType === "docs") {
  //     return (
  //       <div className="flex flex-wrap justify-start p-10">
  //         <FormInput
  //           inputType={"file"}
  //           label={"Document label energie "}
  //           objectKey={"energy_label"}
  //           state={productToSend}
  //           setState={setProductToSend}
  //           validateFunction={validateFunction}
  //           pdf={true}
  //         />

  //         <FormInput
  //           inputType={"file"}
  //           label={"Fiche produit européenne"}
  //           objectKey={"eu_product_fiche"}
  //           state={productToSend}
  //           setState={setProductToSend}
  //           validateFunction={validateFunction}
  //           pdf={true}
  //         />

  //         <FormInput
  //           inputType={"file"}
  //           label={"Fiche technique"}
  //           objectKey={"leaflet"}
  //           state={productToSend}
  //           setState={setProductToSend}
  //           validateFunction={validateFunction}
  //           pdf={true}
  //         />

  //         <FormInput
  //           inputType={"file"}
  //           label={"Manuel d'utilisation"}
  //           objectKey={"manual"}
  //           state={productToSend}
  //           setState={setProductToSend}
  //           validateFunction={validateFunction}
  //           pdf={true}
  //         />

  //         {(productToSend.values.sub_category === "656e410e9e4553cf1eab1aaf" ||
  //           productToSend.values.sub_category === "656e410e9e4553cf1eab1abf" ||
  //           productToSend.values.sub_category === "656e410e9e4553cf1eab1ab0" ||
  //           productToSend.values.sub_category ===
  //             "656e410e9e4553cf1eab1abe") && (
  //           <FormInput
  //             inputType={"file"}
  //             label={"Etiquette indice de réparabilité"}
  //             objectKey={"repairability_picture"}
  //             state={productToSend}
  //             setState={setProductToSend}
  //             validateFunction={validateFunction}
  //             pdf={true}
  //           />
  //         )}
  //       </div>
  //     );
  //   }
  // };

  useEffect(() => {
    switchFeaturesArray();
  }, [productToSend.values["sub_category"], productToSend.values["category"]]);

  return (
    <form
      className="bg-gray-100 p-5 text-center w-[100vw] flex flex-col justify-between items-center"
      onSubmit={(e) => sendproductToSendToServer(e)}>
      <div className="w-full flex items-center -mx-4 space-x-2 overflow-x-auto overflow-y-hidden sm:justify-center flex-nowrap bg-gray-100 text-gray-800 my-5">
        <button
          type="button"
          onClick={(e) => {
            setDataType("gen");
          }}
          rel="noopener noreferrer"
          className={`flex items-center flex-shrink-0 px-5 py-2 border-b-4 ${
            dataType === "gen" ? "border-red-800" : "border-gray-300"
          } text-gray-600 font-semibold`}>
          {/* Nom - Description - Marque - Référence */}
          Désignation
        </button>
        {isFeatureForm && (
          <button
            type="button"
            onClick={(e) => {
              setDataType("caract");
            }}
            rel="noopener noreferrer"
            className={`flex items-center flex-shrink-0 px-5 py-2 border-b-4 ${
              dataType === "caract" ? "border-red-800" : "border-gray-300"
            } text-gray-600 font-semibold`}>
            Caractéristiques
          </button>
        )}
        <button
          type="button"
          onClick={(e) => {
            setDataType("pics");
          }}
          rel="noopener noreferrer"
          className={`flex items-center flex-shrink-0 px-5 py-2 border-b-4 ${
            dataType === "pics" ? "border-red-800" : "border-gray-300"
          } text-gray-600 font-semibold`}>
          Photos
        </button>
        <button
          type="button"
          onClick={(e) => {
            setDataType("docs");
          }}
          rel="noopener noreferrer"
          className={`flex items-center flex-shrink-0 px-5 py-2 border-b-4 ${
            dataType === "docs" ? "border-red-800" : "border-gray-300"
          } text-gray-600 font-semibold`}>
          Documents
        </button>
      </div>
      {dataType === "gen" ? (
        <>
          <div className="flex justify-between w-[75%]">
            <FormInput
              inputType={"text"}
              label={"Nom du produit"}
              objectKey={"name"}
              placeholder={"le nom du produit"}
              state={productToSend}
              setState={setProductToSend}
              validateFunction={validateFunction}
              gen={true}
            />
            <FormInput
              inputType={"text"}
              label={"Reference"}
              objectKey={"reference"}
              placeholder={"la reference du produit"}
              state={productToSend}
              setState={setProductToSend}
              validateFunction={validateFunction}
              gen={true}
            />
          </div>

          <div className="w-full flex flex-col items-center">
            <FormInput
              inputType={"select"}
              label={"Marque"}
              objectKey={"brand_id"}
              placeholder={"une marque"}
              state={productToSend}
              setState={setProductToSend}
              validateFunction={validateFunction}
              arrayOptions={brandsDataToDisplay}
              productToSend={productToSend}
            />
          </div>

          <FormInput
            inputType={"ckEditor"}
            label={"Description du produit"}
            objectKey={"description"}
            placeholder={"la description du produit"}
            state={productToSend}
            setState={setProductToSend}
            validateFunction={validateFunction}
          />
        </>
      ) : dataType === "caract" ? (
        <FeatureForm
          productToSend={productToSend}
          setProductToSend={setProductToSend}
          setIsFeatureForm={setIsFeatureForm}
          arrayFeature={arrayFeature}
        />
      ) : dataType === "pics" ? (
        <div className="">
          <FormInput
            inputType={"file"}
            label={"Photo de présentation du produit"}
            objectKey={"picture_presentation"}
            state={productToSend}
            setState={setProductToSend}
            validateFunction={validateFunction}
            gen={true}
          />

          <FormInput
            inputType={"file"}
            label={"Gallerie photo"}
            objectKey={"picture_gallery"}
            state={productToSend}
            setState={setProductToSend}
            validateFunction={validateFunction}
            multipleAttribute={true}
            disableCondition={
              productToSend.values["picture_gallery"] &&
              productToSend.values["picture_gallery"].length === 5
                ? true
                : false
            }
            gen={true}
          />
        </div>
      ) : dataType === "docs" ? (
        <div className="flex flex-wrap justify-start p-10">
          <FormInput
            inputType={"file"}
            label={"Document label energie "}
            objectKey={"energy_label"}
            state={productToSend}
            setState={setProductToSend}
            validateFunction={validateFunction}
            pdf={true}
          />

          <FormInput
            inputType={"file"}
            label={"Fiche produit européenne"}
            objectKey={"eu_product_fiche"}
            state={productToSend}
            setState={setProductToSend}
            validateFunction={validateFunction}
            pdf={true}
          />

          <FormInput
            inputType={"file"}
            label={"Fiche technique"}
            objectKey={"leaflet"}
            state={productToSend}
            setState={setProductToSend}
            validateFunction={validateFunction}
            pdf={true}
          />

          <FormInput
            inputType={"file"}
            label={"Manuel d'utilisation"}
            objectKey={"manual"}
            state={productToSend}
            setState={setProductToSend}
            validateFunction={validateFunction}
            pdf={true}
          />

          {(productToSend.values.sub_category === "656e410e9e4553cf1eab1aaf" ||
            productToSend.values.sub_category === "656e410e9e4553cf1eab1abf" ||
            productToSend.values.sub_category === "656e410e9e4553cf1eab1ab0" ||
            productToSend.values.sub_category ===
              "656e410e9e4553cf1eab1abe") && (
            <FormInput
              inputType={"file"}
              label={"Etiquette indice de réparabilité"}
              objectKey={"repairability_picture"}
              state={productToSend}
              setState={setProductToSend}
              validateFunction={validateFunction}
              pdf={true}
            />
          )}
        </div>
      ) : null}

      <button
        className="px-8 py-3 font-semibold rounded bg-gray-800 text-gray-100"
        type="submit">
        Envoyer
      </button>
    </form>
  );
};

export default ProductForm;
