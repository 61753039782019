export const onChangeStateObject = (key, value, state, setState) => {
  const copyStateObject = { ...state };
  copyStateObject[key] = value;
  setState(copyStateObject);
};

export const onChangeFormInput = (
  key,
  value,
  state,
  setState,
  validateFunction
) => {
  const copyFormObject = { ...state };
  copyFormObject.touched[key] = true;
  copyFormObject.values[key] = value;
  validateFunction(copyFormObject);
  setState(copyFormObject);
};

export const onChangeFilters = (value, objectKey, state, setState) => {
  const copyFilters = { ...state };
  copyFilters[objectKey] = value;
  if (copyFilters[objectKey]["values"].length === 0) {
    delete copyFilters[objectKey];
  }
  setState(copyFilters);
};
