export const washingMachineFeatureToKeepList = [
  {
    id: "42930",
    featureName: "L’indice de réparabilité",
    measurement: "",
    type: "dropdown",
    listOfValues: [],
    traduction: "",
  },
  {
    id: "2492",
    featureName: "Capacité nominale",
    measurement: "kg",
    type: "numerical",
    listOfValues: [],
    traduction: "",
  },
  {
    id: "8260",
    featureName: "Vitesse d'essorage maximale",
    measurement: "tr/min",
    type: "numerical",
    listOfValues: [],
    traduction: "",
  },
  {
    id: "7619",
    featureName: "Niveau sonore (essorage)",
    measurement: "dB",
    type: "numerical",
    listOfValues: [],
    traduction: "",
  },
  {
    id: "6907",
    featureName: "Indication du temps restant",
    measurement: "",
    type: "y_n",
    listOfValues: [],
    traduction: "",
  },
  {
    id: "42849",
    featureName: "Consommation d'énergie pour 100 cycles",
    measurement: "kWh",
    type: "numerical",
    listOfValues: [],
    traduction: "",
  },
  {
    id: "2510",
    featureName: "Consommation d'eau par cycle",
    measurement: "L",
    type: "numerical",
    listOfValues: [],
    traduction: "",
  },
  {
    id: "1649",
    featureName: "Largeur",
    measurement: "mm",
    type: "numerical",
    listOfValues: [],
    traduction: "",
  },
  {
    id: "1650",
    featureName: "Profondeur",
    measurement: "mm",
    type: "numerical",
    listOfValues: [],
    traduction: "",
  },
  {
    id: "1464",
    featureName: "Hauteur",
    measurement: "mm",
    type: "numerical",
    listOfValues: [],
    traduction: "",
  },
];

export const dishWasherFeatureToKeepList = [
  {
    id: "42930",
    featureName: "L’indice de réparabilité",
    measurement: "",
    type: "dropdown",
    listOfValues: [],
    traduction: "",
  },
  {
    id: "6950",
    featureName: "Nombre de couverts",
    measurement: "couverts",
    type: "numerical",
    listOfValues: [],
    traduction: "",
  },
  {
    id: "1793",
    featureName: "Niveau sonore",
    measurement: "dB",
    type: "numerical",
    listOfValues: [],
    traduction: "",
  },
  {
    id: "2510",
    featureName: "Consommation d'eau par cycle",
    measurement: "L",
    type: "numerical",
    listOfValues: [],
    traduction: "",
  },
  {
    id: "42849",
    featureName: "Consommation d'énergie pour 100 cycles",
    measurement: "kWh",
    type: "numerical",
    listOfValues: [],
    traduction: "",
  },

  {
    id: "2509",
    featureName: "Durée de cycle",
    measurement: "min",
    type: "numerical",
    listOfValues: [],
    traduction: "",
  },

  {
    id: "42850",
    featureName: "Classe d'émission sonore",
    measurement: "",
    type: "dropdown",
    listOfValues: [],
    traduction: "",
  },
  {
    id: "8374",
    featureName: "Programmes de lavage",
    measurement: "",
    type: "multi_dropdown",
    listOfValues: [],
    traduction: "",
  },

  {
    id: "2491",
    featureName: "Nombre de programmes de lavage",
    measurement: "",
    type: "numerical",
    listOfValues: [],
    traduction: "",
  },

  {
    id: "6900",
    featureName: "Minuterie, départ différé",
    measurement: "",
    type: "y_n",
    listOfValues: [],
    traduction: "",
  },

  {
    id: "3027",
    featureName: "Départ différé",
    measurement: "",
    type: "numerical",
    listOfValues: [],
    traduction: "",
  },
  {
    id: "2705",
    featureName: "Classe d'efficacité énergétique",
    measurement: "",
    type: "dropdown",
    listOfValues: [],
    traduction: "",
  },

  {
    id: "1649",
    featureName: "Largeur",
    measurement: "mm",
    type: "numerical",
    listOfValues: [],
    traduction: "",
  },
  {
    id: "1650",
    featureName: "Profondeur",
    measurement: "mm",
    type: "numerical",
    listOfValues: [],
    traduction: "",
  },
  {
    id: "1464",
    featureName: "Hauteur",
    measurement: "mm",
    type: "numerical",
    listOfValues: [],
    traduction: "",
  },
];

export const dryerFeatureToKeepList = [
  {
    id: "2622",
    featureName: "Système de séchage",
    measurement: "",
    type: "dropdown",
    listOfValues: [],
    traduction: "",
  },
  {
    id: "2489",
    featureName: "Classe de séchage",
    measurement: "",
    type: "dropdown",
    listOfValues: [],
    traduction: "",
  },
  {
    id: "8347",
    featureName: "Volume du tambour",
    measurement: "L",
    type: "numerical",
    listOfValues: [],
    traduction: "",
  },
  {
    id: "3027",
    featureName: "Départ différé",
    measurement: "h",
    type: "numerical",
    listOfValues: [],
    traduction: "",
  },
  {
    id: "6907",
    featureName: "Indication du temps restant",
    measurement: "",
    type: "y_n",
    listOfValues: [],
    traduction: "",
  },
  {
    id: "94",
    featureName: "Poids",
    measurement: "kg",
    type: "numerical",
    listOfValues: [],
    traduction: "",
  },
  {
    id: "2492",
    featureName: "Capacité nominale",
    measurement: "kg",
    type: "numerical",
    listOfValues: [],
    traduction: "",
  },
  {
    id: "8422",
    featureName: "Consommation annuelle d'énergie",
    measurement: "kWh",
    type: "numerical",
    listOfValues: [],
    traduction: "",
  },
  {
    id: "1793",
    featureName: "Niveau sonore",
    measurement: "dB",
    type: "numerical",
    listOfValues: [],
    traduction: "",
  },
  {
    id: "2705",
    featureName: "Classe d'efficacité énergétique",
    measurement: "",
    type: "dropdown",
    listOfValues: [],
    traduction: "",
  },

  {
    id: "1649",
    featureName: "Largeur",
    measurement: "mm",
    type: "numerical",
    listOfValues: [],
    traduction: "",
  },
  {
    id: "1650",
    featureName: "Profondeur",
    measurement: "mm",
    type: "numerical",
    listOfValues: [],
    traduction: "",
  },
  {
    id: "1464",
    featureName: "Hauteur",
    measurement: "mm",
    type: "numerical",
    listOfValues: [],
    traduction: "",
  },
];

export const refrigeratorFeatureToKeepList = [
  {
    id: "1464",
    featureName: "Hauteur",
    measurement: "mm",
    type: "numerical",
    listOfValues: [],
    traduction: "",
  },
  {
    id: "1649",
    featureName: "Largeur",
    measurement: "mm",
    type: "numerical",
    listOfValues: [],
    traduction: "",
  },
  {
    id: "1650",
    featureName: "Profondeur",
    measurement: "mm",
    type: "numerical",
    listOfValues: [],
    traduction: "",
  },
  {
    id: "94",
    featureName: "Poids",
    measurement: "kg",
    type: "numerical",
    listOfValues: [],
    traduction: "",
  },

  {
    id: "2480",
    featureName: "Capacité nette du réfrigérateur",
    measurement: "L",
    type: "numerical",
    listOfValues: [],
    traduction: "",
  },

  {
    id: "2473",
    featureName: "Portes réversibles",
    measurement: "",
    type: "dropdown",
    listOfValues: [],
    traduction: "",
  },
  {
    id: "2481",
    featureName: "Nombre de clayettes/bacs du réfrigérateur",
    measurement: "",
    type: "numerical",
    listOfValues: [],
    traduction: "",
  },
  {
    id: "2481",
    featureName: "Nombre de tiroirs à légumes",
    measurement: "",
    type: "numerical",
    listOfValues: [],
    traduction: "",
  },

  {
    id: "42850",
    featureName: "Classe d'émission sonore",
    measurement: "",
    type: "dropdown",
    listOfValues: [],
    traduction: "",
  },
  {
    id: "1793",
    featureName: "Niveau sonore",
    measurement: "dB",
    type: "numerical",
    listOfValues: [],
    traduction: "",
  },

  {
    id: "1793",
    featureName: "Classe d'efficacité énergétique",
    measurement: "",
    type: "dropdown",
    listOfValues: [],
    traduction: "",
  },

  {
    id: "2495",
    featureName: "Consommation d'énergie",
    measurement: "kWh/24h",
    type: "numerical",
    listOfValues: [],
    traduction: "",
  },
  {
    id: "8422",
    featureName: "Consommation annuelle d'énergie",
    measurement: "kWh",
    type: "numerical",
    listOfValues: [],
    traduction: "",
  },
];

export const freezerFeatureToKeepList = [
  {
    id: "1464",
    featureName: "Hauteur",
    measurement: "mm",
    type: "numerical",
    listOfValues: [],
    traduction: "",
  },
  {
    id: "1649",
    featureName: "Largeur",
    measurement: "mm",
    type: "numerical",
    listOfValues: [],
    traduction: "",
  },
  {
    id: "1650",
    featureName: "Profondeur",
    measurement: "mm",
    type: "numerical",
    listOfValues: [],
    traduction: "",
  },
  {
    id: "94",
    featureName: "Poids",
    measurement: "kg",
    type: "numerical",
    listOfValues: [],
    traduction: "",
  },

  {
    id: "2478",
    featureName: "Capacité net du congélateur",
    measurement: "L",
    type: "numerical",
    listOfValues: [],
    traduction: "",
  },

  {
    id: "14052",
    featureName: "Type de produit",
    measurement: "",
    type: "dropdown",
    listOfValues: [],
    traduction: "",
  },

  {
    id: "2493",
    featureName: "Emplacement charnière de porte",
    measurement: "",
    type: "dropdown",
    listOfValues: [],
    traduction: "",
  },

  {
    id: "2473",
    featureName: "Portes réversibles",
    measurement: "",
    type: "dropdown",
    listOfValues: [],
    traduction: "",
  },
  {
    id: "2500",
    featureName: "Nombre de clayettes/bacs du congélateur",
    measurement: "",
    type: "numerical",
    listOfValues: [],
    traduction: "",
  },
  {
    id: "2498",
    featureName: "Capacité de congélation",
    measurement: "kg/24h",
    type: "numerical",
    listOfValues: [],
    traduction: "",
  },

  {
    id: "15734",
    featureName: "Autonomie en cas de panne de courant",
    measurement: "h",
    type: "numerical",
    listOfValues: [],
    traduction: "",
  },

  {
    id: "2472",
    featureName: "Nombre d'étoiles (indique les possibilités de conservation)",
    measurement: "",
    type: "dropdown",
    listOfValues: [],
    traduction: "",
  },
  {
    id: "2485",
    featureName: "Système anti-gel",
    measurement: "",
    type: "y_n",
    listOfValues: [],
    traduction: "",
  },

  {
    id: "16949",
    featureName: "Dégivrage automatique (freezer)",
    measurement: "",
    type: "y_n",
    listOfValues: [],
    traduction: "",
  },

  {
    id: "42850",
    featureName: "Classe d'émission sonore",
    measurement: "",
    type: "dropdown",
    listOfValues: [],
    traduction: "",
  },

  {
    id: "42850",
    featureName: "Niveau sonore",
    measurement: "dB",
    type: "numerical",
    listOfValues: [],
    traduction: "",
  },
  {
    id: "2918",
    featureName: "Fonction congélation rapide",
    measurement: "",
    type: "y_n",
    listOfValues: [],
    traduction: "",
  },

  {
    id: "7195",
    featureName: "Température de congélation (min)",
    measurement: "",
    type: "numerical",
    listOfValues: [],
    traduction: "",
  },

  {
    id: "2499",
    featureName: "Lumière intérieure",
    measurement: "",
    type: "y_n",
    listOfValues: [],
    traduction: "Éclairage intérieur",
  },

  {
    id: "2917",
    featureName: "Sécurité alarme porte ouverte",
    measurement: "",
    type: "y_n",
    listOfValues: [],
    traduction: "",
  },

  {
    id: "2705",
    featureName: "Classe d'efficacité énergétique",
    measurement: "",
    type: "dropdown",
    listOfValues: [],
    traduction: "",
  },

  {
    id: "8422",
    featureName: "Consommation annuelle d'énergie",
    measurement: "kWh",
    type: "numerical",
    listOfValues: [],
    traduction: "",
  },
];

export const wineCellarFeatureToKeepList = [
  {
    id: "1464",
    featureName: "Hauteur",
    measurement: "mm",
    type: "numerical",
    listOfValues: [],
    traduction: "",
  },
  {
    id: "1649",
    featureName: "Largeur",
    measurement: "mm",
    type: "numerical",
    listOfValues: [],
    traduction: "",
  },
  {
    id: "1650",
    featureName: "Profondeur",
    measurement: "mm",
    type: "numerical",
    listOfValues: [],
    traduction: "",
  },
  {
    id: "94",
    featureName: "Poids",
    measurement: "kg",
    type: "numerical",
    listOfValues: [],
    traduction: "",
  },
  {
    id: "7431",
    featureName: "Nombre de clayettes",
    measurement: "étagères",
    type: "numerical",
    listOfValues: [],
    traduction: "",
  },

  {
    id: "2493",
    featureName: "Emplacement charnière de porte",
    measurement: "",
    type: "dropdown",
    listOfValues: [],
    traduction: "",
  },

  {
    id: "2473",
    featureName: "Portes réversibles",
    measurement: "",
    type: "y_n",
    listOfValues: [],
    traduction: "",
  },

  {
    id: "16965",
    featureName: "Protection contre les rayons ultraviolet (UV)",
    measurement: "",
    type: "y_n",
    listOfValues: [],
    traduction: "",
  },

  {
    id: "14052",
    featureName: "Type de produit",
    measurement: "",
    type: "dropdown",
    listOfValues: [],
    traduction: "",
  },
  {
    id: "7281",
    featureName: "Capacité en bouteilles",
    measurement: "bouteille(s)",
    type: "numerical",
    listOfValues: [],
    traduction: "",
  },
  {
    id: "7281",
    featureName: "Nombre de zones de température",
    measurement: "",
    type: "numerical",
    listOfValues: [],
    traduction: "",
  },

  {
    id: "20492",
    featureName: "Capacité nette de la cave à vin",
    measurement: "L",
    type: "numerical",
    listOfValues: [],
    traduction: "",
  },

  {
    id: "2476",
    featureName: "Capacité nette totale",
    measurement: "L",
    type: "numerical",
    listOfValues: [],
    traduction: "",
  },

  {
    id: "2476",
    featureName: "Thermostat",
    measurement: "",
    type: "y_n",
    listOfValues: [],
    traduction: "",
  },

  {
    id: "42850",
    featureName: "Classe d'émission sonore",
    measurement: "",
    type: "dropdown",
    listOfValues: [],
    traduction: "",
  },

  {
    id: "1793",
    featureName: "Niveau sonore",
    measurement: "dB",
    type: "numerical",
    listOfValues: [],
    traduction: "",
  },

  {
    id: "2705",
    featureName: "Classe d'efficacité énergétique",
    measurement: "",
    type: "dropdown",
    listOfValues: [],
    traduction: "",
  },

  {
    id: "8422",
    featureName: "Consommation annuelle d'énergie",
    measurement: "kWh",
    type: "numerical",
    listOfValues: [],
    traduction: "",
  },
];

export const ovenFeatureToKeepList = [
  {
    id: "29859",
    featureName: "Capacité nette du four",
    measurement: "L",
    type: "numerical",
    listOfValues: [],
    traduction: "",
  },
  {
    id: "2641",
    featureName: "Puissance de grill",
    measurement: "W",
    type: "numerical",
    listOfValues: [],
    traduction: "",
  },
  {
    id: "1766",
    featureName: "Couleur du produit",
    measurement: "",
    type: "multi_dropdown",
    listOfValues: [],
    traduction: "",
  },

  {
    id: "26462",
    featureName: "Cuisson par convection",
    measurement: "",
    type: "y_n",
    listOfValues: [],
    traduction: "",
  },
  {
    id: "2638",
    featureName: "Grill",
    measurement: "",
    type: "y_n",
    listOfValues: [],
    traduction: "",
  },

  {
    id: "2630",
    featureName: "Fonction de décongélation",
    measurement: "",
    type: "y_n",
    listOfValues: [],
    traduction: "",
  },
  {
    id: "8365",
    featureName: "Puissance du four",
    measurement: "W",
    type: "numerical",
    listOfValues: [],
    traduction: "",
  },
];

export const cookTopFeatureToKeepList = [
  {
    id: "9060",
    featureName: "Nombre de brûleurs de gas",
    measurement: "zone(s)",
    type: "numerical",
    listOfValues: [],
    traduction: "",
  },
  {
    id: "15672",
    featureName: "Cuiseur",
    measurement: "",
    type: "numerical",
    listOfValues: [],
    traduction: "",
  },
  {
    id: "1766",
    featureName: "Couleur du produit",
    measurement: "",
    type: "dropdown",
    listOfValues: [],
    traduction: "",
  },
  {
    id: "39246",
    featureName: "Dimension de largeur de plaque de cuisson",
    measurement: "cm",
    type: "dropdown",
    listOfValues: [],
    traduction: "",
  },
  {
    id: "7142",
    featureName: "Type de plaque",
    measurement: "",
    type: "dropdown",
    listOfValues: [],
    traduction: "",
  },
  {
    id: "2709",
    featureName: "Type de surface extérieure (dessus)",
    measurement: "",
    type: "dropdown",
    listOfValues: [],
    traduction: "",
  },
  {
    id: "7122",
    featureName: "Nombre de brûleurs",
    measurement: "",
    type: "numerical",
    listOfValues: [],
    traduction: "",
  },
  {
    id: "2651",
    featureName: "Type de commande",
    measurement: "",
    type: "dropdown",
    listOfValues: [],
    traduction: "",
  },
  {
    id: "8330",
    featureName: "Position de contrôle",
    measurement: "",
    type: "dropdown",
    listOfValues: [],
    traduction: "",
  },
  {
    id: "23840",
    featureName: "Hotte intégrée",
    measurement: "",
    type: "y_n",
    listOfValues: [],
    traduction: "",
  },
  {
    id: "11058",
    featureName: "Puissance raccordée (gaz)",
    measurement: "",
    type: "numerical",
    listOfValues: [],
    traduction: "",
  },
  {
    id: "8491",
    featureName: "Tension d'entrée AC",
    measurement: "",
    type: "alphanumeric",
    listOfValues: [],
    traduction: "",
  },
  {
    id: "94",
    featureName: "Poids",
    measurement: "g",
    type: "numerical",
    listOfValues: [],
    traduction: "",
  },
];

export const microWaveFeatureToKeepList = [
  {
    id: "14052",
    featureName: "Type de produit",
    measurement: "",
    type: "dropdown",
    listOfValues: [],
    traduction: "",
  },

  {
    id: "2639",
    featureName: "Capacité intérieure",
    measurement: "L",
    type: "numerical",
    listOfValues: [],
    traduction: "",
  },

  {
    id: "2640",
    featureName: "Puissance du micro-onde",
    measurement: "W",
    type: "numerical",
    listOfValues: [],
    traduction: "",
  },
  {
    id: "2643",
    featureName: "Nombre de niveaux de puissance",
    measurement: "W",
    type: "numerical",
    listOfValues: [],
    traduction: "",
  },
  {
    id: "2647",
    featureName: "Taille du plateau tournant",
    measurement: "mm",
    type: "numerical",
    listOfValues: [],
    traduction: "",
  },

  {
    id: "1766",
    featureName: "Couleur du produit",
    measurement: "",
    type: "multi_dropdown",
    listOfValues: [],
    traduction: "",
  },
  {
    id: "2638",
    featureName: "Grill",
    measurement: "",
    type: "y_n",
    listOfValues: [],
    traduction: "",
  },
  {
    id: "9058",
    featureName: "Cuisson vapeur",
    measurement: "",
    type: "y_n",
    listOfValues: [],
    traduction: "",
  },
  {
    id: "8600",
    featureName: "Fonction garder au chaud",
    measurement: "",
    type: "y_n",
    listOfValues: [],
    traduction: "",
  },
  {
    id: "8334",
    featureName: "Rotisserie",
    measurement: "",
    type: "y_n",
    listOfValues: [],
    traduction: "",
  },
  {
    id: "2630",
    featureName: "Fonction de décongélation",
    measurement: "",
    type: "y_n",
    listOfValues: [],
    traduction: "",
  },
  {
    id: "2629",
    featureName: "Fonction de réchauffage",
    measurement: "",
    type: "y_n",
    listOfValues: [],
    traduction: "",
  },
];

export const integratedHoodFeatureToKeepList = [
  {
    id: "3107",
    featureName: "Puissance d'extraction maximum",
    measurement: "m³/h",
    type: "numerical",
    listOfValues: [],
    traduction: "",
  },

  {
    id: "18382",
    featureName: "Classe d'efficacité dynamique du fluide",
    measurement: "",
    type: "dropdown",
    listOfValues: [],
    traduction: "",
  },
  {
    id: "18382",
    featureName: "Classe d'efficacité du filtrage de la graisse",
    measurement: "",
    type: "dropdown",
    listOfValues: [],
    traduction: "",
  },

  {
    id: "11083",
    featureName: "La puissance maximale de recirculation",
    measurement: "m³/h",
    type: "numerical",
    listOfValues: [],
    traduction: "",
  },
  {
    id: "2594",
    featureName: "Nombre de vitesses",
    measurement: "",
    type: "numerical",
    listOfValues: [],
    traduction: "",
  },

  {
    id: "1793",
    featureName: "Niveau sonore",
    measurement: "dB",
    type: "numerical",
    listOfValues: [],
    traduction: "",
  },

  {
    id: "31",
    featureName: "Type",
    measurement: "",
    type: "dropdown",
    listOfValues: [],
    traduction: "",
  },

  {
    id: "1766",
    featureName: "Couleur du produit",
    measurement: "",
    type: "dropdown",
    listOfValues: [],
    traduction: "",
  },

  {
    id: "1766",
    featureName: "Diamètre du conduit d'évacuation",
    measurement: "mm",
    type: "numerical",
    listOfValues: [],
    traduction: "",
  },
  {
    id: "3108",
    featureName: "Nombre de moteurs",
    measurement: "",
    type: "dropdown",
    listOfValues: [],
    traduction: "",
  },

  {
    id: "3111",
    featureName: "Type de filtre à graisse",
    measurement: "",
    type: "dropdown",
    listOfValues: [],
    traduction: "",
  },

  {
    id: "3110",
    featureName: "Nombre de filtres",
    measurement: "pièce(s)",
    type: "numerical",
    listOfValues: [],
    traduction: "",
  },

  {
    id: "8235",
    featureName: "Filtre démontable",
    measurement: "",
    type: "y_n",
    listOfValues: [],
    traduction: "",
  },

  {
    id: "11567",
    featureName: "Filtre lavable",
    measurement: "",
    type: "y_n",
    listOfValues: [],
    traduction: "",
  },
  {
    id: "2705",
    featureName: "Classe d'efficacité énergétique",
    measurement: "",
    type: "dropdown",
    listOfValues: [],
    traduction: "",
  },
  {
    id: "9637",
    featureName: "Puissance du moteur",
    measurement: "",
    type: "numerical",
    listOfValues: [],
    traduction: "",
  },

  {
    id: "8422",
    featureName: "Consommation annuelle d'énergie",
    measurement: "kWh",
    type: "numerical",
    listOfValues: [],
    traduction: "",
  },
];
export const vacuumFeatureToKeepList = [
  {
    id: "9338",
    featureName: "Capacité poussière",
    measurement: "L",
    type: "numerical",
    listOfValues: [],
    traduction: "",
  },
  {
    id: "1766",
    featureName: "Couleur du produit",
    measurement: "L",
    type: "multi_dropdown",
    listOfValues: [],
    traduction: "",
  },
  {
    id: "2617",
    featureName: "Type de conteneur à poussière",
    measurement: "",
    type: "dropdown",
    listOfValues: [],
    traduction: "",
  },
  {
    id: "1793",
    featureName: "Niveau sonore",
    measurement: "dB",
    type: "numerical",
    listOfValues: [],
    traduction: "",
  },
  {
    id: "2615",
    featureName: "Rayon d'action",
    measurement: "m",
    type: "numerical",
    listOfValues: [],
    traduction: "",
  },
  {
    id: "4882",
    featureName: "Puissance d'alimentation max.",
    measurement: "W",
    type: "numerical",
    listOfValues: [],
    traduction: "",
  },
];
export const steamCentralFeatureToKeepList = [
  {
    id: "5198",
    featureName: "Puissance du fer à repasser",
    measurement: "W",
    type: "numerical",
    listOfValues: [],
    traduction: "",
  },
  {
    id: "2537",
    featureName: "Capacité du réservoir d'eau",
    measurement: "L",
    type: "numerical",
    listOfValues: [],
    traduction: "",
  },
  {
    id: "4560",
    featureName: "Pression de vapeur max.",
    measurement: "bar",
    type: "numerical",
    listOfValues: [],
    traduction: "",
  },
  {
    id: "5192",
    featureName: "Temps de chauffe",
    measurement: "min",
    type: "numerical",
    listOfValues: [],
    traduction: "",
  },
  {
    id: "6960",
    featureName: "Performances de jet de vapeur",
    measurement: "g/min",
    type: "numerical",
    listOfValues: [],
    traduction: "",
  },
  {
    id: "8589",
    featureName: "Matériau/revêtement de la semelle",
    measurement: "",
    type: "dropdown",
    listOfValues: [],
    traduction: "",
  },
  {
    id: "2697",
    featureName: "Système Anti-calcaire",
    measurement: "",
    type: "y_n",
    listOfValues: [],
    traduction: "",
  },
  {
    id: "1766",
    featureName: "Couleur du produit",
    measurement: "",
    type: "multi_dropdown",
    listOfValues: [],
    traduction: "",
  },
  {
    id: "21562",
    featureName: "Auto-stop",
    measurement: "",
    type: "y_n",
    listOfValues: [],
    traduction: "",
  },
  {
    id: "21562",
    featureName: "Auto-stop",
    measurement: "",
    type: "y_n",
    listOfValues: [],
    traduction: "",
  },
];

export const stoveFeatureToKeepList = [];

export const nameValueConcatHandle = (stringifiedArray, sub_category_id) => {
  let keptFeatureArray = [];
  switch (sub_category_id) {
    case "656e410e9e4553cf1eab1aaf":
    case "656e410e9e4553cf1eab1abf":
      keptFeatureArray = washingMachineFeatureToKeepList;
      break;
    case "656e410e9e4553cf1eab1ab0":
    case "656e410e9e4553cf1eab1abe":
      keptFeatureArray = dishWasherFeatureToKeepList;
      break;

    case "656e410e9e4553cf1eab1ab1":
      keptFeatureArray = dryerFeatureToKeepList;
      break;

    case "656e410e9e4553cf1eab1ab2":
    case "656e410e9e4553cf1eab1abb":
      keptFeatureArray = refrigeratorFeatureToKeepList;
      break;

    case "656e410e9e4553cf1eab1ab3":
    case "656e410e9e4553cf1eab1abc":
      keptFeatureArray = freezerFeatureToKeepList;
      break;

    case "656e410e9e4553cf1eab1ab8":
    case "656e410e9e4553cf1eab1ab7":
      keptFeatureArray = ovenFeatureToKeepList;
      break;

    case "656e410e9e4553cf1eab1ab6":
    case "656e410e9e4553cf1eab1ab9":
      keptFeatureArray = microWaveFeatureToKeepList;
      break;

    case "656e410e9e4553cf1eab1ab4":
      keptFeatureArray = wineCellarFeatureToKeepList;
      break;

    case "656e410e9e4553cf1eab1abd":
      keptFeatureArray = integratedHoodFeatureToKeepList;
      break;

    case "656e410e9e4553cf1eab1ab5":
      keptFeatureArray = stoveFeatureToKeepList;
      break;

    case "656e410e9e4553cf1eab1aba":
      keptFeatureArray = cookTopFeatureToKeepList;
      break;

    case "666b8d828d42be25962a3cc2":
      keptFeatureArray = vacuumFeatureToKeepList;
      break;
    case "666b8e048d42be25962a3cc4":
      keptFeatureArray = steamCentralFeatureToKeepList;
      break;

    default:
      keptFeatureArray = [];
      break;
  }
  const parsedArray = JSON.parse(stringifiedArray);

  keptFeatureArray.forEach((keptFeature) => {
    let elmtToMerge = parsedArray.find((elmt) => elmt.id === keptFeature.id);
    if (elmtToMerge) {
      elmtToMerge.name = keptFeature.featureName;
      elmtToMerge.measurement = keptFeature.measurement;
    }
  });
  return parsedArray;
};

export const featureToKeepHandle = (
  sub_category_id,
  arrayProducts,
  stateToSet
) => {
  let localFeatureKeptArray = [];
  switch (sub_category_id) {
    case "656e410e9e4553cf1eab1aaf":
    case "656e410e9e4553cf1eab1abf":
      localFeatureKeptArray = washingMachineFeatureToKeepList;
      break;
    case "656e410e9e4553cf1eab1ab0":
    case "656e410e9e4553cf1eab1abe":
      localFeatureKeptArray = dishWasherFeatureToKeepList;
      break;

    case "656e410e9e4553cf1eab1ab1":
      localFeatureKeptArray = dryerFeatureToKeepList;
      break;
    case "656e410e9e4553cf1eab1ab2":
    case "656e410e9e4553cf1eab1abb":
      localFeatureKeptArray = refrigeratorFeatureToKeepList;
      break;

    case "656e410e9e4553cf1eab1ab3":
    case "656e410e9e4553cf1eab1abc":
      localFeatureKeptArray = freezerFeatureToKeepList;
      break;
    case "656e410e9e4553cf1eab1ab8":
    case "656e410e9e4553cf1eab1ab7":
      localFeatureKeptArray = ovenFeatureToKeepList;
      break;

    case "656e410e9e4553cf1eab1ab6":
    case "656e410e9e4553cf1eab1ab9":
      localFeatureKeptArray = microWaveFeatureToKeepList;
      break;

    case "656e410e9e4553cf1eab1ab4":
      localFeatureKeptArray = wineCellarFeatureToKeepList;
      break;
    case "656e410e9e4553cf1eab1abd":
      localFeatureKeptArray = integratedHoodFeatureToKeepList;
      break;
    case "656e410e9e4553cf1eab1ab5":
      localFeatureKeptArray = stoveFeatureToKeepList;
      break;
    case "656e410e9e4553cf1eab1aba":
      localFeatureKeptArray = cookTopFeatureToKeepList;
      break;

    case "666b8d828d42be25962a3cc2":
      localFeatureKeptArray = vacuumFeatureToKeepList;
      break;
    case "666b8e048d42be25962a3cc4":
      localFeatureKeptArray = steamCentralFeatureToKeepList;
      break;

    default:
      localFeatureKeptArray = [];
      break;
  }
  if (arrayProducts) {
    arrayProducts.forEach((product) => {
      JSON.parse(product.features).forEach((productFeature) => {
        let localSingleFeatureKept = localFeatureKeptArray.find(
          (elmt) => elmt.id === productFeature.id
        );
        if (
          localSingleFeatureKept &&
          !localSingleFeatureKept.listOfValues.find(
            (elmt) => elmt === productFeature.value
          )
        ) {
          if (localSingleFeatureKept.type === "dropdown") {
            localSingleFeatureKept.listOfValues.push({
              value: productFeature.value,
              label: productFeature.value,
            });
          }

          if (localSingleFeatureKept.type === "numerical") {
            let marksObject = {};

            if (
              !localSingleFeatureKept.listOfValues.find(
                (elmt) => elmt.value === productFeature.value
              )
            ) {
              localSingleFeatureKept.listOfValues.push(productFeature);
            }

            localSingleFeatureKept.listOfValues.forEach((valueObject) => {
              marksObject = {
                ...marksObject,
                [`${valueObject.value}`]: `${valueObject.value} ${localSingleFeatureKept.measurement}`,
              };
            });
            localSingleFeatureKept.marks = {
              0: `0 ${localSingleFeatureKept.measurement}`,
              [`${
                localSingleFeatureKept.listOfValues[
                  localSingleFeatureKept.listOfValues.sort(
                    (a, b) => a.value - b.value
                  ).length - 1
                ]["value"]
              }`]: `${
                localSingleFeatureKept.listOfValues[
                  localSingleFeatureKept.listOfValues.sort(
                    (a, b) => a.value - b.value
                  ).length - 1
                ]["value"]
              } ${localSingleFeatureKept.measurement}`,
            };
          }
        }
      });
    });
  }

  return localFeatureKeptArray;
};
