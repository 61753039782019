import React, { useEffect, useState } from "react";

// IMPORT LIB
import { useNavigate } from "react-router-dom";

// IMPORT COMPONENTS
import SignupForm from "./components/SignupForm";
import LoginForm from "./components/LoginForm";
import AlertMessage from "../../Shared/components/AlertMessage/AlertMessage";

// IMPORT CONTEXT
import { useLoadingErrorContext } from "../../Shared/Context/LoadingErrorContext";

const Auth = ({ setUser, userSession }) => {
  const navigate = useNavigate();
  const { errorMessage, setErrorMessage } = useLoadingErrorContext();

  const [formType, setFormType] = useState(true);

  return (
    <main
      id="auth-container"
      className="bg-gray-800 text-gray-50 min-h-[100vh] flex items-center">
      {errorMessage && (
        <AlertMessage
          errorMessage={errorMessage}
          setErrorMessage={setErrorMessage}
        />
      )}

      {/* {formType ? ( */}
      <LoginForm setUser={setUser} />
      {/* ) : (
         <SignupForm setUser={setUser} />
       )} */}
      {/* <p>
        Ajouter un compte{" "}
        <span onClick={() => setFormType((prevState) => !prevState)}>ici</span>
      </p> */}
    </main>
  );
};

export default Auth;
