import React, { useState } from "react";

import { Link } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";

import LogoKC from "../../../assets/img/kcMenagerLogo.jpg";
import IconComponent from "../IconComponent/IconComponent";

const HeaderPhone = ({
  userSession,
  setUser,
  navigationData,
  setNavigationData,
  searchReference,
  setSearchReference,
  searchResults,
  setSearchResults,
  searchByReference,
}) => {
  const [searchShowHide, setSearchShowHide] = useState(false);
  const [subMenu, setSubMenu] = useState(false);
  const [displaySearchBar, setDisplaySearchBar] = useState("none");
  const [displaySubMenu, setDisplaySubMenu] = useState("none");

  const searchBarVariants = {
    display: { opacity: 1, width: "100%", display: displaySearchBar },
    hidden: { opacity: 0, width: "0%", display: displaySearchBar },
  };

  const subMenuVariants = {
    display: { opacity: 1, height: "auto", display: displaySubMenu },
    hidden: { opacity: 0, height: 0, display: displaySubMenu },
  };
  return (
    <nav className="bg-white border-gray-200 border-b-2">
      <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
        <Link
          to="/"
          className="flex items-center space-x-3 rtl:space-x-reverse">
          <img src={LogoKC} className="h-12 rounded-lg" alt="Flowbite Logo" />
        </Link>
        <div className="flex justify-end md:order-2">
          <button
            type="button"
            onClick={() => {
              if (searchShowHide) {
                setSearchShowHide(false);
                setTimeout(() => {
                  setDisplaySearchBar("none");
                }, [500]);
              } else {
                setSubMenu(false);
                setDisplaySubMenu("none");

                setSearchShowHide(true);
                setDisplaySearchBar("block");
              }
            }}
            data-collapse-toggle="navbar-search"
            aria-controls="navbar-search"
            aria-expanded="false"
            className="md:hidden text-gray-500 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 rounded-lg text-sm p-2.5 me-1">
            <svg
              className="w-5 h-5"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 20 20">
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
              />
            </svg>
            <span className="sr-only">Search</span>
          </button>

          <AnimatePresence>
            <motion.div
              animate={searchShowHide ? "display" : "hidden"}
              variants={searchBarVariants}
              transition={{ duration: 0.5 }}
              className="relative md:block">
              {/* <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                <svg
                  className="w-4 h-4 text-gray-500 dark:text-gray-400"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 20">
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                  />
                </svg>
                <span className="sr-only">Search icon</span>
              </div> */}
              <input
                type="text"
                id="search-navbar"
                value={searchReference ? searchReference : ""}
                onChange={(e) => {
                  setSearchReference(e.target.value);
                }}
                className=" block w-full p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Recherce par reference ou EAN"
              />
              {searchResults &&
                searchResults.length > 0 &&
                searchResults.map((searchedProduct, index) => {
                  return (
                    <div
                      key={index}
                      className="flex z-50 items-center rounded shadow-md overflow- absolute  bg-white text-gray-700 hover:bg-gray-300">
                      <div className="self-stretch flex items-center px-3 flex-shrink-0 bg-white relative">
                        {searchedProduct["picture_presentation"] && (
                          <img
                            className="h-5"
                            src={searchedProduct["picture_presentation"]}
                            alt=""
                          />
                        )}
                        {searchedProduct["brand"] &&
                          searchedProduct["brand"]["logo"] && (
                            <div className="absolute bottom-2 -right-5 h-10 w-10">
                              <img
                                src={searchedProduct["brand"]["logo"]}
                                alt="logo de marque"
                              />
                            </div>
                          )}
                      </div>
                      <Link
                        onClick={() => {
                          // setCategoryIndexToDevelop();
                          setSearchReference("");
                        }}
                        to={
                          searchedProduct["sub_category"] &&
                          searchedProduct["sub_category"]["slug"]
                            ? `/product/${searchedProduct["category"]["slug"]}/${searchedProduct["sub_category"]["slug"]}/${searchedProduct["_id"]}`
                            : `/product/${searchedProduct["category"]["slug"]}/${searchedProduct["_id"]}`
                        }
                        className="text-sm font-bold p-4 flex-1">
                        {searchedProduct["name"]}
                      </Link>
                    </div>
                  );
                })}
            </motion.div>
          </AnimatePresence>

          <button
            data-collapse-toggle="navbar-search"
            type="button"
            onClick={() => {
              if (subMenu) {
                setSubMenu(false);
                setTimeout(() => {
                  setDisplaySubMenu("none");
                }, [500]);
              } else {
                setSubMenu(true);
                setDisplaySubMenu("block");
                setSearchShowHide(false);
                setTimeout(() => {
                  setDisplaySearchBar("none");
                }, [500]);
              }
            }}
            className="md:hidden text-gray-500 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 rounded-lg text-sm p-2.5 me-1"
            aria-controls="navbar-search"
            aria-expanded="false">
            <span className="sr-only">Open main menu</span>
            <svg
              className="w-5 h-5"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 17 14">
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M1 1h15M1 7h15M1 13h15"
              />
            </svg>
          </button>
        </div>
        <AnimatePresence>
          <motion.div
            animate={subMenu ? "display" : "hidden"}
            variants={subMenuVariants}
            transition={{ duration: 0.5 }}
            className="items-center justify-between w-full md:flex md:w-auto md:order-1"
            id="navbar-search">
            <ul className="flex flex-col p-4 md:p-0 mt-4 font-medium border border-gray-100 rounded-lg bg-gray-50 md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
              {navigationData &&
                navigationData.length > 0 &&
                navigationData
                  .sort((a, b) => {
                    let AsubCategoryLength = a["sub_category"]?.length;
                    let BsubCategoryLength = b["sub_category"]?.length;
                    return AsubCategoryLength > BsubCategoryLength
                      ? -1
                      : AsubCategoryLength < BsubCategoryLength
                      ? 1
                      : 0;
                  })
                  .map((category, index) => {
                    return (
                      <li
                        key={`${category["_id"]}-${index}`}
                        className="border-b border-gray-200 flex text-white items-center">
                        <IconComponent
                          icon={
                            category["slug"] === "pieces-detachees"
                              ? "power-plug"
                              : category["slug"] === "accessoires"
                              ? "wrench-alt"
                              : category["slug"] ===
                                "electromenager-encastrable"
                              ? "oven-gen"
                              : "blender-phone"
                          }
                          size={20}
                          // color="#1f2937"
                        />
                        <Link
                          onClick={() => {
                            setSubMenu(false);
                            setTimeout(() => {
                              setDisplaySubMenu("none");
                            }, [500]);
                          }}
                          to={
                            category["slug"].includes("pieces-detachees") ||
                            category["slug"].includes("accessoires")
                              ? `/${category["slug"]}/all`
                              : `/${category["slug"]}`
                          }
                          className="w-full block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700"
                          aria-current="page">
                          {category["name"]}
                        </Link>
                      </li>
                    );
                  })}

              <li className="border-b border-gray-200">
                <Link
                  onClick={() => {
                    setSubMenu(false);
                    setTimeout(() => {
                      setDisplaySubMenu("none");
                    }, [500]);
                  }}
                  to="/qui_sommes_nous"
                  className="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700">
                  Qui sommes nous
                </Link>
              </li>

              <li>
                <Link
                  onClick={() => {
                    setSubMenu(false);
                    setTimeout(() => {
                      setDisplaySubMenu("none");
                    }, [500]);
                  }}
                  to="/services"
                  className="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700">
                  Services
                </Link>
              </li>

              <li>
                <Link
                  onClick={() => {
                    setSubMenu(false);
                    setTimeout(() => {
                      setDisplaySubMenu("none");
                    }, [500]);
                  }}
                  to="/contact"
                  className="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700">
                  Contact
                </Link>
              </li>
            </ul>
          </motion.div>
        </AnimatePresence>
      </div>
    </nav>
  );
};

export default HeaderPhone;
