import React, { useState, useEffect } from "react";

import { useLocation } from "react-router-dom";

import ProductInput from "../Products/components/ProductInput";
import ModalSuccess from "../../Shared/components/Modals/ModalSuccess";

import { useLoadingErrorContext } from "../../Shared/Context/LoadingErrorContext";

import {
  sendDataToServer,
  requestHandle,
} from "../../Shared/utils/RequestFunctions";
import LoadingWrapper from "../../Shared/components/LoadingWrapper/LoadingWrapper";
import AlertMessage from "../../Shared/components/AlertMessage/AlertMessage";

import FormInput from "../../Shared/components/FormInput/FormInput";

const BrandsCreateUpdate = ({
  setBrandsDataToDisplay,
  setProductData,
  productData,
  userSession,
  setModale,
  modale,
  brandsDataToDisplay,
}) => {
  const { setErrorMessage, errorMessage, setIsLoading, isLoading } =
    useLoadingErrorContext();
  const [modaleSingle, setModaleSingle] = useState(false);

  const pageType = useLocation().pathname.split("/").pop();

  const [brandDataToSend, setBrandDataToSend] = useState({
    values: modale
      ? {
          brand_name: modale.brand_name,
          brand_logo: modale.brand_logo,
        }
      : {},
    touched: {},
    errors: {},
  });

  const [categoryList, setCategoryList] = useState([]);

  const [showPreviwPicture, setShowPreviewPicture] = useState(
    brandDataToSend.values.brand_logo ? true : false
  );

  const inputArray = [
    // {
    //   type: "select",
    //   objectKey: "associated_categories",
    //   required: true,
    //   label: "Catégories associées",
    //   placeholder: "les catégories associées",
    //   arrayOptions: categoryList,
    //   multipleAttribute: true,
    // },
    {
      type: "text",
      objectKey: "brand_name",
      required: true,
      label: "Nom de la marque",
      placeholder: "un nom de marque",
    },

    {
      type: "file",
      objectKey: "brand_logo",
      required: true,
      label: "Logo de la marque",
      placeholder: "un logo de marque",
    },
  ];

  const validateBrandForm = (value) => {
    const errorsValidation = {};
    let isError = false;
    if (value) {
      if (
        !value.values.associated_categories &&
        value.touched.associated_categories
      ) {
        errorsValidation.associated_categories = "Merci de renseigner ce champ";
        isError = true;
      }

      if (!value.values.brand_name && value.touched.brand_name) {
        errorsValidation.brand_name = "Merci de renseigner ce champ";
        isError = true;
      }

      if (!value.values.brand_logo && value.touched.brand_logo) {
        errorsValidation.brand_logo = "Merci de renseigner ce champ";
        isError = true;
      }

      const copyFormObject = { ...brandDataToSend };
      copyFormObject["errors"] = errorsValidation;
      setBrandDataToSend(copyFormObject);
    } else {
      isError = true;
    }
    return isError;
  };

  const sendBrandData = async (e) => {
    e.preventDefault();
    if (!validateBrandForm(brandDataToSend)) {
      if (pageType !== "create") {
        brandDataToSend.values["_id"] = pageType;
      }

      const associatedCategoriesArray = [];

      categoryList.map((category) => {
        associatedCategoriesArray.push({
          values: category["_id"],
          label: category["name"],
        });
      });

      brandDataToSend.values["associated_categories"] = JSON.stringify(
        associatedCategoriesArray
      );

      const brandResponse = await sendDataToServer(
        setIsLoading,
        setErrorMessage,
        userSession.token,
        pageType === "create" ? "POST" : "PUT",
        "brand",
        brandDataToSend.values,
        setModaleSingle,
        `Succès enregistrement de la marque ${brandDataToSend.values["brand_name"]}`,
        "marques"
      );

      if (brandResponse && brandResponse.statut === "OK") {
        if (setProductData && brandsDataToDisplay) {
          setProductData((prevState) => {
            prevState.values.brand_id = brandResponse.message["_id"];
            return prevState;
          });
          const copyBrandsToDisplay = [...brandsDataToDisplay];
          copyBrandsToDisplay.push(brandResponse.message);

          setBrandsDataToDisplay(copyBrandsToDisplay);
        }
      }
    } else {
      const keysObjectToSend = Object.keys(brandDataToSend.errors);

      let newTouchedObject = {};

      keysObjectToSend.forEach((key) => {
        newTouchedObject = { ...newTouchedObject, [`${key}`]: true };
      });

      const copyBrandsData = { ...brandDataToSend };
      copyBrandsData.touched = newTouchedObject;
      setBrandDataToSend(copyBrandsData);

      setErrorMessage({
        title: "Erreur formulaire",
        content: "Erreur de validation du formulaire",
      });
    }
  };

  const fetchCategoryList = async () => {
    const response = await requestHandle(
      setIsLoading,
      setErrorMessage,
      userSession.token,
      "GET",
      "navigation",
      {}
    );

    if (response && response.statut === "OK") {
      setCategoryList(response.message);
    }
  };

  const fetchBrandSingle = async () => {
    if (pageType !== "create") {
      const response = await requestHandle(
        setIsLoading,
        setErrorMessage,
        userSession.token,
        "POST",
        "brand",
        { single: pageType }
      );

      if (response && response.statut === "OK") {
        const copyBrandsData = { ...brandDataToSend };
        copyBrandsData.values["brand_logo"] = response.message["logo"];
        copyBrandsData.values["brand_name"] = response.message["name"];
        setBrandDataToSend(copyBrandsData);
      }
    }
  };

  useEffect(() => {
    if (pageType !== "create") {
      fetchBrandSingle();
    }

    fetchCategoryList();
    setModaleSingle(false);
  }, []);

  useEffect(() => {
    setShowPreviewPicture(brandDataToSend.values.brand_logo ? true : false);
  }, [brandDataToSend]);

  if (isLoading) return <LoadingWrapper isLoading={isLoading} />;
  return (
    <main className="backoffice-container bg-gray-800">
      {errorMessage && (
        <AlertMessage
          errorMessage={errorMessage}
          setErrorMessage={setErrorMessage}
        />
      )}

      {modale && (
        <ModalSuccess modale={modaleSingle} setModale={setModaleSingle} />
      )}

      <form
        className="bg-gray-100 p-5 text-center w-[100vw] min-h-[100vh]"
        onSubmit={(e) => sendBrandData(e)}>
        <h1 className="mb-5 font-bold text-lg">
          {pageType === "create" && !modale
            ? "Ajouter une nouvelle marque"
            : pageType !== "create" && !modale
            ? "Modifier une marque"
            : modale
            ? modale.title
            : null}
        </h1>
        {inputArray &&
          inputArray.length > 0 &&
          inputArray.map((input, index) => (
            <FormInput
              index={index}
              state={brandDataToSend}
              setState={setBrandDataToSend}
              validateFunction={validateBrandForm}
              page={pageType === "create" ? "brand-create" : "brand-update"}
              inputType={input["type"]}
              objectKey={input["objectKey"]}
              required={input["required"] ? true : false}
              label={input["label"] ? input["label"] : ""}
              placeholder={input["placeholder"] ? input["placeholder"] : ""}
              isError={
                input["required"] &&
                brandDataToSend.touched[input["objectKey"]] &&
                brandDataToSend.errors[input["objectKey"]]
              }
              disableCondition={
                input["disableCondition"] ? input["disableCondition"] : false
              }
              arrayOptions={input["arrayOptions"] ? input["arrayOptions"] : []}
              multipleAttribute={
                input["multipleAttribute"] ? input["multipleAttribute"] : false
              }
            />
          ))}

        <button
          type="submit"
          className="px-8 py-3 font-semibold rounded bg-gray-800 text-gray-100">
          Envoyer
        </button>
      </form>
    </main>
  );
};

export default BrandsCreateUpdate;
