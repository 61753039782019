import React, { useEffect, useState } from "react";
import { useFetcher } from "react-router-dom";

const ListeFilters = ({
  radioKeysArray,
  listeToDisplay,
  setListeToDisplay,
  listArray,
}) => {
  const [triKey, setTriKey] = useState("");
  const [orderFilter, setOrderFilter] = useState("asc");

  const handleTri = (filterKey) => {
    if (filterKey) {
      const newListe = [...listeToDisplay];
      newListe.sort((a, b) => {
        const valueA = a[filterKey];
        const valueB = b[filterKey];

        if (orderFilter === "asc") {
          if (valueA < valueB) return -1;
          if (valueA > valueB) return 1;
        } else {
          if (valueA > valueB) return -1;
          if (valueA < valueB) return 1;
        }
        return 0;
      });
      setListeToDisplay([...newListe]);
      setTriKey(filterKey);
    }
  };

  useEffect(() => {
    handleTri(triKey);
  }, [orderFilter]);

  return (
    <div className="p-4 border-2 border-red-800 my-3 mx-auto flex justify-around">
      <div className="justify-center w-full">
        <h3 className="text-center font-bold my-2">Chercher dans la liste</h3>

        <fieldset className="space-y-1 text-gray-800 w-[80%]">
          <label htmlFor="Search" className="hidden">
            Search
          </label>
          <div className="relative w-[100%]">
            <span className="absolute inset-y-0 left-0 flex items-center pl-2">
              <button
                type="button"
                title="rechercher"
                className="p-1 focus:outline-none focus:ring">
                <svg
                  fill="currentColor"
                  viewBox="0 0 512 512"
                  className="w-4 h-4 text-gray-800">
                  <path d="M479.6,399.716l-81.084-81.084-62.368-25.767A175.014,175.014,0,0,0,368,192c0-97.047-78.953-176-176-176S16,94.953,16,192,94.953,368,192,368a175.034,175.034,0,0,0,101.619-32.377l25.7,62.2L400.4,478.911a56,56,0,1,0,79.2-79.195ZM48,192c0-79.4,64.6-144,144-144s144,64.6,144,144S271.4,336,192,336,48,271.4,48,192ZM456.971,456.284a24.028,24.028,0,0,1-33.942,0l-76.572-76.572-23.894-57.835L380.4,345.771l76.573,76.572A24.028,24.028,0,0,1,456.971,456.284Z"></path>
                </svg>
              </button>
            </span>
            <input
              onChange={(e) => {
                if (e.target.value) {
                  const filteredResults = listeToDisplay.filter((elmt) => {
                    return radioKeysArray.some((keyObject) => {
                      const key = keyObject.key;
                      return elmt[key]
                        .toString()
                        .toLowerCase()
                        .includes(e.target.value.toLowerCase());
                    });
                  });

                  setListeToDisplay(filteredResults);
                } else {
                  setListeToDisplay(listArray);
                }
              }}
              type="search"
              name="Search"
              placeholder="Rechercher"
              className="w-full py-2 pl-10 text-sm rounded-md sm:w-auto focus:outline-none bg-gray-100 text-gray-800 focus:bg-gray-50 focus:border-violet-600"
            />
          </div>
        </fieldset>
      </div>

      <div className="w-full">
        <div className="flex justify-around items-center">
          <h3 className="text-center font-bold my-2">Trier les résultats</h3>
          <select
            name=""
            id=""
            onChange={(e) => {
              setOrderFilter(e.target.value);
            }}>
            <option value="asc">Ascendant</option>
            <option value="desc">Descendant</option>
          </select>
        </div>
        <div className="flex justify-around">
          {radioKeysArray &&
            radioKeysArray.length > 0 &&
            radioKeysArray.map((keyObject, index) => (
              <label key={index} className="flex flex-col">
                <input
                  type="radio"
                  name={keyObject["key"]}
                  value={keyObject["key"]}
                  checked={triKey === keyObject["key"]}
                  onChange={(e) => {
                    // console.log(e.target.value);
                    handleTri(e.target.value);

                    // console.log(keyObject, triKey);
                  }}
                />{" "}
                {keyObject["display"]}
              </label>
            ))}
        </div>
      </div>
    </div>
  );
};

export default ListeFilters;
