import React from "react";

import { motion, AnimatePresence } from "framer-motion";

import Lottie from "react-lottie";
import LottieLoading from "../../../assets/lottie/WashinLoader.json";

const LoadingWrapper = ({ isLoading }) => {
  const lottieOptions = {
    loop: true,
    autoplay: true,
    animationData: LottieLoading,
  };

  const containerAnimation = {
    visible: { opacity: 1 },
    closed: { opacity: 0 },
  };
  return (
    <AnimatePresence>
      <motion.div
        animate={isLoading ? "visible" : "closed"}
        variants={containerAnimation}
        transition={{ duration: 0.3 }}
        className="loading-container">
        <div className="loading-lottie">
          <Lottie options={lottieOptions} height={400} width={400} />
        </div>
      </motion.div>
    </AnimatePresence>
  );
};

export default LoadingWrapper;
