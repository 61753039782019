import React, { useState, useEffect } from "react";
import "./App.css";

import Cookies from "js-cookie";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

// IMPORT ROUTES
import BackofficeRoutes from "./router/BackofficeRoutes";
import ClientRoutes from "./router/ClientRoutes";

function App() {
  const [userSession, setUserSession] = useState(
    Cookies.get("session_kc_menager")
      ? JSON.parse(Cookies.get("session_kc_menager"))
      : null
  );

  const setUser = async (token) => {
    if (token) {
      Cookies.set("session_kc_menager", JSON.stringify({ token }), {
        expires: 1,
      });
      setUserSession({ token });
    } else {
      Cookies.remove("session_kc_menager");
      setUserSession(null);
    }
  };

  return (
    <Router basename="/">
      <Routes>
        {/* CLIENT */}
        <Route
          path="/*"
          element={<ClientRoutes setUser={setUser} userSession={userSession} />}
        />

        {/* BACKOFFICE */}
        <Route
          path="/backoffice/*"
          element={
            <BackofficeRoutes setUser={setUser} userSession={userSession} />
          }
        />
      </Routes>
    </Router>
  );
}

export default App;
