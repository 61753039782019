import React, { useEffect, useState } from "react";

import { AnimatePresence, motion } from "framer-motion";

import ProductInput from "./ProductInput";

import IconComponent from "../../../Shared/components/IconComponent/IconComponent";
import { useLoadingErrorContext } from "../../../Shared/Context/LoadingErrorContext";

import { sendDataToServer } from "../../../Shared/utils/RequestFunctions";
import BrandsCreateUpdate from "../../Brands/BrandsCreateUpdate";

const ModalBrand = ({
  modale,
  setModale,
  userSession,
  productData,
  setProductData,
  brandsDataToDisplay,
  setBrandsDataToDisplay,
}) => {
  const { setErrorMessage, setIsLoading } = useLoadingErrorContext();

  const variants = {
    active: { opacity: 1, scale: 1 },
    hidden: { opacity: 0, scale: 0 },
  };

  return (
    <AnimatePresence initial={false}>
      <motion.div
        id="modal-brand"
        animate={modale ? "active" : "hidden"}
        variants={variants}>
        <div className="close-container">
          <IconComponent
            icon="close-filled"
            size={20}
            color="#fff"
            style={{ cursor: "pointer" }}
            onClick={() => setModale(false)}
          />
        </div>
        <BrandsCreateUpdate
          userSession={userSession}
          productData={productData}
          setProductData={setProductData}
          setModale={setModale}
          modale={modale}
          setBrandsDataToDisplay={setBrandsDataToDisplay}
          brandsDataToDisplay={brandsDataToDisplay}
        />
      </motion.div>
    </AnimatePresence>
  );
};

export default ModalBrand;
