import React, { useState, useEffect } from "react";

// IMPORT LIB
import Cookies from "js-cookie";
import { sanitize } from "dompurify";
import { useNavigate } from "react-router-dom";
// IMPORT CONTEXT
import { useLoadingErrorContext } from "../../Shared/Context/LoadingErrorContext";

// IMPORT UTILS
import {
  requestHandle,
  statutUpdate,
} from "../../Shared/utils/RequestFunctions";

// IMPORT COMPONENT
import TableComponent from "../../Shared/components/TableComponent/TableComponent";
import AlertMessage from "../../Shared/components/AlertMessage/AlertMessage";
import TableDeleteUpdateButton from "../../Shared/components/TableDeleteUpdateButton/TableDeleteUpdateButton";
import ModalDelete from "../../Shared/components/Modals/ModalDelete";

const CarList = ({ userSession }) => {
  const navigate = useNavigate();

  const { isLoading, setIsLoading, errorMessage, setErrorMessage } =
    useLoadingErrorContext();

  const [modaleDelete, setModaleDelete] = useState(false);
  const [carrouselItemList, setCarrouselItemList] = useState([]);
  const [listToDisplay, setListToDisplay] = useState([]);

  const fetchCarrouselList = async () => {
    const response = await requestHandle(
      setIsLoading,
      setErrorMessage,
      userSession.token,
      "GET",
      "carrousel"
    );
    if (response && response.statut === "OK") {
      setCarrouselItemList(response.message);
      setListToDisplay(response.message);
    }
  };

  useEffect(() => {
    fetchCarrouselList();
  }, []);

  return (
    <main id="carrousel-liste-container" className="backoffice-container">
      {errorMessage ? (
        <AlertMessage
          errorMessage={errorMessage}
          setErrorMessage={setErrorMessage}
        />
      ) : null}

      {modaleDelete ? (
        <ModalDelete
          setIsLoading={setIsLoading}
          setErrorMessage={setErrorMessage}
          modale={modaleDelete}
          setModale={setModaleDelete}
          token={userSession.token}
        />
      ) : null}

      <TableComponent
        token={userSession.token}
        modaleDelete={modaleDelete}
        setModaleDelete={setModaleDelete}
        collection={"carrousel"}
        addButton={{
          text: "une nouvelle photo",
          link: "/backoffice/carrousel/create",
        }}
        listTitle={"Les photos du carrousel"}
        setListArray={setCarrouselItemList}
        listArray={carrouselItemList.sort(
          (a, b) => new Date(b.created_at) - new Date(a.created_at)
        )}
        listToDisplay={listToDisplay}
        setListToDisplay={setListToDisplay}
        fieldsArray={[
          { colTitle: "Photo", type: "picture", objectKey: "picture" },
          { colTitle: "Date d'ajout", type: "date", objectKey: "created_at" },
        ]}
      />
    </main>
  );
};

export default CarList;
