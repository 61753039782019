import React from "react";
import { useNavigate, Link } from "react-router-dom";

const HeaderBackoffice = ({ setUser }) => {
  const navigate = useNavigate();
  return (
    <header className="">
      <div className="bg-gray-100 flex justify-end p-5">
        <button
          className="px-4 py-3 font-semibold rounded bg-gray-800 text-gray-100 ml-5"
          onClick={() => {
            navigate("/backoffice/home");
          }}>
          Aller à la page d'accueil de l'administration
        </button>
        <button
          className="px-4 py-3 mx-3 font-semibold rounded bg-gray-800 text-gray-100"
          onClick={() => {
            navigate(-1);
          }}>
          Retour en arrière
        </button>

        <Link
          to={"/"}
          target="_blank"
          className="px-4 py-3 mx-3 font-semibold rounded bg-gray-800 text-gray-100">
          Voir le site
        </Link>
        <button
          className="p-4 bg-red-800 rounded w-[20%] text-white"
          onClick={() => {
            setUser(null);
            navigate("/");
          }}>
          Déconnexion
        </button>
      </div>
    </header>
  );
};

export default HeaderBackoffice;
