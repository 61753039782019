import { Routes, Route, useLocation, Navigate } from "react-router-dom";

import HeaderBackoffice from "../Shared/components/HeaderBackoffice/HeaderBackoffice";

import Auth from "../Backoffice/Auth/Auth";
import BackOfficeHome from "../Backoffice/BackOfficeHome/BackOfficeHome";

import PresList from "../Backoffice/Presentation/PresList";
import PresCreateUpdate from "../Backoffice/Presentation/PresCreateUpdate";

import CarList from "../Backoffice/Carrousel/CarList";
import CarCreateUpdate from "../Backoffice/Carrousel/CarCreateUpdate";

import ServiceList from "../Backoffice/Services/ServiceList";
import ServiceCreateUpdate from "../Backoffice/Services/ServiceCreateUpdate";

import ContactBackList from "../Backoffice/ContactBack/ContactBackList";
import ContactBackSingle from "../Backoffice/ContactBack/ContactBackSingle";

import InformationRequestList from "../Backoffice/InformationRequest/InformationRequestList";
import InformationRequestSingle from "../Backoffice/InformationRequest/InformationRequestSingle";

import ProductsList from "../Backoffice/Products/ProductsList";
import ProductsCreateUpdate from "../Backoffice/Products/ProductsCreateUpdate";

import BrandsList from "../Backoffice/Brands/BrandsList";
import BrandsCreateUpdate from "../Backoffice/Brands/BrandsCreateUpdate";

// import PictureSubCategory from "../Backoffice/pictureSubCategory";

const BackofficeRoutes = ({ userSession, setUser }) => {
  const { pathname } = useLocation();
  return (
    <>
      {!pathname.includes("home") && !pathname.includes("auth") && (
        <HeaderBackoffice setUser={setUser} />
      )}

      <Routes>
        {!userSession ? (
          <>
            <Route
              path="/admin/0982/auth"
              element={<Auth setUser={setUser} userSession={userSession} />}
            />
            <Route path="*" element={<Navigate to="/" replace />} />
          </>
        ) : (
          <>
            <Route
              path="/home"
              element={<BackOfficeHome setUser={setUser} />}
            />

            {/*********************** PRESENTATION ***********************/}
            <Route
              path="/presentation/liste"
              element={<PresList userSession={userSession} />}
            />

            <Route
              path="/presentation/create"
              element={<PresCreateUpdate userSession={userSession} />}
            />

            <Route
              path="/presentation/update/:presentation_ID"
              element={<PresCreateUpdate userSession={userSession} />}
            />

            {/*********************** CARROUSEL ***********************/}
            <Route
              path="/carrousel/liste"
              element={<CarList userSession={userSession} />}
            />

            <Route
              path="/carrousel/create"
              element={<CarCreateUpdate userSession={userSession} />}
            />

            <Route
              path="/carrousel/update/:carrousel_ID"
              element={<CarCreateUpdate userSession={userSession} />}
            />
            {/*********************** SERVICES ***********************/}
            <Route
              path="/service/liste"
              element={<ServiceList userSession={userSession} />}
            />

            <Route
              path="/service/create"
              element={<ServiceCreateUpdate userSession={userSession} />}
            />

            <Route
              path="/service/update/:service_ID"
              element={<ServiceCreateUpdate userSession={userSession} />}
            />
            {/*********************** SAV ***********************/}
            <Route
              path="/sav/liste"
              element={<ContactBackList userSession={userSession} />}
            />

            <Route
              path="/sav/update/:sav_ID"
              element={<ContactBackSingle userSession={userSession} />}
            />

            {/*********************** INFORMATIONS REQUEST ***********************/}
            <Route
              path="/informations_request/liste"
              element={<InformationRequestList userSession={userSession} />}
            />

            <Route
              path="/informations_request/update/:informations_request_ID"
              element={<InformationRequestSingle userSession={userSession} />}
            />

            {/*********************** BRANDS ***********************/}
            <Route
              path="/brand/liste"
              element={<BrandsList userSession={userSession} />}
            />

            <Route
              path="/brand/create"
              element={<BrandsCreateUpdate userSession={userSession} />}
            />

            <Route
              path="/brand/update/:brand_ID"
              element={<BrandsCreateUpdate userSession={userSession} />}
            />

            {/*********************** PRODUCTS ***********************/}
            <Route
              path="/product/liste"
              element={<ProductsList userSession={userSession} />}
            />

            <Route
              path="/product/create"
              element={<ProductsCreateUpdate userSession={userSession} />}
            />

            <Route
              path="/product/update/:product_update_ID"
              element={<ProductsCreateUpdate userSession={userSession} />}
            />

            {/*********************** REDIRECT ***********************/}

            <Route
              path="*"
              element={<Navigate to="/backoffice/home" replace />}
            />
          </>
        )}
      </Routes>
    </>
  );
};

export default BackofficeRoutes;
