import React from "react";

import { Helmet } from "react-helmet-async";

const SEOHelmet = ({ title, description, ogDescription, ogImage }) => {
  return (
    <Helmet>
      {title ? <title>KC Ménager | {`${title}`}</title> : null}
      {/* {description ? (
        <meta
          name="description"
          content={description}
          data-react-helmet="true"
        />
      ) : null}

      {ogImage ? (
        <meta property="og:image" content={ogImage} data-react-helmet="true" />
      ) : null}

      {ogDescription ? (
        <meta
          property="og:description"
          content={ogDescription}
          data-react-helmet="true"
        />
      ) : null} */}
    </Helmet>
  );
};

export default SEOHelmet;
