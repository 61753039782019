import React from "react";

import { onChangeFormInput } from "../../utils/EventFunctions";

import TextEmailPasswordCheckboxInput from "./components/TextEmailPasswordCheckboxInput";
import SelectInput from "./components/SelectInput";
import FileInput from "./components/FileInput";
import CkEditorComponent from "../CkEditor/CkeditorComponent";
import PreviewPicture from "./components/PreviewPicture";

const InputComponent = ({
  index,
  page,
  state,
  setState,
  required,
  inputType,
  objectKey,
  label,
  placeholder,
  isError,
  disableCondition,
  validateFunction,
  arrayOptions,
  multipleAttribute,
  hidden,
  iceCat,
  pdf,
  gen,
  shadow,
}) => {
  return (
    <div
      className={`input-group mx-auto text-center mb-4 min-h-[10vh] ${
        hidden ? "hidden" : ""
      } ${
        iceCat
          ? "w-[25%]"
          : gen
          ? "w-[45%]"
          : pdf
          ? "flex flex-col justify-end w-[20%] "
          : "w-[75%]"
      } ${pdf ? "shadow-xl p-4" : ""} ${shadow ? "shadow-xl" : ""}`}>
      {label && (
        <label
          className="text-md font-semibold inline-block mb-2 text-gray-800"
          htmlFor={`form-input-${page}-${objectKey}`}>
          {label}{" "}
          {required ? <span className="text-red-800"> &#8239;*</span> : ""}
        </label>
      )}
      {inputType === "text" ||
      inputType === "email" ||
      inputType === "password" ||
      inputType === "checkbox" ? (
        <TextEmailPasswordCheckboxInput
          index={index}
          page={page}
          state={state}
          setState={setState}
          inputType={inputType}
          objectKey={objectKey}
          placeholder={placeholder}
          isError={isError}
          disableCondition={disableCondition}
          validateFunction={validateFunction}
        />
      ) : inputType === "ckEditor" ? (
        <CkEditorComponent
          state={state}
          setState={setState}
          objectKey={objectKey}
          validateFunction={validateFunction}
        />
      ) : inputType === "textarea" ? (
        <textarea
          cols={30}
          rows={10}
          id={`form-input-${page}-${objectKey}}`}
          name={`form-input-${page}-${objectKey}}`}
          style={
            isError
              ? {
                  border: "solid var(--danger-color) 2px",
                  outline: "var(--danger-color)",
                  resize: "none",
                }
              : {
                  resize: "none",
                }
          }
          className={`w-full rounded-md focus:ring focus:ri focus:ri  text-gray-800 p-2 border-2 border-gray-800 h-32 resize-none shadow-sm`}
          placeholder={`Merci de renseigner ${placeholder}`}
          value={state.values[objectKey] ? state.values[objectKey] : ""}
          onChange={(e) => {
            onChangeFormInput(
              objectKey,
              e.target.value,
              state,
              setState,
              validateFunction
            );
          }}
        />
      ) : inputType === "file" ? (
        <FileInput
          index={index}
          page={page}
          state={state}
          setState={setState}
          objectKey={objectKey}
          placeholder={placeholder}
          isError={isError}
          disableCondition={disableCondition}
          validateFunction={validateFunction}
          multipleAttribute={multipleAttribute}
          pdf={pdf}
        />
      ) : inputType === "select" ? (
        <SelectInput
          index={index}
          page={page}
          state={state}
          setState={setState}
          objectKey={objectKey}
          placeholder={placeholder}
          isError={isError}
          disableCondition={disableCondition}
          validateFunction={validateFunction}
          arrayOptions={arrayOptions}
          multipleAttribute={multipleAttribute}
        />
      ) : null}

      {state.touched[objectKey] && state.errors[objectKey] ? (
        <div
          style={
            isError
              ? { color: "var(--danger-color)" }
              : { color: "var(--text-color)" }
          }
          className={"form-error-container"}>
          <p>{state.errors[objectKey]}</p>
        </div>
      ) : null}
    </div>
  );
};

export default InputComponent;
