import React from "react";

import logo from "../../../assets/img/kcMenagerLogo.jpg";

import { Link } from "react-router-dom";

import FooterMap from "./components/FooterMap";

const Footer = ({ navigationData }) => {
  return (
    <footer className="py-6 bg-gray-800 text-gray-100 w-full">
      <div className="flex flex-col lg:flex-row mb-5 p-5 ">
        <div className="flex flex-col justify-around md:flex-row lg:w-full">
          <div className="text-center leading-8">
            <h2 className="font-bold text-xl mb-5 underline decoration-solide decoration-red-800 underline-offset-8">
              Nos horaires
            </h2>
            <p className="font-semibold">
              Ouvert du lundi au vendredi
              <br />
              de 9h à 12h et de 14h à 18h30
              <br />
              et le samedi de 9h à 12h.
            </p>
          </div>
          <div className="text-center leading-10">
            <h2 className="font-bold text-xl mb-5 underline decoration-solide decoration-red-800 underline-offset-8">
              Nous contacter
            </h2>
            <ul>
              <li className="font-semibold">Zone de Jalday</li>
              <li className="font-semibold">64500 Saint-Jean-de-Luz</li>
              <li className="font-semibold"></li>
              <li className="font-semibold">
                <a href="mailto:contact@enriquezmenager.fr">
                  imakcmenager@orange.fr
                </a>
              </li>
            </ul>
          </div>
        </div>

        <FooterMap nameclassName={"flex-1 sm:h-[20vh]"} />
      </div>
      <div className="px-6 mx-auto space-y-6 divide-y divide-gray-400 md:space-y-12 divide-opacity-50">
        <h2 className="font-bold text-center text-xl underline decoration-solide decoration-red-800 underline-offset-8">
          Plan du site
        </h2>

        <div className="flex flex-col md:flex-row justify-between">
          <div className="md:text-left rounded-sm md:p-5 h-full my-5">
            <Link
              to={`/`}
              className="block hover:text-red-700 p-1 text-lg font-medium">
              Accueil
            </Link>
            <Link
              to={`/contact`}
              className="block hover:text-red-700 p-1 text-lg font-medium">
              Contact
            </Link>
            <Link
              to={`/qui_sommes_nous`}
              className="block hover:text-red-700 p-1 text-lg font-medium">
              Qui Sommes nous
            </Link>
          </div>
          {navigationData &&
            navigationData.length > 0 &&
            navigationData
              .sort((a, b) => {
                let AsubCategoryLength = a["sub_category"]?.length;
                let BsubCategoryLength = b["sub_category"]?.length;
                return AsubCategoryLength > BsubCategoryLength
                  ? -1
                  : AsubCategoryLength < BsubCategoryLength
                  ? 1
                  : 0;
              })
              .map((category, index) => {
                return (
                  <div
                    key={index}
                    className="my-5 rounded-sm md:text-left md:p-5">
                    <Link
                      to={`/${category["slug"]}`}
                      className="block w-full text-lg font-medium border-b-2 border-red-800 hover:border-red-50 ">
                      {category["name"]}
                    </Link>
                    <div className="flex flex-col">
                      {category["sub_category"] &&
                        category["sub_category"].length > 0 &&
                        category["sub_category"].map((subCategory) => {
                          return (
                            <Link
                              key={subCategory["_id"]}
                              to={`/${category["slug"]}/${subCategory["slug"]}`}
                              className="p-2 hover:text-red-600 ">
                              {subCategory["name"]}
                            </Link>
                          );
                        })}
                    </div>
                  </div>
                );
              })}
        </div>
        <div className="grid justify-center pt-6 lg:justify-between">
          <div className="flex flex-col self-center text-sm text-center md:block lg:col-start-1 md:space-x-6">
            <Link rel="noopener noreferrer" to={"/privacy_policy"}>
              <span className="flex-1">Politique de confidentialité</span>
            </Link>

            <span className="flex-1">
              Site conçu et réalisé par{" "}
              <a
                href="http://centcommentaire.fr"
                rel="noreferrer"
                target="_blank"
                className="text-red-400"
                id="centcommentaire">
                Cent Commentaire
              </a>
            </span>
          </div>
          <div className="flex justify-center pt-4 space-x-4 lg:pt-0 lg:col-end-13">
            <a
              rel="noopener noreferrer"
              target="_blank"
              href={
                "https://www.facebook.com/people/KC-M%C3%A9nager/100057452948947/"
              }
              title="Notre page Facebook"
              className="hover:cursor-pointer flex items-center justify-center w-10 h-10 rounded-full bg-gray-100 text-sky-600">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
                fill="currentColor"
                className="w-5 h-5">
                <path d="M512 256C512 114.6 397.4 0 256 0S0 114.6 0 256C0 376 82.7 476.8 194.2 504.5V334.2H141.4V256h52.8V222.3c0-87.1 39.4-127.5 125-127.5c16.2 0 44.2 3.2 55.7 6.4V172c-6-.6-16.5-1-29.6-1c-42 0-58.2 15.9-58.2 57.2V256h83.6l-14.4 78.2H287V510.1C413.8 494.8 512 386.9 512 256h0z" />
              </svg>
            </a>

            <a
              rel="noopener noreferrer"
              href="mailto:contact@kcmenager.fr"
              title="Email"
              className="flex items-center justify-center w-10 h-10 rounded-full bg-gray-100 text-sky-600">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                className="w-5 h-5">
                <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z"></path>
                <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"></path>
              </svg>
            </a>
            <Link
              rel="noopener noreferrer"
              to={"/"}
              className="flex justify-center space-x-3 md:justify-center">
              <div className="flex items-center justify-center w-10 h-10 bg-gray-800">
                <img className="rounded-full" src={logo} alt="" />
              </div>
              {/* <span className="self-center text-2xl font-semibold">
                Kc Ménager
              </span> */}
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
