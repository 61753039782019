import React, { useState, useEffect } from "react";

// IMPORT LIB
import { sanitize } from "dompurify";

// IMPORT CONTEXT
import { useLoadingErrorContext } from "../../Shared/Context/LoadingErrorContext";

// IMPORT UTILS
import { requestHandle } from "../../Shared/utils/RequestFunctions";

// IMPORT COMPONENT
import BackofficeGoBack from "../../Shared/components/BackofficeGoBack/BackofficeGoBack";
import AlertMessage from "../../Shared/components/AlertMessage/AlertMessage";
import ModalDelete from "../../Shared/components/Modals/ModalDelete";
import LoadingWrapper from "../../Shared/components/LoadingWrapper/LoadingWrapper";
import TableComponent from "../../Shared/components/TableComponent/TableComponent";

const ServiceList = ({ userSession }) => {
  const { isLoading, setIsLoading, errorMessage, setErrorMessage } =
    useLoadingErrorContext();

  const [modaleDelete, setModaleDelete] = useState(false);

  const [serviceList, setServiceList] = useState([]);
  const [listToDisplay, setListToDisplay] = useState([]);

  const fecthServiceList = async () => {
    const response = await requestHandle(
      setIsLoading,
      setErrorMessage,
      userSession.token,
      "GET",
      "service"
    );
    if (response && response.statut === "OK") {
      setServiceList(response.message);
      setListToDisplay(response.message);
    }
  };

  useEffect(() => {
    fecthServiceList();
  }, []);

  return (
    <main id="services-liste-container" className="backoffice-container">
      {errorMessage ? (
        <AlertMessage
          errorMessage={errorMessage}
          setErrorMessage={setErrorMessage}
        />
      ) : null}

      {modaleDelete ? (
        <ModalDelete
          setIsLoading={setIsLoading}
          setErrorMessage={setErrorMessage}
          modale={modaleDelete}
          setModale={setModaleDelete}
          token={userSession.token}
        />
      ) : null}

      <TableComponent
        token={userSession.token}
        modaleDelete={modaleDelete}
        setModaleDelete={setModaleDelete}
        collection={"service"}
        addButton={{ text: "un service", link: "/backoffice/service/create" }}
        listTitle={"Les services"}
        setListArray={setServiceList}
        listArray={serviceList.sort(
          (a, b) => new Date(b.created_at) - new Date(a.created_at)
        )}
        listToDisplay={listToDisplay}
        setListToDisplay={setListToDisplay}
        fieldsArray={[
          // { colTitle: "Section", type: "text", objectKey: "section" },
          { colTitle: "Titre", type: "text", objectKey: "title" },
          { colTitle: "Texte", type: "innerHtml", objectKey: "content" },
          { colTitle: "Photo", type: "picture", objectKey: "picture" },
          { colTitle: "Date d'ajout", type: "date", objectKey: "created_at" },
        ]}
      />
    </main>
  );
};

export default ServiceList;
