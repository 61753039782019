import React, { useState } from "react";

const GalleryComponent = ({
  picture_gallery,
  repairability_picture,
  brand,
}) => {
  const [indexToShow, setIndexToShow] = useState(0);
  return (
    picture_gallery && (
      <div className="gallery-component-container flex justify-between md:w-[100%] h-[50vh] object-contain">
        <div className="gallery-list w-[30%] flex flex-wrap overflow-scroll">
          {picture_gallery.length > 0 &&
            picture_gallery.map((picture, index) => {
              return (
                <img
                  key={index}
                  src={picture}
                  alt=""
                  className="h-20 w-20 cursor-pointer object-contain"
                  onClick={() => {
                    setIndexToShow(index);
                  }}
                />
              );
            })}
        </div>
        <div className="gallery-large-picture w-[100%] relative">
          <img
            className="object-contain mx-auto"
            src={picture_gallery[indexToShow]}
            alt=""
          />

          {brand && (
            <div className="brand-contianer absolute bottom-1/2 right-4 rounded-full border-2 border-gray-800">
              <img
                className="rounded-full mx-auto object-contain"
                src={brand["logo"]}
                alt=""
              />
            </div>
          )}

          {repairability_picture && (
            <div className="repairability_picture-contianer w-20 h-20 absolute bottom-1/4 right-4 border-0 border-gray-800">
              <img
                className="mx-auto object-contain"
                src={repairability_picture}
                alt=""
              />
            </div>
          )}
        </div>
        {/* {energy_label && (
          <div className="text-center flex flex-col items-center">
            <h3 className={"text-center text-lg font-semibold text-gray-00"}>
              Label energie
            </h3>
            <img className="h-20 w-20" src={energy_label} alt="" />
            <a
              className="px-6 py-2 font-semibold border rounded border-red-800 text-gray-800 hover:text-gray-50 my-5 hover:bg-red-800 "
              href={energy_label}
              target="_blank">
              Lien vers le document
            </a>
          </div>
        )} */}
      </div>
    )
  );
};

export default GalleryComponent;
